import {
  IChessgunAnalysisItem,
  IChessgunHistoryItem,
  chessSide,
} from 'chessgun/core';

import { myColorIsWhite } from '@utils/_helpers/_game';

import {
  GameType,
  GameUserType,
  IAppAction,
  IBoardEndedData,
  ThunkResult,
  gameActionTypes,
  ITourGameData,
  GameStatus,
} from '@types';

export const createGameCommonActions = () => {
  /**
   * Задает состояние данных игры
   * @param {ITourGameData} gameData - данные игры
   */
  const setGameData = (gameData: ITourGameData | null): IAppAction => ({
    type: gameActionTypes.SET_GAME_DATA,
    payload: gameData,
  });

  /**
   * Задает состояние запроса данных игры
   * @param {boolean} inRequest - запрос в процессе
   */
  const setGameDataRequest = (inRequest: boolean): IAppAction => ({
    type: gameActionTypes.SET_GAME_DATA_REQUEST,
    payload: inRequest,
  });

  /**
   * Задает тип игры (одиночная или турнирная)
   * @param {GameType} gameType - статус игры
   */
  const setGameType = (gameType: GameType): IAppAction => ({
    type: gameActionTypes.SET_GAME_TYPE,
    payload: gameType,
  });

  /**
   * Задает тип юзера (игрок или зритель)
   * @param {GameUserType} gameUserType - тип пользователя в игре
   */
  const setGameUserType = (gameUserType: GameUserType): IAppAction => ({
    type: gameActionTypes.SET_GAME_USER_TYPE,
    payload: gameUserType,
  });

  /**
   * Задает состояние истории шагов в текущей партии.
   * @param {IChessgunHistoryItem[]} movesHistory - данные о истории шагов в текущей партии.
   */
  const setMovesHistory = (
    movesHistory: (IChessgunHistoryItem | null)[]
  ): IAppAction => ({
    type: gameActionTypes.SET_GAME_MOVES_HISTORY,
    payload: movesHistory,
  });

  /**
   * Задает статус игры
   * @param {GameStatus} status - статус игры
   */
  const setGameStatus = (status: GameStatus): IAppAction => ({
    type: gameActionTypes.SET_GAME_STATUS,
    payload: status,
  });

  /**
   * Задает состояние id доски
   * @param {string} boardId - id доски
   */
  const setBoardId = (boardId: string): IAppAction => ({
    type: gameActionTypes.SET_GAME_BOARD_ID,
    payload: boardId,
  });

  /**
   * Задает угол поворота доски
   * @param {number} rotation - угол поворота доски
   */
  const setBoardRotation = (rotation: number): IAppAction => ({
    type: gameActionTypes.SET_BOARD_ROTATION,
    payload: rotation,
  });

  /**
   * Задает состояние поворота доски
   * @param {string} boardFlipped - повернута ли доска
   */
  const setBoardFlipped = (boardFlipped: boolean): ThunkResult<void> => {
    return (dispatch, getState) => {
      const { my_color: myColor, board_rotation: currentRotation } =
        getState().game;

      if (myColorIsWhite(myColor)) {
        dispatch(
          setBoardRotation(
            boardFlipped ? currentRotation + 180 : currentRotation - 180
          )
        );
      } else {
        dispatch(
          setBoardRotation(
            boardFlipped ? currentRotation - 180 : currentRotation + 180
          )
        );
      }

      dispatch({
        type: gameActionTypes.SET_BOARD_FLIPPED,
        payload: boardFlipped,
      });
    };
  };

  /**
   * Задает значение ревью мода
   * @param {boolean} isReviewMove - ревью мод
   */
  const setGameReviewMode = (isReviewMove: boolean): IAppAction => ({
    type: gameActionTypes.SET_GAME_REVIEW_MODE,
    payload: isReviewMove,
  });

  /**
   * Задает мод анализа хода (для партии в бродкасте)
   * @param {boolean} isAnalyze - мод анализа
   */
  const setAnalyzeMode = (isAnalyze: boolean): IAppAction => ({
    type: gameActionTypes.SET_ANALYZE_MODE,
    payload: isAnalyze,
  });

  /**
   * Задает анализ текущего хода
   * @param {IChessgunAnalysisItem | null} analysis - мод анализа
   */
  const setSelectedMoveAnalysis = (
    analysis: IChessgunAnalysisItem | null
  ): IAppAction => ({
    type: gameActionTypes.SET_GAME_SELECTED_MOVE_ANALYSIS,
    payload: analysis,
  });

  /**
   * Задает количество зрителей игры
   * @param {number} activeViewersAmount - количество зрителей
   */
  const setGameViewersCount = (activeViewersAmount: number): IAppAction => ({
    type: gameActionTypes.SET_GAME_VIEWERS_COUNT,
    payload: activeViewersAmount,
  });

  /**
   * Задает состояние окончания игры
   * @param {IBoardEndedData} gameEndedData - данные об окончании игры
   */
  const setGameEndedData = (
    gameEndedData: IBoardEndedData | null
  ): IAppAction => ({
    type: gameActionTypes.SET_GAME_ENDED,
    payload: gameEndedData,
  });

  /**
   * Задает состояние стороны хода
   * @param {chessSide} turn - сторона хода
   */
  const setGamePlayerTurn = (turn: chessSide): IAppAction => {
    return {
      type: gameActionTypes.SET_PLAYER_TURN,
      payload: turn,
    };
  };

  /**
   * Обнуляет данные игры
   */
  const resetGame = (): IAppAction => ({
    type: gameActionTypes.RESET_GAME,
  });

  return {
    setGameData,
    setGameDataRequest,
    setGameType,
    setGameUserType,
    setMovesHistory,
    setGameStatus,
    setBoardId,
    setBoardFlipped,
    setGameReviewMode,
    setAnalyzeMode,
    setSelectedMoveAnalysis,
    setGameViewersCount,
    setBoardRotation,
    setGameEndedData,
    setGamePlayerTurn,
    resetGame,
  };
};

export const gameCommonActions = createGameCommonActions();
