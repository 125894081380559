export const routesConstants = {
  HOME: '/',

  PROFILE: '/profile',
  INFO_BIO: '/info/bio',

  ARMAGEDDON: '/armageddon',
  ARMAGEDDON_FINALE: '/armageddon/finale',
  EMAIL_ARMAGEDDON: '/onlinearmageddon',

  COMMUNITY: '/community',
  COMMUNITY_CREATE: '/community/create',

  PLAYERS: '/players',
  PLAYERS_TOP: '/players/top',

  BROADCAST_LOBBY: '/broadcasts',
  BROADCAST_BOARD: '/broadcast',
  BROADCAST_HISTORY: '/broadcasts/history',
  BROADCAST_STREAM: '/broadcast/stream',
  BROADCAST_NEW_STREAM: '/broadcast/new_stream',

  PRICING: '/pricing',

  GAME: `/game`,
  FRIEND_WAITING: '/friend_waiting',
  FRIEND_INVITE: '/friend_invite',
  LOBBY: '/lobby',
  ACTIVE_GAMES: '/active-games',

  TOURNAMENTS: '/tournaments',

  MASTERCLASSES: '/masterclass',
  PUZZLES: '/puzzles',
  PUZZLES_RATED: '/puzzles/rated',

  CLUB_ADMIN_INFO: '/community/[id]/admin',
  CLUB_ADMIN_GALLERY: '/community/[id]/admin/gallery',
  CLUB_ADMIN_CARDS: '/community/[id]/admin/cards',
  CLUB_ADMIN_EVENTS: '/community/[id]/admin/events',
  CLUB_ADMIN_MEMBERS: '/community/[id]/admin/members',
  CLUB_ADMIN_REQUESTS: '/community/[id]/admin/requests',
  CLUB_ADMIN_TOURNAMENTS: '/community/[id]/admin/tournaments',
  CLUB_ADMIN_TOURNAMENTS_ITEM:
    '/community/[id]/admin/tournaments/[tournament_id]',
  CLUB_ADMIN_TOURNAMENTS_ITEM_ADD_PARTICIPANTS:
    '/community/[id]/admin/tournaments/[tournament_id]/add-participants',
  CLUB_ADMIN_TOURNAMENTS_ITEM_EDIT:
    '/community/[id]/admin/tournaments/[tournament_id]/edit',
  CLUB_ADMIN_TOURNAMENTS_DRAFTS_ITEM:
    '/community/[id]/admin/tournaments/drafts/[draft_id]',

  CHESS_CLUBS_REVOLUTION: '/chess-clubs-revolution',
  SWISS_QUEENS_WEDNESDAY: '/swiss-queens-wednesday',
  CLASH_OF_BLAMES: '/clash-of-blames',

  NOT_FOUND: '/not_found',
} as const;

export const gameRoutes = [
  routesConstants.GAME,
  routesConstants.FRIEND_WAITING,
  routesConstants.FRIEND_INVITE,
  `${routesConstants.BROADCAST_BOARD}/`,
  `${routesConstants.BROADCAST_NEW_STREAM}/`,
];
