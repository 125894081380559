import { useMemo } from 'react';

import { useForm } from '@utils/form/_useForm.hook';
import {
  emailIsNotValid,
  engValidator,
  passwordValidtor,
} from '@utils/_validators';

import { IRegistrationInputs } from '@types';

import { IPopupsContextState } from '../_types';
import { useNewProPlanPrices } from '@utils/hooks/_useProPlanPrice.hook';

export const useRegistrationComputed = (state: IPopupsContextState) => {
  const { annualFull } = useNewProPlanPrices();

  const paid = useMemo(
    () => state.registration.currentTab === 'pro',
    [state.registration.currentTab]
  );

  const stepsCount: number = useMemo(() => {
    if (state.registration.currentTab === 'pro') {
      return state.registration.freePro ? 5 : 6;
    }

    return 3;
  }, [state.registration.currentTab, state.registration.freePro]);

  const formData = useForm<IRegistrationInputs>({
    first_name: {
      value: '',
      required: true,
      validator: engValidator,
    },
    last_name: {
      value: '',
      required: true,
      validator: engValidator,
    },
    country: {
      value: '',
      required: true,
    },
    email: {
      value: '',
      required: true,
      validator: emailIsNotValid,
      formatter: (val: string) => val.trim(),
    },
    password: {
      value: '',
      required: true,
      validator: passwordValidtor,
    },
    password_repeat: {
      value: '',
      required: true,
      validator: passwordValidtor,
    },
  });

  return {
    price: annualFull,
    paid,
    stepsCount,
    formData,
  };
};
