import { TGameLobbyRatingModes } from '../_types';
import {
  BoardType,
  GameRatingMode,
  IRatingsData,
  TFideProp,
  TUserGameRating,
  TWCProp,
} from '@types';
import { userRatings } from '@constants';

const DefaultRatingMap = {
  [GameRatingMode.FIDERATED]: 900,
  [GameRatingMode.RATED]: 1200,
  [GameRatingMode.UNRATED]: 1200,
};

export const calcRatingLimitsByGameModeAndTimeControl = (
  userRating: TUserGameRating,
  mode: GameRatingMode
) => {
  const MAX_RATING = 3000;

  const rating = getUserGameRatingNumberByGameMode(userRating, mode);

  let lower = Math.round((rating - 100) / 10) * 10;
  let upper = lower + 200;

  if (rating >= MAX_RATING) {
    lower = MAX_RATING - 200;
    upper = MAX_RATING;
  }

  return {
    lower,
    upper,
  };
};

export const getUserGameRatingNumberByGameMode = (
  userRating: TUserGameRating,
  gameRatingMode: GameRatingMode
) => {
  return userRating === 'New'
    ? DefaultRatingMap[gameRatingMode as TGameLobbyRatingModes]
    : userRating;
};

export const getUserGameRating = (
  ratings: IRatingsData | null,
  mode: GameRatingMode,
  timeControl: BoardType
): TUserGameRating => {
  if (ratings) {
    const selectedRating = userRatings.find(
      (rating) => rating.board_type === timeControl
    );

    switch (mode) {
      case GameRatingMode.FIDERATED:
        return selectedRating &&
          ratings[selectedRating.fide_prop as TFideProp] !== 0
          ? ratings[selectedRating.fide_prop as TFideProp]
          : 'New';
      case GameRatingMode.RATED:
      case GameRatingMode.UNRATED:
        return selectedRating &&
          ratings[selectedRating.wc_prop as TWCProp] !== 0
          ? ratings[selectedRating.wc_prop as TWCProp]
          : 'New';
    }
  }

  return 'New';
};
