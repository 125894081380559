import dayjs from 'dayjs';

import formatLabel from '@chessarena/components/lib/utils/formatLabel';

import {
  ApiNotificationType,
  ApiNotification,
} from '@services/_notifications.service';

import { ILocalization, LocaleType } from '@types';
import { routesConstants } from '@constants';

import { Notification } from './_types';

function getDateFormat(locale: LocaleType) {
  switch (locale) {
    case 'ru':
    case 'kz':
      return 'DD.MM.YYYY';
    default:
      return 'MM/DD/YYYY';
  }
}

function getDateOfTournament(locale: LocaleType, dateTime?: string) {
  const dayjsDateTime = dayjs(dateTime);
  if (!dayjsDateTime.isValid()) return null;
  return dayjsDateTime.format(getDateFormat(locale));
}

function getTimeOfTournament(dateTime?: string) {
  const dayjsDateTime = dayjs(dateTime);
  if (!dayjsDateTime.isValid()) return null;
  return dayjsDateTime.format('HH:mm');
}

export function getNotificationTournamentStartReminder(
  data: ApiNotification,
  localization: ILocalization,
  locale: LocaleType
): Notification {
  return {
    id: data.id.toString(),
    title: formatLabel(
      localization?.notifications?.tournament_start_reminder?.title,
      { name: data.notification_object?.title || '' }
    ),
    description: formatLabel(
      localization?.notifications?.tournament_start_reminder?.description,
      {
        name: data.notification_object?.title || '',
        timeControl: data.notification_object?.time_control?.name || '',
        date: getDateOfTournament(locale, data.notification_object?.start),
        time: getTimeOfTournament(data.notification_object?.start),
      }
    ),
    type: data.notification_type,
    link: `${routesConstants.TOURNAMENTS}/${
      data.notification_object?.slug || data.notification_object?.id
    }`,
    unread: true,
  };
}

export function adaptNotification(
  data: ApiNotification,
  localization: ILocalization,
  locale: LocaleType
): Notification {
  switch (data.notification_type) {
    case ApiNotificationType.TOURNAMENT_START_REMINDER:
      return getNotificationTournamentStartReminder(data, localization, locale);
    default:
      return {
        id: data.id.toString(),
        title: data.title,
        description: data.description,
        type: data.notification_type,
        link: data.url,
        unread: true,
      };
  }
}
