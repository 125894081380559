import {
  DoneReason,
  DoneResult,
  EndReason,
  IRoundBoardMove,
  IRoundBoard,
  GameResult,
  GameStatus,
  ITourGameData,
  TournamentBoardStatus,
} from '@types';
import { eDoneResult, ePhase } from '@store/context/_common.types';
import { IGameState, ITurn, IGameCfg } from '@store/context/_types';
import { addMilisecondsToNow, syncedMoment } from '@utils/_helpers/_common';
import { msFromLastMoveToEnd } from '@utils/_helpers/_game';

// eslint-disable-next-line
export const getResult = (result: any): any => {
  if (!result) return;

  switch (result) {
    case DoneResult.WHITE_WIN:
      return 1;
    case DoneResult.DRAW:
      return 2;
    case DoneResult.BLACK_WIN:
      return 3;
    case DoneResult.ABORT:
      return 4;
    default:
      return 5; // game was cancel
  }
};
// eslint-disable-next-line
export const getReason = (reason: any): any => {
  if (!reason) return;

  switch (reason) {
    case DoneReason.WIN_RESIGN:
      return EndReason.RESIGN;
    case DoneReason.WIN_TIME_CONTROL:
      return EndReason.TIME_CONTROL;
    case DoneReason.WIN_CHECKMATE:
      return EndReason.CLASSIC;
    default:
      return undefined;
  }
};

export const getPlayersMsLeft = (gameState: IGameState | undefined) => {
  const playingPh = gameState?.playingPh;
  const cfg = gameState?.cfg;

  const currentMoveData = playingPh?.currTurn;
  const currentMoveStartTime = currentMoveData?.ts;
  const blackTimeData = playingPh?.bClk;
  const whiteTimeData = playingPh?.wClk;
  const whitePreMoveTimeout = cfg?.playingPhCfg?.wPreFirstMoveCfg?.timeout ?? 0;
  const blackPreMoveTimeout = cfg?.playingPhCfg?.bPreFirstMoveCfg?.timeout ?? 0;

  if (currentMoveStartTime && blackTimeData && whiteTimeData) {
    const isGameEnded = !!gameState?.donePh?.result;
    const absNum = currentMoveData.absNum;
    const now = Number(syncedMoment().format('x'));
    const msSpentFromMoveStart = now - currentMoveStartTime;
    const msLeftBeforeMove =
      currentMoveData.clk.limit - currentMoveData.clk.spent;
    const currPreMoveTimeout = currentMoveData.clr
      ? whitePreMoveTimeout
      : blackPreMoveTimeout;
    const isPreMoveTimeEnded =
      absNum > 2 || msSpentFromMoveStart > currPreMoveTimeout;

    const msSpentMoveAfterPreMove =
      now - (currentMoveData.ts + currPreMoveTimeout);
    const msSpent =
      absNum <= 2 ? msSpentMoveAfterPreMove : msSpentFromMoveStart;
    const moveMsLeftValue = msLeftBeforeMove - msSpent;
    const moveMsLeft = moveMsLeftValue > 0 ? moveMsLeftValue : 0;
    const whiteMsLeft = whiteTimeData.limit - whiteTimeData.spent;
    const blackMsLeft = blackTimeData.limit - blackTimeData.spent;

    return {
      whiteMsLeft:
        currentMoveData.clr && isPreMoveTimeEnded && !isGameEnded
          ? moveMsLeft
          : whiteMsLeft,
      blackMsLeft:
        !currentMoveData.clr && isPreMoveTimeEnded && !isGameEnded
          ? moveMsLeft
          : blackMsLeft,
    };
  }

  if (cfg) {
    const timeControlMs = cfg.playingPhCfg.wTc.init;
    return { whiteMsLeft: timeControlMs, blackMsLeft: timeControlMs };
  }

  return { whiteMsLeft: 0, blackMsLeft: 0 };
};

export const getBoardStatus = (currPh: ePhase) => {
  switch (currPh) {
    case ePhase.UNEXPECTED:
    case ePhase.CONNECTING:
    case ePhase.SCHEDULED:
      return TournamentBoardStatus.EXPECTED;

    case ePhase.PLAYING:
      return TournamentBoardStatus.GOES;

    case ePhase.DONE:
      return TournamentBoardStatus.COMPLETED;

    default:
      return TournamentBoardStatus.EXPECTED;
  }
};

export const getFormattedMove = (move: ITurn): IRoundBoardMove => {
  return {
    fen: move.end.fen,
    long_san: move.end.uci,
    san: move.end.san,
    is_white_move: move.start.clr,
    move_number: move.start.absNum,
  };
};

export const getChessgunMovesFromTurns = (turns: ITurn[]) => {
  return turns.map((move) => ({
    fen: move.end.fen,
    lan: move.end.uci,
    moveNumber: move.start.absNum,
  }));
};

export const getBroadcastGameStatus = (
  status: TournamentBoardStatus,
  result: GameResult | null
): GameStatus => {
  switch (status) {
    case TournamentBoardStatus.EXPECTED:
      return GameStatus.SUBSCRIBED;

    case TournamentBoardStatus.GOES:
      return GameStatus.STARTED;

    case TournamentBoardStatus.COMPLETED:
      return result === GameResult.ABORTED || result === GameResult.CANCELLED
        ? GameStatus.ABORTED
        : GameStatus.ENDED;

    default:
      return GameStatus.NONE;
  }
};

export const getGameTimers = (board: IRoundBoard, isBroadcast: boolean) => {
  let whiteMsLeft = board.white_ms_left;
  let blackMsLeft = board.black_ms_left;

  if (!isBroadcast) {
    return {
      whiteMsLeft,
      blackMsLeft,
      whiteMoveEndTime: null,
      blackMoveEndTime: null,
    };
  }

  const movesAmount = board.moves.length;
  const lastMove = movesAmount > 0 ? board.moves[movesAmount - 1] : null;
  const isWhiteMove = !!lastMove?.is_white_move;

  let whiteMoveEndTime: string | null = null;
  let blackMoveEndTime: string | null = null;

  if (lastMove?.made_in && !board.result) {
    if (isWhiteMove && board.black_ms_left) {
      blackMsLeft = msFromLastMoveToEnd(lastMove.made_in, board.black_ms_left);
    }

    if (!isWhiteMove && board.white_ms_left) {
      whiteMsLeft = msFromLastMoveToEnd(lastMove.made_in, board.white_ms_left);
    }
  }

  if (!isWhiteMove && whiteMsLeft) {
    whiteMoveEndTime = addMilisecondsToNow(whiteMsLeft);
  }

  if (isWhiteMove && blackMsLeft) {
    blackMoveEndTime = addMilisecondsToNow(blackMsLeft);
  }

  return {
    whiteMsLeft,
    blackMsLeft,
    whiteMoveEndTime,
    blackMoveEndTime,
  };
};

export const getPlayersPreMoveMs = ({
  currentMoveData,
  playingPhCfg,
}: {
  currentMoveData?: ITurn['start'] | null;
  playingPhCfg?: IGameCfg['playingPhCfg'];
}) => {
  const bPreFirstMoveCfg = playingPhCfg?.bPreFirstMoveCfg;
  const wPreFirstMoveCfg = playingPhCfg?.wPreFirstMoveCfg;

  const lastMoveTime = currentMoveData?.ts;

  if (!wPreFirstMoveCfg || !bPreFirstMoveCfg || !lastMoveTime)
    return {
      whitePreMoveMsLeft: 0,
      blackPreMoveMsLeft: 0,
      whitePreMoveMsAdditional: 0,
      blackPreMoveMsAdditional: 0,
    };
  const absNum = currentMoveData.absNum;
  const now = Number(syncedMoment().format('x'));
  const msSpent = now - lastMoveTime;
  const whitePreMoveMsLeft = wPreFirstMoveCfg.timeout - msSpent;
  const blackPreMoveMsLeft =
    absNum === 1
      ? bPreFirstMoveCfg.timeout
      : bPreFirstMoveCfg.timeout - msSpent;

  return {
    whitePreMoveMsLeft:
      absNum > 1 || whitePreMoveMsLeft <= 0 ? 0 : whitePreMoveMsLeft,
    blackPreMoveMsLeft:
      absNum > 2 || blackPreMoveMsLeft <= 0 ? 0 : blackPreMoveMsLeft,
    whitePreMoveMsAdditional: absNum === 1 ? wPreFirstMoveCfg.timeout : 0,
    blackPreMoveMsAdditional: absNum === 2 ? bPreFirstMoveCfg.timeout : 0,
  };
};

export const getGameStatus = (
  boardData: Pick<ITourGameData, 'result'> | null,
  currPh: IGameState['currPh'] | undefined,
  donePh: IGameState['donePh'] | null | undefined
) => {
  if (!boardData && !currPh) return GameStatus.NONE;

  if (boardData?.result) {
    return boardData.result === GameResult.ABORTED ||
      boardData.result === GameResult.CANCELLED
      ? GameStatus.ABORTED
      : GameStatus.ENDED;
  }

  if (currPh === ePhase.CONNECTING) {
    return GameStatus.INITIALIZING;
  }

  if (currPh === ePhase.PLAYING) {
    return GameStatus.STARTED;
  }

  if (currPh === ePhase.SCHEDULED) {
    return GameStatus.CREATED;
  }

  if (currPh === ePhase.DONE) {
    return donePh?.result === eDoneResult.ABORT
      ? GameStatus.ABORTED
      : GameStatus.ENDED;
  }

  return GameStatus.NONE;
};
