import { BoardType } from '@types';

export const userRatings = [
  {
    wc_prop: 'worldchess_rapid',
    fide_prop: 'fide_rapid',
    title: 'rapid',
    board_type: BoardType.RAPID,
  },
  {
    wc_prop: 'worldchess_blitz',
    fide_prop: 'fide_blitz',
    title: 'blitz',
    board_type: BoardType.BLITZ,
  },
  {
    wc_prop: 'worldchess_bullet',
    fide_prop: 'fide_bullet',
    title: 'bullet',
    board_type: BoardType.BULLET,
  },
];
