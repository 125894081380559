import {
  IUpdateFideError,
  IFideUsersResponse,
  IProSubscription,
  AccountVerification,
} from '@types';
import { Store, useStore, useStoreComputed } from 'storeshed';

export enum eFideSubscriptionStatus {
  NONE = 'NONE',
  ON_VERIFICATION = 'ON VERIFICATION',
  VERIFIED = 'VERIFIED',
}

export interface IFideSubscriptionStore {
  product_request: boolean;
  fide_request: boolean;
  fide_error: IUpdateFideError;
  find_fide_request: boolean;
  find_fide_users: IFideUsersResponse | null;
  pro_subscription: IProSubscription | null;
  autorenew_request: boolean;
  free_account_request: boolean;
}

export const fideSubscriptionInitialState = {
  product_request: false,
  fide_request: false,
  fide_error: null,
  find_fide_request: false,
  find_fide_users: null,
  pro_subscription: null,
  free_account_request: false,
  autorenew_request: false,
};

export const fideSubscriptionStore = new Store<IFideSubscriptionStore>({
  ...fideSubscriptionInitialState,
});

export const useFideSubscriptionStore = <
  K extends keyof IFideSubscriptionStore
>(
  key: K
): IFideSubscriptionStore[K] => useStore(fideSubscriptionStore, key);

export const useFideSubscriptionComputed = <
  K extends keyof IFideSubscriptionStore,
  R
>(
  key: K,
  cb: (state: IFideSubscriptionStore) => R
): R => useStoreComputed(fideSubscriptionStore, key, cb);

// ex useFideSubscriptionStatus from userContext/hooks
export const useFideSubscriptionStatus = () => {
  const fideSubscriptionStatus = useFideSubscriptionComputed(
    'pro_subscription',
    ({ pro_subscription: proSubscription }) => {
      if (!proSubscription || !proSubscription.is_active)
        return eFideSubscriptionStatus.NONE;

      if (
        proSubscription &&
        proSubscription.is_active &&
        proSubscription.verified === AccountVerification.ON_CHECK
      )
        return eFideSubscriptionStatus.ON_VERIFICATION;

      return eFideSubscriptionStatus.VERIFIED;
    }
  );

  return fideSubscriptionStatus;
};
