import React from 'react';

import { IBaseIcon } from './_types';
import { shapeColors } from '@styles/_variables';

/**
 * Иконка: Галочка в кружке
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - CSS класс иконки
 */
export const CheckInCircleIcon: React.FC<IBaseIcon> = ({
  fill = shapeColors['white'],
  width = '100%',
  height = '100%',
  className = '',
}) => (
  <svg
    // original size: 20x20
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9146 6.01405L15.9964 5.09583L7.4699 13.6223L3.5296 9.68204L2.60864 10.603L7.34417 15.3385L7.34514 15.3376L7.46814 15.4606L16.9146 6.01405Z"
      fill="black"
    />
  </svg>
);
