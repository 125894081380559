import React, { Dispatch } from 'react';
import { useActiveGamesActions } from './_actions';
import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';
import { Chessgun } from 'chessgun/core';
import { ITourGameData } from '@types';
import { useComputed } from './_useComputed.hook';

export type TGameTypeOptions = 'allGames' | 'single' | 'tournaments';
export type TTimeControlOptions = 'any' | 'rapid' | 'blitz' | 'bullet';
export type TRatingTypeOptions = 'all' | 'fide' | 'foa';

export type ActiveGame = {
  boardId: string;
  gameData: ILobbyContextGame;
  boardData: ITourGameData | null;
  engine: Chessgun;
};

export interface IActiveGamesContextState {
  filterGameType: TGameTypeOptions;
  filterTimeControl: TTimeControlOptions;
  filterRatingType: TRatingTypeOptions;
}

export type TActiveGamesContextActions = ReturnType<
  typeof useActiveGamesActions
>;

export type IActiveGamesContextDispatch = Dispatch<IActiveGamesAction>;

export interface IActiveGamesContextProps {
  state: IActiveGamesContextState;
  actions: TActiveGamesContextActions;
  computed: ReturnType<typeof useComputed>;
}

export enum eActiveGamesContextActionTypes {
  SET_FILTER_GAME_TYPE = 'set filter game type',
  SET_FILTER_TIME_CONTROL = 'set filter time control',
  SET_FILTER_RATING_TYPE = 'set filter rating type',
}

export type TActiveGamesContextReducer = React.Reducer<
  IActiveGamesContextState,
  IActiveGamesAction
>;

interface IActiveGamesActionMessage {
  type: eActiveGamesContextActionTypes;
}

interface ISetFilterGameType extends IActiveGamesActionMessage {
  type: eActiveGamesContextActionTypes.SET_FILTER_GAME_TYPE;
  payload: TGameTypeOptions;
}

interface ISetFilterTimeControl extends IActiveGamesActionMessage {
  type: eActiveGamesContextActionTypes.SET_FILTER_TIME_CONTROL;
  payload: TTimeControlOptions;
}

interface ISetFilterRatingType extends IActiveGamesActionMessage {
  type: eActiveGamesContextActionTypes.SET_FILTER_RATING_TYPE;
  payload: TRatingTypeOptions;
}

export type IActiveGamesAction =
  | ISetFilterGameType
  | ISetFilterTimeControl
  | ISetFilterRatingType;
