import { httpInstance } from '@services';
import { BaseService } from './_base.service';
import { INewsCommonData } from '@types';

export class NewsService extends BaseService {
  /**
   * Осуществляет запрос на получение новостей по тэгу
   *  @param {string} tag - новостной тэг
   */
  getTaggedNews(tag: string) {
    this.ajax.removeHeaders('Authorization');
    console.log(process.env.BASE_URL);
    return this.ajax.get<INewsCommonData>(
      `${process.env.GAME_URL}/news/tags/${tag}/json/`
    );
  }
}

export const newsService = new NewsService({
  instance: httpInstance,
});
