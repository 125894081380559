import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { useForm } from '@utils/form/_useForm.hook';
import { engValidator } from 'shared/utils/_validators';

import { IFideInputs } from '@types';
import { useApplicationContext } from '@application';
import { fideTabs } from '../_constants';
import { IFidePopupTab } from '../_types';
import { dateValidator } from '@utils/_helpers';

export const useFideComputed = () => {
  const router = useRouter();
  const freeProParam = router.query.free_pro as string;

  const { localization: l } = useApplicationContext();

  const freePro = useMemo(() => {
    return freeProParam === 'true';
  }, [freeProParam]);

  const tabs = useMemo<IFidePopupTab[]>(() => {
    return fideTabs.map(({ textKey, id, value }) => ({
      id,
      value,
      text: l.registration[textKey],
    }));
  }, [l]);

  const formData = useForm<IFideInputs>({
    first_name: {
      value: '',
      required: true,
      validator: (val) => engValidator(val, 'Incorrect first name'),
    },
    last_name: {
      value: '',
      required: true,
      validator: (val) => engValidator(val, 'Incorrect last name'),
    },
    date_of_birth: {
      value: '',
      required: true,
      validator: dateValidator,
    },
    place_of_birth: {
      value: '',
      required: true,
    },
    nationality: {
      value: '',
      required: true,
    },
    federation: {
      value: '',
      required: true,
    },
    gender: {
      value: '',
      required: true,
    },
  });

  return {
    freePro,
    formData,
    tabs,
  };
};
