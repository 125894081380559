var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AChessgunStoreOnGet } from "../store";
import { chessgunComputed } from "./_chessgunComputed";
import { DEFAULT_POSITION } from "./_constants";
import { createDefaultState } from "./_createDefaultState";
import { MoveController } from "./_MoveController";
import { parseAnalysisItem } from "./analysis/_parseAnalysisItem";
import { parseAnalysisItemNew } from "./analysis/_parseAnalysisItemNew";
import { flipTurn } from "./common";
import { compareFen } from "./fen/_compareFen";
import { parseFen } from "./fen/_parseFen";
import { parsePgn } from "./pgn/_parsePgn";
import { Piece } from "./Piece";
import { outOfBoard } from "./stringBoard/_outOfBoard";
import { getAddressByMoveId, getHistoryLastAddress, getHistoryPrevAddress, getIncrementedAddress, getMoveIdByAddress, getMoveIndexByAddress, } from "./history";
export class Chessgun extends AChessgunStoreOnGet {
    constructor(firstArg = DEFAULT_POSITION, opts = {}) {
        const $fen = typeof firstArg === "string" ? firstArg : DEFAULT_POSITION;
        const $opts = typeof firstArg === "string" ? opts : firstArg;
        const state = createDefaultState(Object.assign(Object.assign({}, $opts), { currentFen: $fen }));
        super(state, chessgunComputed);
        this.onPrevMove = () => {
            const { selectedMoveAddress } = this.store.get();
            if (selectedMoveAddress[0] === -1)
                return;
            const newSelectedMoveAddress = getHistoryPrevAddress(selectedMoveAddress);
            this.onSelectMove({ id: getMoveIdByAddress(newSelectedMoveAddress) });
        };
        this.onNextMove = () => {
            const { selectedMoveAddress, selectedMoveHistory } = this.store.get();
            const newSelectedMoveAddress = getIncrementedAddress(selectedMoveAddress);
            const newMoveIndex = getMoveIndexByAddress(newSelectedMoveAddress);
            if (newMoveIndex < selectedMoveHistory.length)
                this.onSelectMove({ id: getMoveIdByAddress(newSelectedMoveAddress) });
        };
        this.onFirstMove = () => {
            const { selectedMoveAddress } = this.store.get();
            if (selectedMoveAddress[0] === -1)
                return;
            this.onSelectMove({ id: getMoveIdByAddress([-1]) });
        };
        this.onLastMove = () => {
            const { selectedMoveAddress, history } = this.store.get();
            const address = getHistoryLastAddress(history, selectedMoveAddress);
            this.onSelectMove({ id: getMoveIdByAddress(address) });
        };
        /**
         * Останавливает комментирование и делает последний ход активным
         */
        this.onStopCommenting = () => {
            const { mainLineLastMove, history } = this.store.get();
            const newSelectedMoveAddress = (mainLineLastMove === null || mainLineLastMove === void 0 ? void 0 : mainLineLastMove.fen)
                ? [history.findIndex((item) => (item === null || item === void 0 ? void 0 : item.fen) === (mainLineLastMove === null || mainLineLastMove === void 0 ? void 0 : mainLineLastMove.fen))]
                : [-1];
            this.store.dispatch({
                selectedMoveAddress: newSelectedMoveAddress,
                commenting: false,
            });
            if (mainLineLastMove === null || mainLineLastMove === void 0 ? void 0 : mainLineLastMove.fen)
                this.setCurrentMove(mainLineLastMove === null || mainLineLastMove === void 0 ? void 0 : mainLineLastMove.fen);
            else
                this.setDefaultState();
        };
        this.moveController = new MoveController(this.store);
    }
    /**
     * Задает последний ход в истории текущим
     */
    setMoveToLatest() {
        const { history, pieces } = this.store.get();
        const latestState = history[history.length - 1];
        if (!latestState)
            return;
        pieces.forEach((piece) => {
            piece.setCurrentFen(latestState.fen);
        });
        const { turn, fen: currentFen, possibleCastlings, capturedFigures, checkmateData, enPassant, } = latestState;
        this.store.dispatch({
            turn: flipTurn(turn),
            enPassant,
            currentFen,
            checkmateData,
            capturedFigures,
            possibleCastlings,
        });
    }
    /**
     * Загружает новый ход или массив ходов
     * @param {string | string[]} notation - ход или массив ходов
     * @param {TMoveOpts} opts - дополнительные параметры
     * @param {boolean} opts.latest - является ли ход последним
     * @param {() => void} opts.onError - обработчик ошибки загрузки
     */
    move(notation, opts = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const { latest = true, mode, isUserMove = false, onError } = opts;
            if (latest) {
                const { commenting, lastMove, mainLineLastMove } = this.store.get();
                if (commenting) {
                    if (lastMove === null || lastMove === void 0 ? void 0 : lastMove.fen)
                        this.setCurrentMove(lastMove.fen);
                }
                else {
                    if (mainLineLastMove === null || mainLineLastMove === void 0 ? void 0 : mainLineLastMove.fen)
                        this.setCurrentMove(mainLineLastMove.fen);
                }
            }
            try {
                this.store.dispatch("loading", true);
                if (Array.isArray(notation)) {
                    this.moveController.moveMany(notation);
                }
                else {
                    this.moveController.move(notation, mode, isUserMove);
                }
                this.store.dispatch("loading", false);
            }
            catch (err) {
                console.log(err);
                onError && onError();
            }
        });
    }
    setDefaultState() {
        const currentFen = DEFAULT_POSITION;
        const { nextTurn: turn, possibleCastlings, checkmate } = parseFen(currentFen);
        this.store.dispatch({
            turn,
            currentFen,
            possibleCastlings,
            enPassant: null,
            movesWithoutCapture: 0,
            checkmateData: checkmate,
        });
        this.store.get("pieces").forEach((piece) => piece.setCurrentFen(currentFen));
    }
    /**
     * Задает текущий ход с помощью фена
     * @param {string} fen
     */
    setCurrentMove(fen) {
        const { selectedMoveHistory } = this.store.get();
        const $historyItem = selectedMoveHistory.find((item) => item && compareFen(item.fen, fen));
        if (!$historyItem)
            throw new Error(`No move in history with fen ${fen}`);
        const { turn, movesWithoutCapture, possibleCastlings, enPassant, checkmateData } = $historyItem;
        this.store.dispatch({
            enPassant,
            currentFen: fen,
            possibleCastlings,
            movesWithoutCapture,
            turn: flipTurn(turn),
            checkmateData,
        });
        this.store.get("pieces").forEach((piece) => piece.setCurrentFen(fen));
        const { lastMove, currentFen, history } = this.store.get();
        // На странице игры при вызове setCurrentMove без установки selectedMoveAddress lastMove будет некорректным
        if ((lastMove === null || lastMove === void 0 ? void 0 : lastMove.fen) !== currentFen) {
            const $index = history.findIndex((item) => item && compareFen(item.fen, fen));
            if ($index >= 0) {
                this.store.dispatch("selectedMoveAddress", [$index]);
            }
        }
    }
    /**
     * Задает текущий ход с помощью id и fen
     */
    onSelectMove({ id, fen }) {
        const { history } = this.store.get();
        const selectedMoveAddress = getAddressByMoveId(id);
        this.store.dispatch({
            selectedMoveAddress: selectedMoveAddress,
            commenting: (selectedMoveAddress === null || selectedMoveAddress === void 0 ? void 0 : selectedMoveAddress.length) > 1 || history.length - 1 !== selectedMoveAddress[0],
        });
        if (fen) {
            this.setCurrentMove(fen);
            return;
        }
        const { lastMove } = this.store.get();
        if (lastMove === null || lastMove === void 0 ? void 0 : lastMove.fen) {
            this.setCurrentMove(lastMove.fen);
            return;
        }
        this.setDefaultState();
    }
    undo() {
        this.setMoveToLatest();
        this.moveController.undo();
    }
    loadPgn(pgn) {
        const { headers, moves } = parsePgn(pgn);
        this.move(moves.map((move) => move.san));
        return { headers, moves };
    }
    /**
     * Загружает историю ходов до текущего
     */
    uploadHistory({ notations, type = "longSan", }) {
        const chessgun = new Chessgun();
        const { history, pieces } = this.store.get();
        notations.forEach((lan) => {
            chessgun.move(lan);
        });
        const $prevHistory = chessgun.get("history");
        const $newPieces = chessgun.get("pieces");
        $newPieces.forEach((fig) => {
            const history = fig.history;
            const last = history[history.length - 1];
            pieces
                .filter((fig) => !fig.dead)
                .forEach((currFig) => {
                const curHistory = currFig.history.find((item) => item.fen === last.fen);
                if ((curHistory === null || curHistory === void 0 ? void 0 : curHistory.dead) === last.dead && (curHistory === null || curHistory === void 0 ? void 0 : curHistory.position) === last.position) {
                    currFig.uploadHistory(history);
                }
            });
        });
        $prevHistory.forEach((item, i) => {
            history[i] = item;
        });
        this.store.dispatch({
            history,
            pieces: [...pieces, ...$newPieces.filter((piece) => piece.dead)],
            selectedMoveAddress: [history.length - 1],
        });
    }
    /**
     * Загружает анализ для ходов
     * @param items
     */
    uploadAnalysis(items) {
        let i = 0;
        const move = () => __awaiter(this, void 0, void 0, function* () {
            const item = items[i];
            const multipv = Array.isArray(item === null || item === void 0 ? void 0 : item.multipv) ? item.multipv[0] : null;
            const line = Array.isArray(multipv === null || multipv === void 0 ? void 0 : multipv.line) ? multipv === null || multipv === void 0 ? void 0 : multipv.line[0] : null;
            if (line) {
                const res = typeof line === "string"
                    ? yield parseAnalysisItem(items[i])
                    : parseAnalysisItemNew(items[i]);
                this.store.dispatch("analysis", [...this.store.get("analysis"), res]);
                i++;
                requestAnimationFrame(move);
            }
        });
        requestAnimationFrame(move);
    }
    /**
     * Добавляет объект анализа
     */
    addAnalysisItem(item, index) {
        requestAnimationFrame(() => __awaiter(this, void 0, void 0, function* () {
            const items = this.store.get("analysis");
            const parsedItem = item.multipv[0].line[0] === "string"
                ? yield parseAnalysisItem(item)
                : parseAnalysisItemNew(item);
            if (index !== undefined) {
                items.splice(index, 1, parsedItem);
            }
            else {
                items.push(parsedItem);
            }
            this.store.dispatch("analysis", items);
        }));
    }
    /**
     * Загружает fen. Старые данные удаляются
     * @param fen
     */
    loadFen(fen) {
        const { possibleCastlings, figures, enPassant, nextTurn, halfMoveNumber, movesWithoutCapture, capturedFigures, checkmate, } = parseFen(fen);
        const pieces = Piece.createPiecesFromParsedData({
            fen,
            figures,
            checkmateData: checkmate,
        });
        const history = new Array(halfMoveNumber).fill(null);
        this.store.dispatch({
            pieces,
            history,
            enPassant,
            capturedFigures,
            possibleCastlings,
            movesWithoutCapture,
            turn: nextTurn,
            currentFen: fen,
            checkmateData: checkmate,
            selectedMoveAddress: [history.length - 1],
        });
    }
    restart() {
        this.loadFen(DEFAULT_POSITION);
    }
    /**
     * Возвращает строку с виртульной доской с отступами и пробелами
     */
    printAscii() {
        const pieces = this.store.get("pieces").filter((piece) => !piece.dead);
        let board = "";
        for (let i = 0; i < 120; i++) {
            if (outOfBoard(i)) {
                board += "\n";
                i += 7;
                continue;
            }
            const piece = pieces.find((piece) => piece.position === i);
            board += piece ? piece.boardNotation() : " ";
        }
    }
}
