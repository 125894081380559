import { AccountVerification } from './_common.types';
import { UseFormState } from '@utils/form/_useForm.hook';

export enum ranksProducts {
  ACM = 'sku_ACM_title',
  AFM = 'sku_AFM_title',
  AIM = 'sku_AIM_title',
  AGM = 'sku_AGM_title',
}

export enum LastPaymentStatus {
  FAILED = 'failed',
  SUCCESS = 'success',
}

export enum ePaymentSource {
  STRIPE = 'stripe',
}

export enum ePriceId {
  PRO_ANNUAL = 'pro-annual',
  PRO_MONTHLY = 'pro-monthly',
}

export interface IProductSku {
  stripe_id: string;
  price: number;
  currency: string;
  product: string;
  status: number;
}

export interface ICouponData {
  amountOff: number | null;
  id: string;
  percentOff: number | null;
  valid: boolean;
}

export interface IRecurringPurchase {
  payment_source: ePaymentSource;
  price_stripe_id: ePriceId;
  price_type: string; // 'recurring'...
  status: string; // 'active'...
  valid_from: string;
  valid_to: string;
  cancel_at_period_end: boolean;
}

export interface ISubscriptionData {
  cancel_at_period_end: boolean | null;
  created: string;
  is_active: boolean;
  invoice_url?: string | null;
  period: {
    bounds: string;
    lower: string;
    upper: string;
  };
  plan: {
    amount: number;
    created: string;
    currency: string;
    interval: string;
    interval_count: number;
    name: string;
    old_price: number | null;
    status: number;
    stripe_id: string;
  };
  status: string;
  stripe_id: string;
  last_payment_status: LastPaymentStatus;
}

export interface IOrderData {
  amount: number;
  created: string;
  currency: string;
  product: {
    created: string;
    currency: string;
    old_price: number | null;
    price: number;
    product: string;
    status: number;
    stripe_id: string;
    tournament: number | null;
  };
  status: string;
}

export interface IPlan {
  amount: number;
  created: string;
  currency: string;
  interval: string;
  interval_count: number;
  name: string;
  old_price: number | null;
  status: number;
  stripe_id: string;
}

export interface IPlansData {
  pro_plan?: IPlan | null;
  some_plan?: IPlan | null;
}

export interface IOrderData {
  amount: number;
  created: string;
  currency: string;
  product: {
    created: string;
    currency: string;
    old_price: number | null;
    price: number;
    product: string;
    status: number;
    stripe_id: string;
    tournament: number | null;
  };
  status: string;
}

export interface IProSubscription {
  fide_id: number | null;
  free_requested: boolean;
  is_active: boolean;
  verified: AccountVerification | null;
  data: ISubscriptionData | null;
}

export interface IStripeError {
  code?: string;
  message: string;
  param?: string;
  type?: string;
}

export interface IBuyData {
  stripe_key: string;
  card: UseFormState<
    'card_number' | 'exp_month' | 'exp_year' | 'securitycode' | 'name'
  >;
  plan: string;
  playerId?: string | number;
  successCallback: () => void;
  invoiceCallback: (invoiceUrl: string) => void;
  failCallback: () => void;
  coupon?: string;
}
