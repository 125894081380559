import { MULTIBOARD_BOARDS_LIMIT } from '@constants/_tournament.constants';
import { appService } from '@services/_app.service';
import { tournamentService } from '@services/_tournament.service';
import { TMainStore } from '@store/storeshed';
import {
  IRoundBoard,
  ISubscribedRoundBoard,
  ISystemPopups,
  TournamentBoardStatus,
  TournamentBoardType,
  TournamentStatus,
  TournamentSubscriptions,
  TournamentType,
} from '@types';
import { getGameTimers } from '@utils/_ng_helpers';
import { chessSide } from 'chessgun/core';

import { tournamentActions } from './index';
import { gameService } from '@services/_game.service';

export const createTournamentBoardsActions = () => {
  /**
   * Задает значение фильтра игр по статусу
   * @param {TournamentBoardStatus | null} status - статус игр для фильтра
   */
  const setRoundBoardsStatus = (
    { tournamentStore: store }: TMainStore,
    status: TournamentBoardStatus | null
  ) => {
    store.dispatch('round_boards_status', status);
  };

  /**
   * Задает значение номера страницы для фильтра игр
   * @param {number} page - номер страницы для фильтра игр
   */
  const setRoundBoardsPage = (
    { tournamentStore: store }: TMainStore,
    page: number
  ) => {
    store.dispatch('round_boards_page_number', page);
  };

  /**
   * Задает значение номера страницы для фильтра игр
   * @param {number} page - номер страницы для фильтра игр
   */
  const setRoundBoardsLimit = (
    { tournamentStore: store }: TMainStore,
    limit: number
  ) => {
    store.dispatch('round_boards_page_limit', limit);
  };

  /**
   * Задает значение количества активных досок в раунде
   * @param {number} boardsAmount - количество активных досок в раунде
   */
  const setActiveBoardsAmount = (
    { tournamentStore: store }: TMainStore,
    boardsAmount: number
  ) => {
    store.dispatch('active_boards_amount', boardsAmount);
  };

  /**
   * Выполняет запрос досок текущего раунда
   * @param {number | string} tournamentId - id турнира
   * @param {number | string} roundId - id раунда
   */
  const getRoundBoards = async (
    { tournamentStore: store, userDataStore }: TMainStore,
    {
      tournamentId,
      roundId,
      ngRoundCreated,
      popups,
      subscriptions,
      loading,
    }: {
      tournamentId: number | string;
      roundId: number | string;
      ngRoundCreated?: boolean;
      popups?: ISystemPopups;
      subscriptions?: TournamentSubscriptions;
      loading?: boolean;
    }
  ) => {
    const roundsBoardsStatus = store.get('round_boards_status');
    const roundsBoardsPageNumber = store.get('round_boards_page_number');
    const roundsBoardsPageLimit = store.get('round_boards_page_limit');

    if (loading) store.dispatch('round_boards_request', true);

    try {
      const { ok, data } = await tournamentService.getRoundBoards({
        tournamentId,
        roundId,
        limit: roundsBoardsPageLimit,
        offset: (roundsBoardsPageNumber - 1) * roundsBoardsPageLimit,
        status: roundsBoardsStatus || undefined,
      });

      if (ok) {
        const tournamentData = store.get('tournament_data');
        const userDisqualifed = !!store.get('tournament_you')?.disqualified;
        const isBroadcast =
          store.get('boards_type') === TournamentBoardType.BROADCAST;
        const profile = userDataStore.get('data');

        // TODO: после полного перехода на нг нужно перенести
        // создание досок из handleRoundBoards
        if (!isBroadcast) tournamentActions.getRoundNGBoardsData(data.results);

        const showPopup = Boolean(
          ngRoundCreated &&
            popups &&
            tournamentData?.user_signed &&
            tournamentData.kind === TournamentType.SWISS &&
            !userDisqualifed
        );

        if (showPopup) {
          if (data.players_free_win.includes(profile?.player?.player_id || 0)) {
            popups?.alert(
              "Oops, we don't have an opponent for you this round. But the good news is you won this tour!"
            );
          }
        }

        store.dispatch('round_boards_amount', data.count);

        const activeBoardsAmount = store.get('active_boards_amount');
        if (!activeBoardsAmount) {
          tournamentActions.setActiveBoardsAmount(data.active_boards_count);
        }

        tournamentActions.handleRoundBoards(data.results);

        tournamentActions.updateIndividualSubscriptions(subscriptions);
      }
    } catch (error) {
      console.log(error);
    }

    store.dispatch('round_boards_request', false);
  };

  const updateIndividualSubscriptions = (
    { tournamentStore: store }: TMainStore,
    subscriptions?: TournamentSubscriptions
  ) => {
    const tournamentData = store.get('tournament_data');
    const roundsBoardsPageNumber = store.get('round_boards_page_number');
    const roundsBoardsPageLimit = store.get('round_boards_page_limit');

    const needsIndividualSubscription =
      tournamentData?.status === TournamentStatus.GOES &&
      roundsBoardsPageLimit * roundsBoardsPageNumber > MULTIBOARD_BOARDS_LIMIT;

    if (needsIndividualSubscription && subscriptions) {
      tournamentActions.handleGameServerSubscriptionsToBoards(subscriptions);
    }
  };

  const handleGameServerSubscriptionsToBoards = (
    { tournamentStore, authStore }: TMainStore,
    { subscribe, unsubscribe }: TournamentSubscriptions
  ) => {
    const roundBoards = tournamentStore.get('round_boards');
    const playerId = authStore.get('uid');
    const activeGameServerSubscriptions = tournamentStore.get(
      'round_boards_game_server_subscriptions'
    );
    const roundsBoardsPageNumber = tournamentStore.get(
      'round_boards_page_number'
    );
    const roundsBoardsPageLimit = tournamentStore.get(
      'round_boards_page_limit'
    );

    const roundBoardsIds = new Set(roundBoards.map(({ board_id }) => board_id));
    const newSubscribedGames = new Set<string>(activeGameServerSubscriptions);

    // Remove old subscriptions
    Array.from(activeGameServerSubscriptions).forEach((boardId) => {
      if (!roundBoardsIds.has(boardId)) {
        console.log('unsubbed off ', boardId);
        newSubscribedGames.delete(boardId);
        unsubscribe(boardId);
      }
    });

    // Add new subscriptions
    roundBoards.forEach(({ board_id, status, moves }, idx) => {
      const currentItemIndex =
        (roundsBoardsPageNumber - 1) * roundsBoardsPageLimit + (idx + 1);
      const isOutOfMultiboardRange = currentItemIndex > MULTIBOARD_BOARDS_LIMIT;

      if (
        !activeGameServerSubscriptions.has(board_id) &&
        isOutOfMultiboardRange &&
        status === TournamentBoardStatus.GOES
      ) {
        newSubscribedGames.add(board_id);

        subscribe({
          settings: {
            boardId: board_id,
            playerId: playerId as string,
            userType: 'viewer',
            region: null,
          },
          moves,
        });
      }
    });

    tournamentStore.dispatch(
      'round_boards_game_server_subscriptions',
      newSubscribedGames
    );
  };

  const getRoundNGBoardsData = async (
    {}: TMainStore,
    boards: IRoundBoard[]
  ) => {
    try {
      const gameIds: string[] = [];
      boards.map((board) => {
        if (board.status !== TournamentBoardStatus.COMPLETED) {
          gameIds.push(board.board_id);
        }
      });

      // const { data: regions } = await appService.getRegionsByGIds(gameIds);

      // const groupByCategory = Object.entries(regions).reduce(
      //   (group: { [key: string]: string[] }, [key, value]) => {
      //     group[value] = group[value] ?? [];
      //     group[value].push(key);
      //     return group;
      //   },
      //   {}
      // );
      // console.log(groupByCategory);

      // TODO: проверить, почему при первом ходе перезапрашиваются доски
      if (gameIds.length) {
        const { data } = await appService.getNGPlayerGames({
          gameIds,
        });

        data.forEach((board) => {
          tournamentActions.updateNGTourBoardData(board);
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  /**
   * Формирует движок доски
   * @param {IRoundBoard[]} boards - доски
   */
  const handleRoundBoards = (
    { tournamentStore: store }: TMainStore,
    boards: IRoundBoard[]
  ) => {
    const roundBoards = store.get('round_boards');
    const newBoards: ISubscribedRoundBoard[] = [];

    boards.forEach((board) => {
      const roundBoard = roundBoards.find(
        (roundBoard) => board.board_id === roundBoard.board_id
      );

      const isBroadcast =
        store.get('boards_type') === TournamentBoardType.BROADCAST;
      const movesAmount = board.moves.length;
      const lastMove = board.moves[movesAmount - 1];
      const playerTurn: chessSide =
        lastMove && lastMove.is_white_move ? chessSide.BLACK : chessSide.WHITE;

      const { whiteMsLeft, blackMsLeft, whiteMoveEndTime, blackMoveEndTime } =
        getGameTimers(board, isBroadcast);

      newBoards.push({
        ...board,
        white_ms_left: whiteMsLeft,
        black_ms_left: blackMsLeft,
        playerTurn,
        white_move_end_time: whiteMoveEndTime,
        black_move_end_time: blackMoveEndTime,
        white_pre_move_ms_left: roundBoard
          ? roundBoard.white_pre_move_ms_left
          : 0,
        black_pre_move_ms_left: roundBoard
          ? roundBoard.black_pre_move_ms_left
          : 0,
        analysis: lastMove?.analysis,
        moves:
          board.status !== TournamentBoardStatus.COMPLETED && !isBroadcast
            ? []
            : board.moves,
        playingPhCfg: roundBoard?.playingPhCfg ?? null,
      });
    });

    store.dispatch('round_boards', newBoards);
  };

  const getAndSubscribeToStandingSelectedBoard = async (
    { tournamentStore, authStore }: TMainStore,
    { subscribe, unsubscribe }: TournamentSubscriptions,
    gameId?: string
  ) => {
    const playerId = authStore.get('uid');
    const standingSelectedGame = tournamentStore.get('standing_selected_game');
    const roundBoards = tournamentStore.get('round_boards');

    if (standingSelectedGame) {
      const standingSelectedGameRoundBoard = roundBoards.find(
        (item) => item.board_id === standingSelectedGame.board_id
      );

      tournamentStore.dispatch('standing_selected_game', null);

      if (
        standingSelectedGame.status !== TournamentBoardStatus.COMPLETED &&
        !standingSelectedGameRoundBoard
      ) {
        unsubscribe(standingSelectedGame?.board_id);
      }
    }

    if (gameId) {
      const roundBoard = roundBoards.find((item) => item.board_id === gameId);

      if (roundBoard) {
        tournamentStore.dispatch('standing_selected_game', roundBoard);
      } else {
        const { data } = await gameService.getBoardData(gameId);

        tournamentStore.dispatch(
          'standing_selected_game',
          data as unknown as ISubscribedRoundBoard
        );

        if (data.status !== TournamentBoardStatus.COMPLETED) {
          const { data: games } = await appService.getNGPlayerGames({
            gameIds: [gameId],
          });

          tournamentActions.updateNGTourBoardData(games[0]);

          subscribe({
            settings: {
              boardId: gameId,
              playerId: playerId as string,
              userType: 'viewer',
              region: null,
            },
            moves: data.moves,
          });
        }
      }
    }
  };

  return {
    setRoundBoardsStatus,
    setRoundBoardsPage,
    setRoundBoardsLimit,
    getRoundBoards,
    setActiveBoardsAmount,
    handleRoundBoards,
    getRoundNGBoardsData,
    updateIndividualSubscriptions,
    handleGameServerSubscriptionsToBoards,
    getAndSubscribeToStandingSelectedBoard,
  };
};
