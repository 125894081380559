export enum LocaleType {
  EN = 'en',
  RU = 'ru',
  KZ = 'kz',
}

export enum eAccessLevel {
  UNAUTHORIZED = 0,
  FREE = 1,
  PRO = 2,
}

export enum AccountVerification {
  NOT_VERIFIED = 0,
  ON_CHECK = 1,
  VERIFIED = 2,
}

export enum eChessColor {
  w = 'white',
  b = 'black',
}

export enum playerSide {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum BoardType {
  CLASSIC = 1,
  RAPID = 2,
  BLITZ = 3,
  ARMAGEDDON = 4,
  BULLET = 5,
  DAILY = 6,
}

export enum figureType {
  PAWN = 'p',
  KING = 'k',
  QUEEN = 'q',
  ROOK = 'r',
  BISHOP = 'b',
  KNIGHT = 'n',
}

export enum GameRatingMode {
  UNRATED = 'non-rated',
  RATED = 'worldchess',
  FIDERATED = 'fide',
  OTB = 'otb',
}

export enum GamePlayerType {
  ANONYMOUS = 'anonymous',
  WORLDCHESS = 'worldchess',
  FIDE = 'fide',
}

export enum eSoundsTheme {
  OFF = 'off',
  ON = 'on',
}

export enum ePremovesTheme {
  OFF = 'off',
  ON = 'on',
}

export enum eAutoPromotionTheme {
  OFF = 'off',
  ON = 'on',
}

export enum checkEmailErrorCodes {
  EMAIL_ALREADY_EXISTS = 'email_already_exists',
  EMAIL_DOMAIN_IS_BANNED = 'email_domain_is_banned',
  EMPTY_EMAIL = 'empty_email',
  EMAIL_ALREADY_EXISTS_NOT_VERIFIED = 'email_already_exists_not_verified',
  SOMETHING_WENTS_WRONG = 'something_wents_wrong',
}

export interface ILocalization {
  [key: string]: any; // eslint-disable-line
}

export interface ITimeControl {
  id: number;
  name: string;
  board_type: BoardType;
  start_time: string;
  black_start_time: string;
  additional_time: string;
  additional_time_move: number;
  increment: string;
  increment_start_move: number;
  board_type_name: string;
  white_start_time: string;
}

export interface IAdvancedTimeControl extends ITimeControl {
  increment_number: number;
  start_time_number: number;
}

export interface IGroupedTimeControl {
  board_type: BoardType;
  name: string;
  controls: IAdvancedTimeControl[];
}

export interface IRequestPagination<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<T>;
}

export interface IImageThumbnails {
  full?: string | null;
  medium?: string | null;
  small?: string | null;
}

export interface ICountry {
  code: string;
  id: number;
  long_code: string;
  flag_image: { svg: string };
  name: string;
  region: number;
  name_localization: Record<LocaleType, string>;
  federation_en: string;
  federation_ru: string;
}

export interface IRegion {
  id: number;
  name: string;
  name_localization: Record<LocaleType, string>;
}

export interface IRatingsData {
  fide_blitz: number;
  fide_bullet: number;
  fide_id: number;
  fide_rapid: number;
  fide_rating: number;
  since: string;
  verification: number;
  worldchess_blitz: number;
  worldchess_bullet: number;
  worldchess_rapid: number;
  worldchess_rating: number;
  otb_blitz: number;
  otb_standard: number;
  otb_rapid: number;
  otb_rating: number;
}
