import { useContext } from 'react';
import { eBoardThemes } from '@types';

import { mediaBreakpoints } from '@chessarena/components/lib/common/constants';
import useWindowDimension from '@chessarena/components/lib/utils/useWindowDimension';
import { GameBoardSettingsPopupSliderContext } from '@chessarena/components/lib/game/GameBoardSettingsPopupSlider/context';

import { BoardPreview } from '@components/molecules/BoardPreview';
import { IBoard } from './types';

const BoardPreviewWrapper: React.FC<{ theme: eBoardThemes }> = ({ theme }) => {
  const { width: windowWidth } = useWindowDimension();
  const { width: sliderWidth } = useContext(
    GameBoardSettingsPopupSliderContext
  );
  const isMobile = windowWidth < mediaBreakpoints.tablet;

  const size = isMobile ? sliderWidth : 360;

  return (
    <BoardPreview
      size={size}
      theme={theme}
      fenPos="r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R"
    />
  );
};

export const settingsPopupBoards: IBoard[] = [
  {
    value: eBoardThemes.WORLDCHESS,
    name: 'Worldchess',
    content: <BoardPreviewWrapper theme={eBoardThemes.WORLDCHESS} />,
    countryId: null,
    paid: false,
  },
  {
    value: eBoardThemes.STANDARD,
    name: 'Standard',
    content: <BoardPreviewWrapper theme={eBoardThemes.STANDARD} />,
    countryId: null,
    paid: false,
  },
  {
    value: eBoardThemes.TEXTBOOK,
    name: 'Textbook',
    content: <BoardPreviewWrapper theme={eBoardThemes.TEXTBOOK} />,
    countryId: null,
    paid: false,
  },
  {
    value: eBoardThemes.MARBLE,
    name: 'Marble',
    content: <BoardPreviewWrapper theme={eBoardThemes.MARBLE} />,
    countryId: null,
    paid: false,
  },
  {
    value: eBoardThemes.HIPSTER,
    name: 'Hipster',
    content: <BoardPreviewWrapper theme={eBoardThemes.HIPSTER} />,
    countryId: null,
    paid: true,
  },
  {
    value: eBoardThemes.AQUA,
    name: 'Aqua',
    content: <BoardPreviewWrapper theme={eBoardThemes.AQUA} />,
    countryId: null,
    paid: true,
  },
  {
    value: eBoardThemes.CURRY,
    name: 'Curry',
    content: <BoardPreviewWrapper theme={eBoardThemes.CURRY} />,
    countryId: null,
    paid: true,
  },
  {
    value: eBoardThemes.LEMON_TREE,
    name: 'Lemon Tree',
    content: <BoardPreviewWrapper theme={eBoardThemes.LEMON_TREE} />,
    countryId: null,
    paid: true,
  },
  {
    value: eBoardThemes.BUBBLEGUM,
    name: 'Bubblegum',
    content: <BoardPreviewWrapper theme={eBoardThemes.BUBBLEGUM} />,
    countryId: null,
    paid: true,
  },
  {
    value: eBoardThemes.PHILIPPINES,
    name: 'Philippines',
    content: <BoardPreviewWrapper theme={eBoardThemes.PHILIPPINES} />,
    countryId: 119, // Philippines
    paid: false,
  },
];
