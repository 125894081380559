import { IStripeError, ICouponData, IProfileData } from '@types';
import { Store, useStore } from 'storeshed';

export interface IPaymentStore {
  buy_title_request: boolean;
  stripe_token: string | null;
  payment_error: IStripeError | null;
  promocode_request: boolean;
  promocode_error: string | null;
  promocode: ICouponData | null;
  popup_profile: IProfileData | null;
  popup_profile_request: boolean;
}

export const paymentInitialState = {
  buy_title_request: false,
  stripe_token: null,
  payment_error: null,
  promocode_request: false,
  promocode_error: null,
  promocode: null,
  popup_profile: null,
  popup_profile_request: false,
};

export const paymentStore = new Store<IPaymentStore>({
  ...paymentInitialState,
});

export const usePaymentStore = <K extends keyof IPaymentStore>(
  key: K
): IPaymentStore[K] => useStore(paymentStore, key);
