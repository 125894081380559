import { DEFAULT_POSITION, engineGameMode } from "../_constants";
export const getIncrementedAddress = (indexes) => {
    if (!indexes.length)
        return [0];
    return [...indexes.slice(0, -1), indexes[indexes.length - 1] + 1];
};
export const setHistoryTree = ({ move, history, address, mode, selectedMoveAddress, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    if (address && address.length > 1)
        return setHistoryTree({
            move,
            history: ((_b = (_a = history[address[0]]) === null || _a === void 0 ? void 0 : _a.variations) === null || _b === void 0 ? void 0 : _b[address[1]]) || [],
            address: address.slice(2),
            mode,
            selectedMoveAddress,
        });
    // Если нет ходов или выбран последний ход
    if (!address.length || !history.length || (history === null || history === void 0 ? void 0 : history.length) - 1 === address[0]) {
        const isMainLine = !selectedMoveAddress.length || selectedMoveAddress.length === 1;
        const hasEmptyItem = history.length && history[history.length - 1] && !((_c = history[history.length - 1]) === null || _c === void 0 ? void 0 : _c.fen);
        if (isMainLine && !hasEmptyItem && mode === engineGameMode.STREAM) {
            // Создать пустой ход с вариантом (заполнится когда придет ход с сервера)
            const startInd = getIncrementedAddress(address);
            const newMove = { variations: [[move]] };
            history.push(newMove);
            return [...startInd, 0, 0];
        }
        // добавить новый ход в конец истории и обновить адрес
        history.push(move);
        return [...selectedMoveAddress.slice(0, -1), history.length - 1];
    }
    // Если следующий ход совпадает с новым ходом - обновить только адрес хода
    if (history[address[0] + 1] && ((_d = history[address[0] + 1]) === null || _d === void 0 ? void 0 : _d.san) === move.san) {
        return getIncrementedAddress(selectedMoveAddress);
    }
    // Если следующий ход имеет варианты
    if (history[address[0] + 1] && ((_e = history[address[0] + 1]) === null || _e === void 0 ? void 0 : _e.variations)) {
        const fIndex = (_h = (_g = (_f = history[address[0] + 1]) === null || _f === void 0 ? void 0 : _f.variations) === null || _g === void 0 ? void 0 : _g.findIndex((el) => el[0].san === move.san)) !== null && _h !== void 0 ? _h : -1;
        // Если уже есть такой вариант - обновить адрес хода
        if (fIndex >= 0) {
            return [...getIncrementedAddress(selectedMoveAddress), fIndex, 0];
        }
        // иначе добавить новый вариант хода
        const i = ((_k = (_j = history[address[0] + 1]) === null || _j === void 0 ? void 0 : _j.variations) === null || _k === void 0 ? void 0 : _k.length) || 0;
        const lastInd = getIncrementedAddress(selectedMoveAddress);
        const newItem = [move];
        (_m = (_l = history[address[0] + 1]) === null || _l === void 0 ? void 0 : _l.variations) === null || _m === void 0 ? void 0 : _m.push(newItem);
        return [...lastInd, i, 0];
    }
    // Иначе создаем вариант для след хода и обновляем адрес
    const startInd = getIncrementedAddress(selectedMoveAddress);
    (_p = (_o = history[address[0] + 1]) === null || _o === void 0 ? void 0 : _o.variations) === null || _p === void 0 ? void 0 : _p.push([move]);
    return [...startInd, 0, 0];
};
export const getHistoryTree = ({ move, history, selectedMoveAddress, mode, commenting, isUserMove, }) => {
    var _a;
    let result = [...history];
    if (!isUserMove) {
        // Если есть пустой элемент - заполнить данными
        if (history.length && history[history.length - 1] && !((_a = history[history.length - 1]) === null || _a === void 0 ? void 0 : _a.fen)) {
            const lastMove = history[history.length - 1];
            result = [...result.slice(0, -1), Object.assign(Object.assign({}, move), { variations: lastMove === null || lastMove === void 0 ? void 0 : lastMove.variations })];
            return {
                result,
                newMoveAddress: commenting
                    ? selectedMoveAddress
                    : getIncrementedAddress(selectedMoveAddress),
            };
        }
        result.push(move);
        return { result, newMoveAddress: commenting ? selectedMoveAddress : [result.length - 1] };
    }
    const newMoveAddress = setHistoryTree({
        move,
        history: result,
        address: selectedMoveAddress,
        mode,
        selectedMoveAddress,
    });
    return { result, newMoveAddress };
};
export const getSelectedAddressLineHistory = (history, indexes) => {
    let result = [];
    (function flat(hist, ind) {
        var _a, _b;
        if (ind.length === 1)
            result = [...result, ...hist];
        else {
            result.push(...hist.slice(0, ind[0]));
            flat(((_b = (_a = hist[ind[0]]) === null || _a === void 0 ? void 0 : _a.variations) === null || _b === void 0 ? void 0 : _b[ind[1]]) || [], ind.slice(2));
        }
    })(history, indexes);
    return result;
};
export const getMoveByAddress = (history, address) => {
    var _a, _b;
    if (!(history === null || history === void 0 ? void 0 : history.length) || !(address === null || address === void 0 ? void 0 : address.length))
        return null;
    if (address.length === 1)
        return history[address[0]];
    else
        return getMoveByAddress(((_b = (_a = history[address[0]]) === null || _a === void 0 ? void 0 : _a.variations) === null || _b === void 0 ? void 0 : _b[address[1]]) || [], address.slice(2));
};
export const getMoveIndexByAddress = (address) => {
    const filtered = address.filter((item, i) => !(i % 2));
    return filtered.reduce((acc, item) => {
        if (address)
            acc += item;
        return acc;
    }, 0);
};
export const getIsSilentMove = (lastMove, mainLineLastMove, commenting, isUserMove) => {
    if (commenting) {
        if (isUserMove)
            return false;
        if (lastMove && mainLineLastMove)
            return lastMove.fen !== mainLineLastMove.fen;
        return true;
    }
    return false;
};
export const getHistoryPrevAddress = (address) => {
    if (address.length === 1 && address[0] === 0)
        return [-1];
    const newAddress = [...address.slice(0, -1), address[address.length - 1] - 1];
    if (newAddress[newAddress.length - 1] < 0) {
        const lastInd = newAddress.slice(0, -2);
        return [...lastInd.slice(0, -1), lastInd[lastInd.length - 1] - 1];
    }
    return newAddress;
};
// Вычисляет индех последнего эелемента линии
export const getHistoryLastIndex = (history, address) => {
    let lastLine = history;
    address.slice(0, -1).forEach((item, i) => {
        var _a;
        if (i % 2 === 0)
            lastLine = lastLine === null || lastLine === void 0 ? void 0 : lastLine[item];
        else {
            const $item = lastLine;
            lastLine = (_a = $item === null || $item === void 0 ? void 0 : $item.variations) === null || _a === void 0 ? void 0 : _a[item];
        }
    });
    return (lastLine === null || lastLine === void 0 ? void 0 : lastLine.length) - 1;
};
export const getHistoryLastAddress = (history, address) => {
    const lastIndex = getHistoryLastIndex(history, address);
    return [...address.slice(0, -1), lastIndex];
};
export const getMoveIdByAddress = (address) => {
    return address.join("_");
};
export const getAddressByMoveId = (id) => {
    if (!id)
        return [];
    return id.split("_").map(Number);
};
export const getMoveCurrentFen = ({ currentFen, mainLineLastMove, commenting, isUserMove, }) => {
    if (commenting) {
        if (isUserMove)
            return currentFen;
        return (mainLineLastMove === null || mainLineLastMove === void 0 ? void 0 : mainLineLastMove.fen) || DEFAULT_POSITION;
    }
    return currentFen;
};
