import { useCallback, useEffect, useState } from 'react';

import { LocaleType } from '@types';

import { translations as localTranslations } from '@utils/_locales';

function isValidLocaleType(value?: string): value is LocaleType {
  return Object.values(LocaleType).includes(value as LocaleType);
}

export const useI18n = (initLocale?: LocaleType) => {
  const checkedLocale = isValidLocaleType(initLocale)
    ? initLocale
    : LocaleType.EN;

  const [locale, setLocale] = useState(checkedLocale);
  const [translations, setTranslations] = useState(localTranslations);
  const [localization, setLocalization] = useState(translations[checkedLocale]);

  useEffect(() => {
    const loadLocaleData = async () => {
      // Динамический импорт нужного файла локализации
      const translation = await import(`../../assets/locales/${locale}.json`);

      setTranslations((prev) => {
        const newTranslations = {
          ...prev,
          [locale]: translation,
        };

        setLocalization(newTranslations[locale]);

        return newTranslations;
      });
    };

    loadLocaleData();
  }, [locale]);

  const changeLocale = useCallback(
    (newLocale: LocaleType) => {
      setLocale(newLocale);
      setLocalization(translations[newLocale]);
    },
    [translations]
  );

  return { locale, localization, changeLocale };
};
