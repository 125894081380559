import { tournamentActions } from '.';
import { TMainStore } from '@store/storeshed';
import { tournamentsService } from '@services/_tournaments.service';
import {
  ITournamentStateUpdatedPayload,
  ITournamentRoundPayload,
} from '@store/context/lobby_context/ws/_types';
import {
  ITournamentRound,
  TournamentBoardStatus,
  TournamentRoundStatus,
  TournamentStatus,
  TournamentSubscriptions,
} from '@types';

export const createTournamentUpdatesActions = () => {
  /**
   * Обновление стейта турнира
   * @param {ITournamentExpandedData} tournamentState - новый стейт
   */
  const updateTournamentState = (
    { tournamentStore: store }: TMainStore,
    tournamentState: ITournamentStateUpdatedPayload['state']
  ) => {
    const tournamentData = store.get('tournament_data');

    if (!tournamentData) return;

    store.dispatch('tournament_data', {
      ...tournamentData,
      ...tournamentState,
    });
  };

  /**
   * Выполняет запрос на обновление информации об участниках турнира.
   * @param {number | string} tournamentId - id турнира
   */
  const updateTournamentStanding = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string,
    loading?: boolean
  ) => {
    store.dispatch('tournament_update_players_data_request', true);
    store.dispatch('tournament_update_players_data_loading', loading || false);

    try {
      const standingPageLimit = store.get('standing_page_limit');
      const standingPageNumber = store.get('standing_page_number');

      const { data } = await tournamentsService.getTournamentStanding({
        id: tournamentId,
        limit: standingPageLimit,
        offset: (standingPageNumber - 1) * standingPageLimit,
      });

      if (data) {
        store.dispatch('tournament_players_data', data);
      }
    } catch (error) {
      console.log(error);
    }

    store.dispatch('tournament_update_players_data_request', false);
    store.dispatch('tournament_update_players_data_loading', false);
  };

  /**
   * Выполняет запрос на обновление информации об участниках турнира.
   * @param {number | string} tournamentId - id турнира
   * @param {number} limit - количество запрашиваемых данных
   * @param {number} offset - пагинация
   */
  const pushTournamentStanding = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string,
    limit?: number,
    offset?: number
  ) => {
    store.dispatch('tournament_players_data_request', true);

    try {
      const { data } = await tournamentsService.getTournamentStanding({
        id: tournamentId,
        limit,
        offset,
      });

      if (data) {
        const playersData = store.get('tournament_players_data');

        if (playersData === null) {
          store.dispatch('tournament_players_data', data);
        } else {
          const newPlayers = [...playersData.data];
          newPlayers.push(...data.data);

          store.dispatch('tournament_players_data', {
            ...data,
            data: newPlayers,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }

    store.dispatch('tournament_players_data_request', false);
  };

  /**
   * Выполняет запрос на подгрузку информации об участниках турнира
   * при клике на кнопку пагинации
   * @param {number | string} tournamentId - id турнира
   */
  const uploadTournamentStanding = async ({
    tournamentStore: store,
  }: TMainStore) => {
    try {
      const limit = 50;

      const tournamentData = store.get('tournament_data');
      const playersData = store.get('tournament_players_data');

      if (!playersData || !tournamentData) return;

      const isTournamentCompleted =
        tournamentData?.status === TournamentStatus.COMPLETED;

      const newPlayersCount = playersData?.data.length + limit;
      tournamentActions.setLoadedPlayersCount(newPlayersCount);

      if (isTournamentCompleted) {
        tournamentActions.pushTournamentStanding(
          tournamentData.id,
          limit,
          playersData?.data.length
        );
      } else {
        tournamentActions.getTournamentPlayers(
          tournamentData.id,
          newPlayersCount
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Выполняет запрос  на обновление информации о турах текущего турнира
   * @param {number | string} tournamentId - id турнира
   */
  const updateTournamentRounds = async (
    { tournamentStore: store }: TMainStore,
    roundUpdate: ITournamentRoundPayload,
    subscriptions?: TournamentSubscriptions
  ) => {
    try {
      const selectedRound = store.get('selected_round');
      const rounds = store.get('tournament_rounds');
      const newRounds: ITournamentRound[] = rounds.map((round) =>
        round.id === roundUpdate.id ? { ...round, ...roundUpdate } : round
      );
      store.dispatch('tournament_rounds', newRounds);

      if (selectedRound?.id === roundUpdate.id) {
        store.dispatch('selected_round', { ...selectedRound, ...roundUpdate });

        const roundBoards = store.get('round_boards');
        if (
          roundBoards.length &&
          roundUpdate.status === TournamentRoundStatus.GOES
        ) {
          const newBoards = roundBoards.map((board) => ({
            ...board,
            status: TournamentBoardStatus.GOES,
          }));
          store.dispatch('round_boards', newBoards);

          tournamentActions.updateIndividualSubscriptions(subscriptions);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    updateTournamentState,
    updateTournamentStanding,
    pushTournamentStanding,
    uploadTournamentStanding,
    updateTournamentRounds,
  };
};
