/**
 * Объект, повторяющий основные методы Console,
 * но методы не выполняются на проде
 */
export const devConsole = {
  _isProduction: process.env.NODE_ENV === 'production',
  //eslint-disable-next-line
  log: function <T extends any[]>(...values: T) {
    if (this._isProduction) return;

    console.log(...values);
  },
  //eslint-disable-next-line
  warn: function <T extends any[]>(...values: T) {
    if (this._isProduction) return;

    console.warn(...values);
  },

  error: function <T extends string>(message: T) {
    if (this._isProduction) return;

    console.error(message);
  },

  table: function <T extends object>(obj: T) {
    if (this._isProduction) return;

    console.table(obj);
  },
};
