import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

import { figures } from '@constants';

import { IChessgunHistoryItem, chessSide, figureType } from 'chessgun/core';
import { syncedMoment } from './_common';
import { getDurationMsFromStringDate } from './_dates';
import {
  BoardType,
  GameResult,
  IPgnMove,
  ITimeControl,
  INotationRow,
  ILocalization,
} from '@types';

dayjs.extend(duration);
dayjs.extend(utc);

/**
 * возвращает мой цвет фигур в игре
 * @param {boolean} myUid - мой uid
 * @param {boolean} whitePlayerUid - uid белого игрока
 * @returns {chessSide}
 */
export const getMyColor = (
  myUid: string,
  blackPlayerUid?: string
): chessSide => {
  return myUid === blackPlayerUid ? chessSide.BLACK : chessSide.WHITE;
};

/**
 * Проверка, моя ли это доска
 * @param {boolean} myUid - мой uid
 * @param {boolean} whitePlayerUid - uid белого игрока
 * @param {boolean} blackPlayerUid - uid  игрока черными
 * @returns {boolean}
 */
export const isMyBoard = ({
  myUid,
  whitePlayerUid,
  blackPlayerUid,
}: {
  myUid: string | null;
  whitePlayerUid?: string;
  blackPlayerUid?: string;
}): boolean => {
  return myUid === whitePlayerUid || myUid === blackPlayerUid;
};

/**
 * возвращает цвет хода
 * @param {boolean} isWhiteMove - белый ли ход
 * @returns {chessSide}
 */
export const getMoveColor = (isWhiteMove: boolean): chessSide => {
  return isWhiteMove ? chessSide.WHITE : chessSide.BLACK;
};

/**
 * возвращает логический тип: пользователь играет за белых
 * @param {chessSide} myColor - цвет пользователся
 * @returns {boolean}
 */
export const myColorIsWhite = (myColor: chessSide): boolean => {
  return myColor === chessSide.WHITE;
};

/**
 * возвращает цвет оппонента
 * @param {chessSide} myColor - цвет пользователся
 * @returns {chessSide}
 */
export const getOpponentColor = (myColor: chessSide): chessSide => {
  return myColorIsWhite(myColor) ? chessSide.BLACK : chessSide.WHITE;
};

/**
 * возвращает данные игрока в зависимости от цвета и типа
 * @param {string} playerType - тип игрока (я или оппонент)
 * @param {chessSide} myColor - цвет игрока
 * @param {T} whiteData - данные игрока белыми
 * @param {T} blackData - данные игрока черными
 * @returns {T}
 */
export const getPlayerData = <T>({
  playerType,
  myColor,
  whiteData,
  blackData,
}: {
  playerType: 'me' | 'opponent';
  myColor: chessSide;
  whiteData: T;
  blackData: T;
}): T => {
  if (playerType === 'me') {
    return myColorIsWhite(myColor) ? whiteData : blackData;
  }

  return myColorIsWhite(myColor) ? blackData : whiteData;
};

/**
 * переводит миллисекунды в таймер в игре
 * @param {number} ms - миллисекунды
 * @returns {string}
 */
export const msToGameTime = (ms: number): string => {
  const oneHourInMs = 3600000;
  const halfMin = 30000;
  const milliseconds = dayjs.utc(dayjs.duration(ms, 'ms').asMilliseconds());

  // больше чем час - показываем время в формате мин.сек
  if (ms >= oneHourInMs) {
    const sumMinutes = Math.floor(dayjs.duration(ms, 'ms').asMinutes());

    return `${sumMinutes}:${milliseconds.format('ss')}`;
  }

  // c часа до 30 сек показываем время в формате мин.сек
  if (ms > halfMin) {
    return milliseconds.format('m:ss');
  }

  //  с 30 сек показываем время в формате сек.млсек
  const fractionalSecond = milliseconds.format('SSS').slice(0, 1);
  return `${milliseconds.format('s.')}${fractionalSecond}`;
};

/**
 * Возвращает таймер игрока с доски просмотра турнира
 * @param {number | null} msLeft - оставшееся время
 * @param {number} timecontrol - время таймконтроля
 */
export const getBoardTimer = (
  msLeft: number | null,
  timecontrol?: string
): number => {
  if (msLeft !== null) return msLeft;

  if (timecontrol) {
    return dayjs
      .duration(getDurationMsFromStringDate(timecontrol))
      .asMilliseconds();
  }

  return 0;
};

/**
 * переводит миллисекунды в таймер для игры в бродкастах
 * @param {number} ms - миллисекунды
 * @param {string} timeControl - время таймконтроля
 * @returns {string}
 */
export const msToBroadcastTime = (
  ms: number | null,
  timeControl?: string
): string => {
  const msLeft = getBoardTimer(ms, timeControl);

  const duration = timeControl
    ? getDurationMsFromStringDate(timeControl)
    : ms ?? 0;

  const timeControlMs = dayjs.duration(duration).asMilliseconds();
  const oneHourInMs = 3600000;
  const halfMin = 30000;
  const milliseconds = dayjs.utc(dayjs.duration(msLeft, 'ms').asMilliseconds());

  // с 30 сек показываем время в формате сек:мс
  if (msLeft <= halfMin) {
    const timerMs = Math.floor(milliseconds.millisecond() / 100);
    const timerSec = milliseconds.second();
    return `${timerSec}.${timerMs}`;
  }

  // время таймконтроля больше чем час - показываем время в формате ч:мин:сек
  if (timeControlMs >= oneHourInMs) {
    return milliseconds.format('H:mm:ss');
  }

  // время таймконтроля меньше часа - показываем время в формате мин:сек
  return milliseconds.format('mm:ss');
};

/**
 * переводит миллисекунды в таймер
 * @param {number} ms - миллисекунды
 * @returns {string}
 */
export const msToTime = (ms: number, format = 'mm:ss'): string => {
  const milliseconds = dayjs.utc(dayjs.duration(ms, 'ms').asMilliseconds());

  return milliseconds.format(format);
};

/**
 * переводит миллисекунды в таймер
 * @param {number} ms - миллисекунды
 * @returns {string}
 */
export const dateIsAfterNow = (datetime: string | number): boolean => {
  const currentMoment = syncedMoment();
  const startMoment = dayjs(datetime);
  const isAfter = dayjs(startMoment).isAfter(currentMoment);

  return isAfter;
};

/**
 * возвращает кол-во миллисекунд до даты от текущего времени
 * @param {number} ms - миллисекунды
 * @returns {string}
 */
export const getMsFromNow = (datetime: string | number): number => {
  const currentMoment = syncedMoment();
  const startMoment = dayjs(datetime);

  return startMoment.diff(currentMoment);
};

/**
 * переводит миллисекунды в таймер с полным временем формата HH:mm:ss
 * @param {number} ms - миллисекунды
 * @returns {string}
 */
export const msToFullTime = (ms: number): string => {
  const milliseconds = dayjs.utc(dayjs.duration(ms, 'ms').asMilliseconds());

  return milliseconds.format('HH:mm:ss');
};

/**
 * возвращает время окончания хода в IOS (перевод из оставшихся ms в datetime)
 * @param {number} msLeft - миллисекунды
 * @returns {string} - время окончания хода в IOS
 */
export const getMoveEndTime = (msLeft: number): string => {
  return syncedMoment().add(msLeft, 'ms').format('YYYY-MM-DDTHH:mm:ss');
};

/**
 * возвращает текстовый boardType
 * @param {number} type - id boardType
 * @returns {boardType} string
 */
export const getBoardType = (type?: number): string => {
  switch (type) {
    case BoardType.CLASSIC:
      return 'classic';
    case BoardType.RAPID:
      return 'rapid';
    case BoardType.BLITZ:
      return 'blitz';
    case BoardType.ARMAGEDDON:
      return 'armageddon';
    case BoardType.BULLET:
      return 'bullet';
    case BoardType.DAILY:
      return 'daily';
    default:
      return 'classic';
  }
};

/**
 * Вычисление локализованного времени из тайм контрола
 */
export const getLocalizedTime = (
  startTime: number,
  increment: number,
  minutesLocalization: string
) => {
  return `${dayjs.duration(startTime).asMinutes()}${
    dayjs.duration(increment).asMilliseconds()
      ? `+${dayjs.duration(increment).asSeconds()}`
      : ` ${minutesLocalization}`
  }`;
};

/**
 * Вычисление локализованного названия тайм контрола с временем
 */
export const getLocalizedTimeControl = (
  timeControl: ITimeControl,
  boardTypesLocalization: ILocalization,
  minutesLocalization: string
): string => {
  const boardTypeName =
    boardTypesLocalization[getBoardType(timeControl.board_type)];
  const startTime = getDurationMsFromStringDate(timeControl.start_time);
  const increment = getDurationMsFromStringDate(timeControl.increment);

  const timeType = getLocalizedTime(startTime, increment, minutesLocalization);
  return `${boardTypeName} ${timeType}`;
};

/**
 * возвращает числовой результат партии
 * @param {GameResult} result - id result
 * @returns {result} string
 */
export const getGameResult = (result?: GameResult): string => {
  switch (result) {
    case GameResult.WHITE_WIN:
      return '1—0';
    case GameResult.DRAW:
      return '½—½';
    case GameResult.BLACK_WIN:
      return '0—1';
    case GameResult.ABORTED:
    case GameResult.CANCELLED:
      return '0—0';

    default:
      return '';
  }
};

/**
 * возвращает текстовый таймконтрол партии
 * @param {object} timecontrol - таймконтрол партии
 * @returns {result} string
 */
export const getGameTimecontrol = (timecontrol: ITimeControl): string => {
  const startTime = getDurationMsFromStringDate(timecontrol.start_time);
  const increment = getDurationMsFromStringDate(timecontrol.increment);

  return `${dayjs.duration(startTime).asMinutes()}${
    dayjs.duration(increment).asMilliseconds()
      ? `+${dayjs.duration(increment).asSeconds()}`
      : ' min'
  }`;
};

export const getFigureValue = (type: figureType | string): number => {
  if (type === figureType.PAWN) return 1;
  if (type === figureType.KNIGHT || type === figureType.BISHOP) return 3;
  if (type === figureType.ROOK) return 5;
  if (type === figureType.QUEEN) return 9;

  return 0;
};

export const getFiguresAdvantageFromFen = (
  fen: string,
  turn: chessSide
): { white: number; black: number } => {
  const fenFigures =
    turn === chessSide.WHITE ? fen.split(' w ')[0] : fen.split(' b ')[0];

  if (fenFigures.length) {
    const whiteFigureTypes = ['K', 'Q', 'R', 'B', 'N', 'P'];
    const blackFigureTypes = ['k', 'q', 'r', 'b', 'n', 'p'];

    const fenArray = fenFigures.split('');

    const whiteFigures: figureType[] = [];
    const blackFigures: figureType[] = [];

    fenArray.map((item) => {
      if (whiteFigureTypes.includes(item)) {
        whiteFigures.push(item.toLowerCase() as figureType);
      }

      if (blackFigureTypes.includes(item)) {
        blackFigures.push(item as figureType);
      }
    });

    const whiteAdvantage = getFiguresAdvantage(whiteFigures, blackFigures);
    const blackAdvantage = getFiguresAdvantage(blackFigures, whiteFigures);

    return { white: whiteAdvantage, black: blackAdvantage };
  }

  return { white: 0, black: 0 };
};

export const getFiguresAdvantage = (
  playerCapturedFigures: figureType[],
  opponentCapturedFigures: figureType[]
): number => {
  const calcAdvantage = (capturedFigures: figureType[]): number => {
    let advantage = 0;

    capturedFigures.forEach((figure) => {
      advantage += getFigureValue(figure);
    });

    return advantage;
  };

  return (
    calcAdvantage(playerCapturedFigures) -
    calcAdvantage(opponentCapturedFigures)
  );
};

export const getPlayerGameResult = (
  gameResult: GameResult | null,
  playerColor: chessSide
) => {
  switch (gameResult) {
    case GameResult.WHITE_WIN:
      return playerColor === chessSide.WHITE ? '1' : '0';
    case GameResult.BLACK_WIN:
      return playerColor === chessSide.WHITE ? '0' : '1';
    case GameResult.DRAW:
      return '½';
    case GameResult.ABORTED:
      return '0';

    default:
      return '0';
  }
};

/**
 * возвращает текстовую оценку
 * @param {number} evaluation - оценка
 * @returns {result} string
 */
export const getEval = (evaluation: number): string => {
  const absEval = Math.abs(evaluation);
  if (absEval < 10) return evaluation.toFixed(2);

  return Math.abs(evaluation) >= 100
    ? evaluation.toFixed(0)
    : evaluation.toFixed(1);
};

/**
 * возвращает оценку хода в бродкасте, если есть, либо количество ходов до мата
 * @param {number | null} lineEval - оценка
 * @param {number | null} mateIn - количество ходов до мата
 * @returns {result} string
 */
export const getBroadcastLineEval = (
  lineEval: number | null,
  mateIn: number | null
): string => {
  if (mateIn) {
    return `${mateIn}M`;
  } else if (lineEval !== null) {
    return getEval(lineEval / 100);
  } else {
    return '';
  }
};

/**
 * возвращает ходы для нотации
 * @param {(IChessgunHistoryItem | null)[]} gameNotation - ходы из движка
 * @returns {Array} нотация
 */
export const getGameNotation = (
  gameNotation: (IChessgunHistoryItem | null)[]
): Array<INotationRow> => {
  if (!gameNotation.length) return [];

  const moves: Array<INotationRow> = [];

  gameNotation.forEach((item, index) => {
    const pgnMove: IPgnMove = {
      id: item?.fen || `${index}`,
      position: item?.san || '',
      figure: null,
      check: item?.checkmateData.check,
      checkmate: item?.checkmateData.checkmate,
    };

    // TODO: fix
    // если есть piece, то это не рокировка
    if (item && 'piece' in item.moveData) {
      pgnMove.figure = figures[item.moveData.piece];
      pgnMove.figureType = item.moveData.piece;
    }

    if (index === 0 || index % 2 === 0) {
      moves.push({
        [chessSide.WHITE]: pgnMove,
        [chessSide.BLACK]: null,
      });
    } else {
      moves[moves.length - 1][chessSide.BLACK] = pgnMove;
    }
  });

  return moves;
};

/**
 * возвращает ход в формате для отображения в нотации
 * @param {string} position - позиция из движка
 * @returns {string} formattedPosition
 */
export const formatMovePosition = (position: string): string => {
  let formattedPosition = position;

  if (['K', 'Q', 'R', 'N', 'B'].includes(formattedPosition[0])) {
    formattedPosition = formattedPosition.substring(1);
  }

  if (position.includes('+')) {
    formattedPosition = formattedPosition.substring(
      0,
      formattedPosition.length - 1
    );
  }

  return formattedPosition;
};

/**
 * считает миллисекунды до окончания таймера от времени последнего хода
 * @param {string} madeIn - время последнего хода
 * @param {number} msLeft - оставшееся время текущего хода
 * @returns {number}
 */
export const msFromLastMoveToEnd = (madeIn: string, msLeft: number): number => {
  const timerEndTime = dayjs(madeIn).add(msLeft);

  return timerEndTime.diff(syncedMoment());
};
