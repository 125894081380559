import React from 'react';

import { IBaseIcon } from './_types';
import { shapeColors } from '@styles/_variables';

/**
 * Иконка: Круглая FIDE с надписью FIDE
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 */
export const FideRoundedTextIcon: React.FC<IBaseIcon> = ({
  fill = shapeColors['white'],
  width = '100%',
  height = '100%',
  className = '',
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 64 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8009 9.28179C24.7232 8.91282 24.5542 8.32916 24.5026 8.01876C25.317 7.44832 27.8743 6.39295 27.8743 6.39295L27.5417 3.20676L28.8836 2.71582L30.15 5.87389C38.8986 5.11068 39.8084 9.03179 40.3203 10.1817C44.657 9.5266 49.0628 8.11749 52.3851 6.22662C47.641 3.37611 40.3627 0.958984 31.8979 0.958984C24.8382 0.958984 18.2096 2.47703 12.3125 5.70153C15.7671 7.46037 20.4198 8.89659 24.3832 9.74949C24.5377 9.59537 24.6724 9.44812 24.8009 9.28179Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5496 10.6489C17.3829 9.44249 12.6237 7.32974 10.9026 6.53223C8.63738 7.91021 6.06029 10.0047 4.15479 12.4229C6.45302 13.1708 11.0911 14.5306 18.9581 15.7511C19.6264 14.711 22.7859 11.5623 23.5496 10.6489Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.7835 16.6732C50.0078 16.1591 57.5142 14.2319 60.3669 13.2129C58.6344 10.7867 55.8864 8.43117 53.645 7.00684C52.044 7.9029 47.7052 9.97231 40.6416 11.071C41.3171 12.8156 40.7835 16.6732 40.7835 16.6732Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.5435 22.9765C40.6055 22.9727 62.0467 22.9765 63.9297 22.9765C63.8945 20.2642 62.9638 17.2403 61.2726 14.5439C59.5373 15.048 52.405 17.3058 40.5298 17.9691C40.2522 19.6533 39.6668 21.5221 39.5435 22.9765Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3214 18.042C29.3214 18.042 26.7744 17.8867 25.275 17.8141C24.1248 18.7597 21.6916 21.1837 19.1738 17.0089C13.8247 16.3365 7.37539 14.8337 3.34968 13.4971C0.907287 16.9614 0.153994 20.3229 0.065918 22.9758H23.7857C25.4831 19.9702 29.3214 18.042 29.3214 18.042Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.4152 24.2593C39.2286 26.2653 40.126 28.0101 40.6712 28.7427C52.1764 29.8092 59.637 32.2818 61.1227 32.7172C62.3022 30.906 63.7528 27.9567 63.9176 24.2593C63.1777 24.2573 40.2939 24.2573 39.4152 24.2593Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0464 24.2588C23.0464 24.2588 1.08598 24.2418 0.0786133 24.2538C0.234123 27.6043 1.31392 30.2149 2.59154 32.3559C4.33345 31.8096 9.78282 29.7775 22.2112 28.6637C22.1097 27.4895 22.5388 25.2648 23.0464 24.2588Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.9415 32.4353C40.3613 32.4273 22.7827 32.4374 22.7827 32.4374C22.7827 32.4374 22.3974 31.0565 22.3207 29.8579C11.5503 30.6623 4.57028 32.9917 3.31348 33.4765C5.214 36.2676 8.06855 38.7634 11.0592 40.5696C11.7046 40.138 17.1438 36.6546 27.6292 35.7576C26.6863 34.1468 22.7827 34.0985 22.7827 34.0985L22.7786 32.8354L40.9509 32.8395L40.9456 34.0934C40.125 34.2174 36.7615 34.5258 35.8454 35.8483C44.2761 36.5449 50.5918 39.4578 53.0019 40.4062C55.8961 38.6695 58.6204 36.2072 60.3414 33.8496C58.78 33.2859 50.056 30.7237 41.3726 30.0273C41.3726 30.0273 41.8482 31.4173 40.9415 32.4353Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.3168 38.8866C37.1136 39.4701 38.0855 39.6224 39.6895 39.6284C40.4171 39.6316 40.787 39.6043 41.9538 39.5903V44.8658C45.2551 44.1119 48.8052 42.7805 51.5285 41.2612C51.5285 41.2612 44.6324 37.7185 35.4858 36.8647C35.3863 37.3122 35.46 38.2596 36.3168 38.8866Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0951 36.7148C20.5586 37.2006 14.9839 39.3435 12.2368 41.2576C14.7809 42.6899 17.9817 43.967 21.4179 44.7885V39.5481C21.4179 39.5481 25.9679 39.8254 27.2837 38.9544C28.2558 38.3083 28.1781 37.0062 28.0951 36.7148Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6479 46.7229H24.8934V44.453H26.8768V43.4137H24.8934V42.1215H27.0167V41.0752H23.6479V46.7229Z"
      fill={fill}
    />
    <mask
      id="mask0_1285_11752"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="64"
      height="47"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.065918 46.7825H63.9279V0.959473H0.065918V46.7825Z"
        fill={fill}
      />
    </mask>
    <g mask="url(#mask0_1285_11752)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9663 46.7341H29.2117V41.0864H27.9663V46.7341Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9718 45.7845C31.8237 45.7845 31.6445 45.7845 31.5378 45.7593V42.0641C31.6445 42.0389 31.833 42.0136 32.1034 42.0136C33.1853 42.0136 33.8567 42.6346 33.8567 43.8069C33.8567 45.1564 33.1117 45.7935 31.9718 45.7845ZM34.2422 41.6366C33.7168 41.2333 33.0289 41.0327 31.9718 41.0327C31.3491 41.0327 30.7501 41.0831 30.292 41.1587V46.6985C30.5958 46.7398 31.0609 46.7821 31.7096 46.7821C32.7905 46.7821 33.6847 46.5484 34.2661 46.0534C34.7914 45.5919 35.1768 44.8459 35.1768 43.7644C35.1768 42.7675 34.8162 42.0719 34.2422 41.6366Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2495 44.3348H39.2825V43.2957H37.2495V42.1224H39.4046V41.0752H36.0039V46.7239H39.5208V45.6765H37.2495V44.3348Z"
        fill={fill}
      />
    </g>
  </svg>
);
