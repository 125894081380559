import { eAccessLevel, IImageThumbnails, ITimeControl } from './_common.types';

export enum ChallengeStatus {
  CREATED = 1,
  ACCEPT = 2,
  DECLINE = 3,
}

export enum ChallengeLifeCycleType {
  SHORT = 'short',
  INVITE = 'invite',
  LONG = 'long',
}

export interface IChallengePlayer {
  full_name: string | null;
  avatar?: IImageThumbnails | null;
  nationality_id?: number | null;
  country?: number | null;
  nickname?: string;
  fide_id?: number | null;
  rank?: string | null;
  rating: number;
  uid?: string;
  id?: number;
  birth_date?: string;
  player_id?: number;
  arn: string;
  access_level: eAccessLevel;
  otb_title?: string | null;
}

export interface IChallengeItem {
  id: number;
  // owner: IChatPlayer; ???
  owner: IChallengePlayer;
  life_cycle_type: ChallengeLifeCycleType;
  invite_code?: string | null;
  owner_anonymous_uid?: string | null;
  opponent?: IChallengePlayer;
  opponent_anonymous_uid?: string | null;
  rating_type: string;
  rating_from: null;
  rating_to: null;
  time_control: ITimeControl;
  status: ChallengeStatus;
  created_at: string;
  desired_color: string | null;
}

export interface IChallengesList {
  incoming: IChallengeItem[];
  outcoming: IChallengeItem[];
  declined: IChallengeItem[];
}

export interface IChallengesNumber {
  incoming: number;
  outcoming: number;
  declined: number;
}
