export enum eBoardThemes {
  ARMA = 'arma',
  AQUA = 'aqua',
  STANDARD = 'standard',
  WORLDCHESS = 'worldchess',
  // NFT = "nft",
  TEXTBOOK = 'textbook',
  CURRY = 'curry',
  HIPSTER = 'hipster',
  LEMON_TREE = 'lemon-tree',
  MARBLE = 'marble',
  BUBBLEGUM = 'bubblegum',
  LICHESS = 'lichess',
  PHILIPPINES = 'philippines',
}
