import Router from 'next/router';
import {
  getFiguresAdvantageFromFen,
  msFromLastMoveToEnd,
} from '@utils/_helpers/_game';

import { httpStatuses, routesConstants } from '@constants';
import {
  EndReason,
  GameType,
  GameUserType,
  IPulseItem,
  ThunkResult,
  gameActionTypes,
  GameResult,
  GameStatus,
  IGameMove,
  ITourGameData,
  TournamentBoardStatus,
} from '@types';
import { Chessgun, IChessgunAnalysisItemProps } from 'chessgun/core';
import { chessSide } from 'chessgun/core/_constants';

import {
  IBroadcastBoardTimeChanged,
  IBroadcastMoveAnalyzed,
  IBroadcastMoved,
} from '../context/_types';
import {
  BroadcastService,
  broadcastService,
} from '@services/_broadcast.service';
import { viewerActions } from './_viewer.actions';
import { gameActions } from './game';

export const createBroadcastBoardActions = (
  broadcastService: BroadcastService
) => {
  /**
   * Выполняет запрос информации об игре в турнире
   * @param {string} boardId - id доски
   * @param {string} broadcastId - id бродкаста
   * @param {boolean} movesUpdate - обнвить только ходы?
   */
  const getBroadcastBoard = ({
    boardId,
    broadcastId,
    movesUpdate,
  }: {
    boardId: string;
    broadcastId: string;
    movesUpdate?: boolean;
  }): ThunkResult<void> => {
    return async (dispatch) => {
      if (!movesUpdate) {
        dispatch(gameActions.setGameStatus(GameStatus.CREATING));
        dispatch(gameActions.setGameUserType(GameUserType.VIEWER));
        dispatch(gameActions.setBoardId(boardId));
        dispatch(gameActions.setGameType(GameType.BROADCAST));
        dispatch(gameActions.setAnalyzeMode(true));
      }

      try {
        const { status, data } = await broadcastService.getBroadcastBoardData(
          boardId
        );

        if (status === httpStatuses.OK) {
          dispatch(setBroadcastBoardEngine(data));

          dispatch(setBroadcastBoard(data));
        }
      } catch (error) {
        console.log(error);

        Router.push(`${routesConstants.BROADCAST_LOBBY}/${broadcastId}`);
        dispatch(gameActions.setGameStatus(GameStatus.NONE));
      }
    };
  };

  /**
   * Выполняет запрос информации об игре в турнире
   * @param {string} boardId - id доски
   * @param {string} broadcastId - id бродкаста
   * @param {boolean} movesUpdate - обнвить только ходы?
   */
  const broadcastBoardEndedUpdate = ({
    boardId,
    broadcastId,
  }: {
    boardId: string;
    broadcastId?: string;
  }): ThunkResult<void> => {
    return async (dispatch, getState) => {
      try {
        const { status, data } = await broadcastService.getBroadcastBoardData(
          boardId
        );

        if (status === httpStatuses.OK) {
          dispatch(
            gameActions.setPlayersMsLeft({
              whiteMsLeft: data.white_ms_left,
              blackMsLeft: data.black_ms_left,
            })
          );

          if (data.pgn) {
            dispatch(gameActions.setPgnFileName(data.pgn));
          }

          dispatch(gameActions.setGameData(data));

          dispatch(
            gameActions.setPlayers({
              whitePlayer: data.white_player,
              blackPlayer: data.black_player,
            })
          );

          dispatch(viewerActions.setEndedGameData(data));

          const engine = getState().game.engine;
          if (engine) {
            engine.on('currentAnalysisItem', (analysis) => {
              if (!getState().game.is_analyze_mode) return;

              dispatch(gameActions.setSelectedMoveAnalysis(analysis));
            });
          }
        }
      } catch (error) {
        console.log(error);

        if (!broadcastId) return;
        Router.push(`${routesConstants.BROADCAST_LOBBY}/${broadcastId}`);
        dispatch(gameActions.setGameStatus(GameStatus.NONE));
      }
    };
  };

  /**
   * Задает состояние турнирной игры
   * @param {ITourGameData} boardData - данные турнирной игры
   */
  const setBroadcastBoard = (boardData: ITourGameData): ThunkResult<void> => {
    return (dispatch, getState) => {
      dispatch(gameActions.setGameData(boardData));

      dispatch(
        gameActions.setPlayers({
          whitePlayer: boardData.white_player,
          blackPlayer: boardData.black_player,
        })
      );

      let whiteMsLeft = boardData.white_ms_left;
      let blackMsLeft = boardData.black_ms_left;

      const lastMove = boardData.moves[boardData.moves.length - 1];
      if (lastMove && lastMove.made_in && !boardData.result) {
        if (lastMove.is_white_move && boardData.black_ms_left) {
          blackMsLeft = msFromLastMoveToEnd(
            lastMove.made_in,
            boardData.black_ms_left
          );
        }

        if (!lastMove.is_white_move && boardData.white_ms_left) {
          whiteMsLeft = msFromLastMoveToEnd(
            lastMove.made_in,
            boardData.white_ms_left
          );
        }
      }

      if (whiteMsLeft !== null) {
        dispatch(gameActions.setWhiteMsLeft(whiteMsLeft));
      }

      if (blackMsLeft !== null) {
        dispatch(gameActions.setBlackMsLeft(blackMsLeft));
      }

      const gameStatus =
        boardData.status === TournamentBoardStatus.GOES
          ? GameStatus.STARTED
          : GameStatus.CREATED;
      dispatch(gameActions.setGameStatus(gameStatus));

      if (boardData.pgn) {
        dispatch(gameActions.setPgnFileName(boardData.pgn));
      }

      if (boardData.result) {
        dispatch(gameActions.setGameStatus(GameStatus.ENDED));

        dispatch(
          gameActions.setGameEndedData({
            result: boardData.result ? boardData.result : GameResult.ABORTED,
            reason: EndReason.CLASSIC,
            board_id: boardData.board_id,
            black_ms_left: boardData.black_ms_left,
            white_ms_left: boardData.white_ms_left,
          })
        );
      } else {
        const gameEndedData = getState().game.game_ended;
        if (gameEndedData) {
          dispatch(gameActions.setGameEndedData(null));
        }
      }
    };
  };

  /**
   * Задает состояние игрового движка
   */
  const setBroadcastBoardEngine = (data: ITourGameData): ThunkResult<void> => {
    return async (dispatch, getState) => {
      const { board_id: boardId } = getState().game;

      const engineName = `broadcastBoard ${boardId}`;

      const storeEngine = getState().game.engine;
      const engine = storeEngine
        ? storeEngine
        : new Chessgun({
            name: engineName,
            withValidation: false,
          });

      if (!storeEngine) {
        await dispatch({
          type: gameActionTypes.SET_GAME_ENGINE,
          payload: engine,
        });

        window[`chessEngine: ${engineName}`] = engine;
      }

      const liveEngineName = `broadcastBoard ${boardId} live`;
      const storeLiveEngine = getState().game.liveEngine;
      const liveEngine = storeLiveEngine
        ? storeLiveEngine
        : new Chessgun({
            name: liveEngineName,
            withValidation: false,
          });

      if (!storeLiveEngine) {
        await dispatch({
          type: gameActionTypes.SET_LIVE_GAME_ENGINE,
          payload: liveEngine,
        });
      }

      if (engine) {
        engine.on('history', (history) => {
          const turn = engine.get().turn;
          dispatch(gameActions.setGamePlayerTurn(turn));

          dispatch(gameActions.setMovesHistory(history));

          const lastEngineMove = history[history.length - 1];

          dispatch(gameActions.setSelectedMove(lastEngineMove));
        });

        engine.on('lastMove', (lastMove) => {
          const currentFen = lastMove?.fen;
          const movesHistory = engine.get().history;

          if (!currentFen) return;

          const currentMoveIndex = movesHistory.findIndex(
            (move) => move?.fen === currentFen
          );
          const currentMove = movesHistory[currentMoveIndex];
          const lastMoveIndex = movesHistory.length - 1;

          if (currentMove) {
            dispatch(
              gameActions.setCapturedFigures(currentMove.capturedFigures)
            );

            const turn = engine.get().turn;
            const advantage = getFiguresAdvantageFromFen(currentFen, turn);
            dispatch(gameActions.setAdvantage(advantage));
          }

          dispatch(
            gameActions.handleGameReview({
              currentMoveIndex,
              lastMoveIndex,
            })
          );
        });

        engine.on('currentAnalysisItem', (analysis) => {
          if (!getState().game.is_analyze_mode) return;

          dispatch(gameActions.setSelectedMoveAnalysis(analysis));
        });

        const movesAmount = data.moves.length;
        const gameMovesHistory = getState().game.moves_history;

        if (movesAmount > 0) {
          if (engine && gameMovesHistory.length > 0) {
            console.log('restart engine');
            engine.restart();
          }

          dispatch(gameActions.addAllMoves(data.moves));
        }
      }
    };
  };

  /**
   * Добавляет анализ хода в движок
   * @param {IBoardMoveAnalyzed} move - ход
   */
  const addBroadcastBoardMove = (data: IBroadcastMoved): ThunkResult<void> => {
    return (dispatch, getState) => {
      const {
        engine: chessEngine,
        liveEngine: liveChessEngine,
        game_type: gameType,
        white_ms_left: prevWhiteMsLeft,
        black_ms_left: prevBlackMsLeft,
      } = getState().game;
      const { game_data: gameData } = getState().game;

      if (liveChessEngine) {
        liveChessEngine.move(data.move.uci, {
          onError: () => {
            if (gameData && gameType === GameType.BROADCAST) {
              dispatch(
                broadcastBoardActions.getBroadcastBoard({
                  boardId: gameData.board_id,
                  broadcastId: `${gameData.tournament.id}`,
                  movesUpdate: true,
                })
              );
            }
          },
        });
      }

      if (chessEngine) {
        chessEngine.move(data.move.uci, {
          onError: () => {
            if (gameData && gameType === GameType.BROADCAST) {
              dispatch(
                broadcastBoardActions.getBroadcastBoard({
                  boardId: gameData.board_id,
                  broadcastId: `${gameData.tournament.id}`,
                  movesUpdate: true,
                })
              );
            }
          },
        });

        if (Router?.query?.logs === 'on') {
          console.group('TIMER_LOG MOVED');
          console.table({ whiteMsLeft: data.wTime, blackMsLeft: data.bTime });
          console.groupEnd();
        }

        // Если прилетает такое же время, как уже лежит в сторе, то время
        // не обновляется. Добавляем +10 мс для таких ходов, чтоб реакт
        // улавливал изменение и обновлял таймер
        const newWhiteMsLeft =
          data.wTime === prevWhiteMsLeft ? data.wTime + 10 : data.wTime;
        const newBlackMsLeft =
          data.bTime === prevBlackMsLeft ? data.bTime + 10 : data.bTime;

        //  корректируем оставшееся время игроков
        dispatch(
          gameActions.setPlayersMsLeft({
            whiteMsLeft: newWhiteMsLeft,
            blackMsLeft: newBlackMsLeft,
          })
        );
      }
    };
  };

  /**
   * Добавляет анализ хода в движок
   * @param {IBroadcastMoveAnalyzed} moveAnalysis - ход
   */
  const addBroadcastMoveAnalysis = (
    moveAnalysis: IBroadcastMoveAnalyzed
  ): ThunkResult<void> => {
    return (dispatch, getState) => {
      const chessEngine = getState().game.engine;

      if (!chessEngine) return;

      if (moveAnalysis.analysis.multipv) {
        const analysisItem: IChessgunAnalysisItemProps = {
          fen: moveAnalysis.fen,
          multipv: moveAnalysis.analysis.multipv,
          depth: moveAnalysis.analysis.depth,
        };

        const analysis = chessEngine.get('analysis');
        const index = analysis.findIndex(({ fen }) => fen === moveAnalysis.fen);
        chessEngine.addAnalysisItem(
          analysisItem,
          index !== -1 ? index : undefined
        );
      }
    };
  };

  /**
   * Заменяет ходы в игре бродкаста
   * @param {ITourGameData} moves - список новых ходов
   */
  const setBroadcastMovesChanged = (moves: IGameMove[]): ThunkResult<void> => {
    return (dispatch) => {
      let whiteMsLeft = null;
      let blackMsLeft = null;

      const lastMove = moves[moves.length - 1];
      if (lastMove && lastMove.made_in) {
        if (lastMove.is_white_move && lastMove.black_ms_left) {
          blackMsLeft = msFromLastMoveToEnd(
            lastMove.made_in,
            lastMove.black_ms_left
          );
        }

        if (!lastMove.is_white_move && lastMove.white_ms_left) {
          whiteMsLeft = msFromLastMoveToEnd(
            lastMove.made_in,
            lastMove.white_ms_left
          );
        }
      }

      if (whiteMsLeft !== null) {
        dispatch(gameActions.setWhiteMsLeft(whiteMsLeft));
      }

      if (blackMsLeft !== null) {
        dispatch(gameActions.setBlackMsLeft(blackMsLeft));
      }

      dispatch(gameActions.addAllMoves(moves));
    };
  };

  /**
   * Заменяет ходы в игре бродкаста
   * @param {ITourGameData} moves - список новых ходов
   */
  const setBroadcastTimeChanged = (
    timeData: IBroadcastBoardTimeChanged
  ): ThunkResult<void> => {
    return (dispatch, getState) => {
      const chessEngine = getState().game.engine;
      const currTurnIsWhite = chessEngine?.get('turn') === chessSide.WHITE;

      const blackMsLeft =
        !currTurnIsWhite && timeData.lastMoveMadeIn
          ? msFromLastMoveToEnd(timeData.lastMoveMadeIn, timeData.bTime)
          : timeData.bTime;
      const whiteMsLeft =
        currTurnIsWhite && timeData.lastMoveMadeIn
          ? msFromLastMoveToEnd(timeData.lastMoveMadeIn, timeData.wTime)
          : timeData.wTime;

      if (Router?.query?.logs === 'on') {
        console.group('TIMER_LOG TIME_CHANGED');
        console.table({ whiteMsLeft, blackMsLeft });
        console.groupEnd();
      }
      dispatch(gameActions.setPlayersMsLeft({ whiteMsLeft, blackMsLeft }));
    };
  };

  /**
   * Выполняет запрос информации об игре в турнире
   * @param {string} boardId - id доски
   */
  const getBroadcastActiveViewers = (boardId: string): ThunkResult<void> => {
    return async (dispatch) => {
      try {
        const { status, data } =
          await broadcastService.getBroadcastActiveViewers(boardId);

        if (status === httpStatuses.OK) {
          dispatch(
            gameActions.setGameViewersCount(Number(data.active_viewers_amount))
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
  };

  /**
   * Выполняет запрос истории пульса
   * @param {number} playerId - id игрока
   */
  const getPlayerPulseHistory = ({
    playerId,
    playerColor,
    filterFrom,
    filterTo,
  }: {
    playerId: number;
    playerColor: chessSide;
    filterFrom?: string;
    filterTo?: string;
  }): ThunkResult<void> => {
    return async (dispatch, getState) => {
      try {
        const { ok, data } = await broadcastService.getPulseHistory(
          playerId,
          filterFrom,
          filterTo
        );

        const prevHistory =
          playerColor === chessSide.WHITE
            ? getState().game.white_pulse_history
            : getState().game.black_pulse_history;
        const setPulseHistory = (history: IPulseItem[]) =>
          playerColor === chessSide.WHITE
            ? dispatch(gameActions.setWhitePulseHistory(history))
            : dispatch(gameActions.setBlackPulseHistory(history));

        if (ok && data.results.length) {
          if (!prevHistory.length) {
            setPulseHistory(data.results);
          } else {
            const lastItem = prevHistory[prevHistory.length - 1];
            const index = data.results.findIndex(
              (item) => item.measure_datetime === lastItem.measure_datetime
            );
            const newItems = data.results.slice(index + 1);

            if (newItems.length) {
              const newPulseHistory = prevHistory.concat(newItems);
              setPulseHistory(newPulseHistory);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
  };

  return {
    getBroadcastBoard,
    broadcastBoardEndedUpdate,
    setBroadcastBoard,
    setBroadcastBoardEngine,
    addBroadcastBoardMove,
    addBroadcastMoveAnalysis,
    setBroadcastMovesChanged,
    setBroadcastTimeChanged,
    getBroadcastActiveViewers,
    getPlayerPulseHistory,
  };
};

export const broadcastBoardActions =
  createBroadcastBoardActions(broadcastService);
