import { BaseService } from './_base.service';
import {
  createAuthorizationHeaders,
  createAuthorizationOption,
} from '@utils/_requests';
import { combineURLParamsByMap } from '@utils/_url_helpers';
import { httpInstance } from '.';
import {
  ITournamentData,
  IStandingParams,
  ITournamentParams,
  ITournamentPlayersData,
  ITournamentsData,
} from '@types';

const SERVICE_URLS = {
  GET_TOURNAMENTS: '/online/tournaments/short/',
  GET_MY_TOURNAMENTS: '/online/tournaments/my/',
  GET_TOURNAMENT_STANDING: '/online/tournaments/:id/standing/',
};

export type TMyUpcomingTournamentsResponse = {
  count: number;
  results: ITournamentData[];
};

// Copied from shared
export class TournamentsService extends BaseService {
  /**
   * Осуществляет запрос на получение турниров.
   */
  getTournaments(params: ITournamentParams) {
    const {
      minStartTime,
      maxStartTime,
      minFinishTime,
      maxFinishTime,
      limit = 100,
      offset = 0,
      dateSortDirection,
      sortBy,
      community,
      statuses,
      tournamentType,
      locationRegion,
      boardType,
      isPaid,
      featured,
    } = params;

    const ordering = `${sortBy ? sortBy : ''},${
      dateSortDirection && dateSortDirection === 'desc' ? '-' : ''
    }start`;

    const url = combineURLParamsByMap(SERVICE_URLS.GET_TOURNAMENTS, {
      hidden: 'false',
      ordering,
      start_time_after: minStartTime,
      start_time_before: maxStartTime,
      finish_time_after: minFinishTime,
      finish_time_before: maxFinishTime,
      community_that_organized: community,
      status: statuses,
      limit,
      offset,
      kind: tournamentType,
      location_region: locationRegion,
      board_type: boardType,
      is_paid: isPaid,
      featured,
    });

    return this.ajax.get<ITournamentsData>(url);
  }

  /**
   * Осуществляет запрос на получение участников турнира по id.
   * @param {number | string} id - id турнира
   * @param {number} limit - количество запрашиваемых данных
   * @param {number} offset - пагинация
   * @param {string} stage - выбранная стадия
   */
  getTournamentStanding(
    { id, limit = 50, offset = 0, stage }: IStandingParams,
    jwt?: string
  ) {
    const query: { limit: number; offset: number; stage?: string } = {
      limit,
      offset,
    };

    const queryId = typeof id === 'string' ? id : id.toString();

    if (stage) {
      query.stage = stage;
    }

    return this.ajax.get<ITournamentPlayersData>(
      SERVICE_URLS.GET_TOURNAMENT_STANDING.replace(':id', queryId),
      { ...createAuthorizationOption(jwt), query }
    );
  }

  /**
   * Выполняет запрос на получение турниров в которых участвует пользователь
   */
  getMyUpcomingTournaments(params: ITournamentParams, jwt?: string) {
    const {
      minStartTime,
      maxStartTime,
      minFinishTime,
      maxFinishTime,
      statuses,
    } = params;

    const url = combineURLParamsByMap(SERVICE_URLS.GET_MY_TOURNAMENTS, {
      ordering: 'start',
      start_time_after: minStartTime,
      start_time_before: maxStartTime,
      finish_time_after: minFinishTime,
      finish_time_before: maxFinishTime,
      status: statuses,
    });

    return this.ajax.get<TMyUpcomingTournamentsResponse>(
      url,
      createAuthorizationHeaders(jwt)
    );
  }
}

export const tournamentsService = new TournamentsService({
  instance: httpInstance,
});
