import {
  ICertificatesData,
  IPlayerTournaments,
  IProSubscription,
  IProfileData,
  IProfileEvent,
  IRatingsChartsData,
  IRatingsData,
  IStatisticData,
  ITournamentData,
  IUserData,
  TournamentStatus,
  IProfileHydrationData,
  IProfileStats,
  IRequestError,
} from '@types';
import { bindActions } from 'storeshed';
import { mainStore } from '@store/storeshed';
import { findProPlan, syncedMoment } from '@utils/_helpers/_common';
import { httpStatuses } from '@constants';
import { profileInitialState, profileStore } from '../stores/_profile.store';
import { TMainStore } from '../_types';
import { tournamentsService } from '@services/_tournaments.service';
import { playersService } from '@services/_players.service';
import { userService } from '@services/_user.service';
import dayjs from 'dayjs';

export const createProfileActions = () => {
  const setProfileData = ({ profileStore }: TMainStore, data: IProfileData) => {
    profileStore.dispatch('data', data);
  };

  const setProfileDataRequest = (
    { profileStore }: TMainStore,
    inRequest: boolean
  ) => {
    profileStore.dispatch('data_request', inRequest);
  };

  const setProfileEventsHistory = (
    { profileStore }: TMainStore,
    data: IProfileEvent[]
  ) => {
    profileStore.dispatch('events_history', data);
  };

  const setProfileEventsHistoryRequest = (
    { profileStore }: TMainStore,
    inRequest: boolean
  ) => {
    profileStore.dispatch('events_history_request', inRequest);
  };

  const setProfileRatings = (
    { profileStore }: TMainStore,
    data: IRatingsData
  ) => {
    profileStore.dispatch('ratings', data);
  };

  const setProfileRatingsRequest = (
    { profileStore }: TMainStore,
    inRequest: boolean
  ) => {
    profileStore.dispatch('ratings_request', inRequest);
  };

  const setProfileTournaments = (
    { profileStore }: TMainStore,
    data: IPlayerTournaments
  ) => {
    profileStore.dispatch('tournaments', data);
  };

  const setProfileTournamentsRequest = (
    { profileStore }: TMainStore,
    inRequest: boolean
  ) => {
    profileStore.dispatch('tournaments_request', inRequest);
  };

  /**
   * Задает статистику игр WorldChess пользователя (user.wc_stats).
   * @param {IStatisticData} stats - объект статистики игр пользователя.
   */
  const setProfileWCStats = (
    { profileStore }: TMainStore,
    stats: IStatisticData
  ) => {
    profileStore.dispatch('wc_stats', stats);
  };

  /**
   * Задает статистику игр FIDE пользователя (user.fide_stats).
   * @param {IStatisticData} stats - объект статистики игр пользователя.
   */
  const setProfileFideStats = (
    { profileStore }: TMainStore,
    stats: IStatisticData
  ) => {
    profileStore.dispatch('fide_stats', stats);
  };

  /**
   * Задает статистику игр OTB пользователя (user.otb_stats).
   * @param {IStatisticData} stats - объект статистики игр пользователя.
   */
  const setProfileOtbStats = (
    { profileStore }: TMainStore,
    stats: IStatisticData
  ) => {
    profileStore.dispatch('otb_stats', stats);
  };

  /**
   * Задает данные для графиков WorldChess рейтинга (user.wc_charts).
   * @param {IRatingsChartsData} charts - данные графика.
   */
  const setProfileWCCharts = (
    { profileStore }: TMainStore,
    charts: IRatingsChartsData
  ) => {
    profileStore.dispatch('wc_charts', charts);
  };

  /**
   * Задает данные для графиков FIDE рейтинга (user.fide_charts).
   * @param {IRatingsChartsData} charts  - данные графика.
   */
  const setProfileFideCharts = (
    { profileStore }: TMainStore,
    charts: IRatingsChartsData
  ) => {
    profileStore.dispatch('fide_charts', charts);
  };

  /**
   * Задает данные для графиков Otb рейтинга (user.otb_charts).
   * @param {IRatingsChartsData} charts  - данные графика.
   */
  const setProfileOtbCharts = (
    { profileStore }: TMainStore,
    charts: IRatingsChartsData
  ) => {
    profileStore.dispatch('otb_charts', charts);
  };

  /**
   * Задает состояние запроса на статистику игр пользователя.
   * @param {boolean} inRequest - выполняется ли запрос на статистику игр пользователя.
   */
  const setProfileStatsRequest = (
    { profileStore }: TMainStore,
    inRequest: boolean
  ) => {
    profileStore.dispatch('stats_request', inRequest);
  };

  /**
   * Задаёт состояние fide (pro) подписки пользователя.
   */
  const setProSubscription = (
    { profileStore }: TMainStore,
    data: IProSubscription | null
  ) => {
    profileStore.dispatch('pro_subscription', data);
  };

  /**
   * Задаёт будущие турниры пользователя.
   */
  const setProfileUpcomingTournaments = (
    { profileStore }: TMainStore,
    data: ITournamentData[] | null
  ) => {
    const filteredData = data?.filter(({ finish }: ITournamentData) => {
      const endTime = dayjs(finish);
      const msToFinish = endTime.diff(syncedMoment());
      return msToFinish > 0;
    });

    profileStore.dispatch('upcoming_tournaments', filteredData || null);
  };

  /**
   * Задает состояние запроса на будущие турниры пользователя.
   * @param {boolean} inRequest - выполняется ли запрос на будущие турниры пользователя.
   */
  const setProfileUpcomingTournamentsRequest = (
    { profileStore }: TMainStore,
    inRequest: boolean
  ) => {
    profileStore.dispatch('upcoming_tournaments_request', inRequest);
  };

  /**
   * Задает сертификаты профиля
   * @param certificates
   */
  const setCertificates = (
    { profileStore }: TMainStore,
    certificates: ICertificatesData
  ) => {
    profileStore.dispatch('certificates', certificates);
  };

  /**
   * Обнуляет данные профиля
   */
  const resetProfile = ({ profileStore }: TMainStore) => {
    profileStore.dispatch({ ...profileInitialState });
  };

  const setIsOnline = ({ profileStore }: TMainStore, isOnline: boolean) => {
    profileStore.dispatch('is_online', isOnline);
  };

  const setProfile = (_: TMainStore, profile: IUserData) => {
    profileActions.setProfileData(profile);

    const currentFide = findProPlan(profile);
    profileActions.setProSubscription({
      fide_id: profile.fide_id,
      is_active: currentFide ? currentFide.is_active : false,
      verified: profile.fide_verified_status,
      free_requested: false,
      data: currentFide,
    });
  };

  const getProfile = async (
    {}: TMainStore,
    userId: number | string,
    notFoundCallback?: () => void
  ) => {
    profileActions.setProfileDataRequest(true);

    try {
      const { ok, data } = await userService.getProfile(userId);

      if (ok) {
        profileActions.setProfile(data);
      }
    } catch (err) {
      const error = err as IRequestError;
      if (error.status === httpStatuses.NOT_FOUND && notFoundCallback) {
        notFoundCallback();
      }
      console.log(err);
    }

    profileActions.setProfileDataRequest(false);
  };

  const getProfileEventsHistory = async (
    {}: TMainStore,
    userId: number | string
  ) => {
    const eventsHistoryRequest = profileStore.get('events_history_request');
    if (eventsHistoryRequest) return;

    profileActions.setProfileEventsHistoryRequest(true);

    try {
      const { ok, data } = await playersService.getPlayerEventsHistory(userId);

      if (ok) {
        profileActions.setProfileEventsHistory(data.resp);
      }
    } catch (err) {
      console.log(err);
    }

    profileActions.setProfileEventsHistoryRequest(false);
  };

  const getProfileRatings = async ({}: TMainStore, userId: number | string) => {
    profileActions.setProfileRatingsRequest(true);

    try {
      const { ok, data } = await playersService.getPlayerRatings(userId);

      if (ok) {
        profileActions.setProfileRatings(data);
      }
    } catch (err) {
      console.log(err);
    }

    profileActions.setProfileRatingsRequest(false);
  };

  const getProfileTournaments = async (
    {}: TMainStore,
    userId: number | string
  ) => {
    profileActions.setProfileTournamentsRequest(true);

    try {
      const { ok, data } = await playersService.getPlayerTournaments(userId);

      if (ok) {
        profileActions.setProfileTournaments(data);
      }
    } catch (err) {
      console.log(err);
    }

    profileActions.setProfileTournamentsRequest(false);
  };

  const setProfileStats = (_: TMainStore, profileStats: IProfileStats) => {
    profileActions.setProfileWCStats(profileStats.wcGameStats);
    profileActions.setProfileFideStats(profileStats.fideGameStats);
    profileActions.setProfileOtbStats(profileStats.otbGameStats);
    profileActions.setProfileWCCharts(profileStats.wcRatings);
    profileActions.setProfileFideCharts(profileStats.fideRatings);
    profileActions.setProfileOtbCharts(profileStats.otbRatings);
  };

  const getProfileStats = async (
    { profileStore }: TMainStore,
    playerId: number | string
  ) => {
    const statsRequest = profileStore.get('stats_request');

    if (statsRequest) return;

    profileActions.setProfileStatsRequest(true);

    try {
      const { ok: wcOkay, data: wcStats } = await userService.userStats(
        playerId,
        'worldchess'
      );
      const { ok: fideOk, data: fideStats } = await userService.userStats(
        playerId,
        'fide'
      );
      const { ok: otbOk, data: otbStats } = await userService.userStats(
        playerId,
        'otb'
      );

      if (wcOkay && fideOk && otbOk) {
        const profileStats: IProfileStats = {
          wcGameStats: wcStats.game_stats,
          fideGameStats: fideStats.game_stats,
          otbGameStats: otbStats.game_stats,
          wcRatings: wcStats.ratings,
          fideRatings: fideStats.ratings,
          otbRatings: otbStats.ratings,
        };

        profileActions.setProfileStats(profileStats);
      }
    } catch (err) {
      console.log(err);
    }

    profileActions.setProfileStatsRequest(false);
  };

  const getProfileUpcomingTournaments = async ({}: TMainStore) => {
    profileActions.setProfileUpcomingTournamentsRequest(true);

    try {
      const { data } = await tournamentsService.getMyUpcomingTournaments({
        statuses: [TournamentStatus.EXPECTED],
      });

      const sortedData = [...data.results].sort(
        (a, b) => dayjs(a.start).valueOf() - dayjs(b.start).valueOf()
      );

      if (data) {
        profileActions.setProfileUpcomingTournaments(sortedData);
      }
    } catch (err) {
      console.log(err);
    }

    profileActions.setProfileUpcomingTournamentsRequest(false);
  };

  const getIsOnline = async ({}: TMainStore, userId: number | string) => {
    try {
      const { ok, data } = await userService.getIsOnline(userId);

      if (ok) {
        profileActions.setIsOnline(data.is_online);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hydrateProfilePageData = (
    _: TMainStore,
    {
      profile,
      isOnline,
      profileEventsHistory,
      profileTournaments,
      profileRatings,
      profileStats,
      profileUpcomingTournaments,
      certificates,
    }: IProfileHydrationData
  ) => {
    const data = profileStore.get('data');
    if (profile?.player.player_id !== data?.player?.player_id)
      profileActions.resetProfile();

    if (profile) {
      profileActions.setProfile(profile);
    }

    if (isOnline) {
      profileActions.setIsOnline(isOnline);
    }

    if (profileEventsHistory) {
      profileActions.setProfileEventsHistory(profileEventsHistory);
    }

    if (profileTournaments) {
      profileActions.setProfileTournaments(profileTournaments);
    }

    if (profileRatings) {
      profileActions.setProfileRatings(profileRatings);
    }

    if (profileStats) {
      profileActions.setProfileStats(profileStats);
    }

    if (profileUpcomingTournaments) {
      profileActions.setProfileUpcomingTournaments(profileUpcomingTournaments);
    }

    if (certificates) {
      profileActions.setCertificates(certificates);
    }
  };

  return {
    setProfile,
    setProfileStats,

    setProfileData,
    setProfileDataRequest,
    setProfileEventsHistory,
    setProfileEventsHistoryRequest,
    setProfileRatings,
    setProfileRatingsRequest,
    setProfileTournaments,
    setProfileTournamentsRequest,

    setProfileWCStats,
    setProfileFideStats,
    setProfileOtbStats,
    setProfileWCCharts,
    setProfileFideCharts,
    setProfileOtbCharts,
    setProfileStatsRequest,
    setCertificates,

    setProSubscription, // TODO: ???

    setProfileUpcomingTournaments,
    setProfileUpcomingTournamentsRequest,

    resetProfile,
    setIsOnline,

    getProfile,
    getProfileEventsHistory,
    getProfileRatings,
    getProfileTournaments,
    getProfileStats,
    getProfileUpcomingTournaments,
    getIsOnline,

    hydrateProfilePageData,
  };
};

export const profileActions = bindActions(mainStore, createProfileActions());
