import { tournamentService } from '@services/_tournament.service';
import { TMainStore } from '@store/storeshed';
import {
  ISystemPopups,
  ITournamentRound,
  TournamentBoardType,
  TournamentRoundStatus,
  TournamentSubscriptions,
} from '@types';
import { tournamentActions } from './index';
import { addMilisecondsToNow } from '@utils/_helpers/_common';

export const createTournamentRoundsActions = () => {
  const setSelectedRound = (
    { tournamentStore: store }: TMainStore,
    round: ITournamentRound
  ) => {
    store.dispatch('selected_round', round);
  };
  /**
   * Задает значение выбранного для просмотра раунда
   * @param {number | string} tournamentId - id турнира
   * @param {ITournamentRound} round - id турнира
   */
  const updateSelectedRound = (
    { tournamentStore: store }: TMainStore,
    {
      tournamentId,
      round,
      ngRoundCreated,
      popups,
      subscriptions,
      loading,
    }: {
      tournamentId: number | string;
      round: ITournamentRound;
      subscriptions?: TournamentSubscriptions;
      ngRoundCreated?: boolean;
      popups?: ISystemPopups;
      loading?: boolean;
    }
  ) => {
    console.log('updateSelectedRound');
    const selectedRound = store.get('selected_round');

    if (selectedRound && selectedRound.id !== round.id) {
      store.dispatch('round_boards', []);
    }

    store.dispatch('selected_round', {
      ...round,
      tournament_id: round.tournament,
    });

    tournamentActions.getRoundBoards({
      tournamentId,
      roundId: round.id,
      ngRoundCreated,
      popups,
      subscriptions,
      loading,
    });
  };

  const setIsRoundBoardsReady = (
    { tournamentStore: store }: TMainStore,
    isReady: boolean
  ) => {
    store.dispatch('is_round_boards_ready', isReady);
  };

  /**
   * Выполняет запрос туров текущего турнира
   * @param {number | string} tournamentId - id турнира
   */
  const getTournamentRounds = async (
    { tournamentStore: store }: TMainStore,
    {
      tournamentId,
      tournamentType = TournamentBoardType.ONLINE,
      ngRoundCreated,
      popups,
      subscriptions,
    }: {
      tournamentId: number | string;
      tournamentType?: TournamentBoardType;
      ngRoundCreated?: boolean;
      popups?: ISystemPopups;
      subscriptions?: TournamentSubscriptions;
    }
  ) => {
    store.dispatch('tournament_rounds_request', true);

    try {
      const { ok, data } = await tournamentService.getTournamentRounds(
        tournamentId
      );

      if (ok) {
        const rounds: ITournamentRound[] = [];

        data.forEach((round) => {
          rounds.push({
            ...round,
            start:
              round.ms_left_to_start > 0
                ? addMilisecondsToNow(round.ms_left_to_start)
                : round.start,
            finish:
              round.ms_left_to_end > 0
                ? addMilisecondsToNow(round.ms_left_to_end)
                : round.finish,
          });
        });

        store.dispatch('tournament_rounds', rounds);

        if (rounds.length > 0) {
          let lastRound: ITournamentRound;
          const lastAddedRound = (lastRound = rounds[rounds.length - 1]);

          if (tournamentType === TournamentBoardType.BROADCAST) {
            let activeRound: ITournamentRound | null = null;

            rounds.forEach((round) => {
              if (round.status === TournamentRoundStatus.GOES) {
                activeRound = round;
              }

              if (
                round.status === TournamentRoundStatus.EXPECTED &&
                !activeRound
              ) {
                activeRound = round;
              }
            });

            lastRound = activeRound ? activeRound : lastAddedRound;
          } else {
            lastRound = lastAddedRound; // последний раунд в списке
          }

          tournamentActions.updateSelectedRound({
            tournamentId,
            round: lastRound,
            ngRoundCreated,
            popups,
            subscriptions,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }

    store.dispatch('tournament_rounds_request', false);
  };

  return {
    getTournamentRounds,
    updateSelectedRound,
    setSelectedRound,
    setIsRoundBoardsReady,
  };
};
