import { chessSide } from 'chessgun/core';

import { IGameCfg } from '@store/context/_types';
import { TSubscribeOnTournamentGame } from '@store/context/lobby_context/_lobby_context.types';
import { Arbitrator, TournamentParticipantStatus } from './_community.types';
import {
  BoardType,
  eAccessLevel,
  eChessColor,
  GameRatingMode,
  ICountry,
  IImageThumbnails,
  IRegion,
  ITimeControl,
} from './_common.types';
import {
  GameResult,
  IBoardData,
  IGameMoveAnalysis,
  IGamePlayer,
} from './_game.types';
import { IProductSku } from './_payment.types';

export enum BoardAppearance {
  TWO_BOARDS = 'two boards',
  MULTIBOARD = 'multiboard',
}

export enum TournamentBoardType {
  NONE = 'none',
  ONLINE = 'online',
  BROADCAST = 'broadcast',
}

export enum TournamentBoardStatus {
  EXPECTED = 1,
  GOES = 2,
  COMPLETED = 3,
}

export enum TournamentType {
  MATCH = 0,
  PLAYOFF = 1,
  SWISS = 2,
  ARENA = 3,
  ARMAGEDDON = 4,
}

export type TournamentTypeText =
  | 'swiss'
  | 'arena'
  | 'match'
  | 'playoff'
  | 'round-robin'
  | 'knockout';

export enum TournamentStatus {
  // для онлайн турниров
  EXPECTED = 1,
  GOES = 2,
  COMPLETED = 3,

  // для бродкаста
  // EXPECTED_UPLOADED = 4,
  // GOES_UPLOADED = 5,
  // COMPLETED_UPLOADED = 6,
}

export enum ArenaTitleType {
  ACM = 'ACM',
  AFM = 'AFM',
  AIM = 'AIM',
  AGM = 'AGM',
}

export enum TournamentMode {
  OFFLINE = 'offline',
  ONLINE = 'online',
}

export enum TournamentRoundStatus {
  EXPECTED = 1,
  GOES = 2,
  COMPLETED = 3,
}

export enum TournamentJoinLimit {
  NO_USER_DATA = 'no_user_data',
  FIDE_PLAYERS_ONLY = 'fide_players_only',
  SIGNUP_NOT_OPENED_YET = 'signup_not_opened_yet',
  SIGNUP_ALREADY_CLOSED = 'signup_already_closed',
  TOO_MANY_PLAYERS = 'too_many_players',
  SIGNUP_TWICE = 'signup_twice',
  SAME_TIME_TOURNAMENT = 'same_time_tournament',
  SIGNUP_COUNTRY_LIMIT = 'signup_country_limit',
  RATING_TOO_LOW = 'rating_too_low',
  RATING_TOO_HIGH = 'rating_too_high',
  AGE_TOO_HIGH = 'age_too_high',
  TITLE_NOT_ALLOWED = 'title_not_allowed',
  AGE_TOO_LOW = 'age_too_low',
  EVENT_MAX_TOURNAMENT = 'event_max_tournament_participate',
  TOURNAMENT_NOT_PAID = 'tournament_not_paid',
  GENDER_NOT_ALLOWED = 'gender_not_allowed',
}

export interface ITournamentGameTour {
  id: number;
  start: string;
  number: number;
  start_ms_left: number;
}

export interface ITournamentOrganizer {
  icon: string;
  logo: string;
  short_title: string;
  site: string;
  url: string;
}

export interface IStandingParams {
  id: number | string;
  limit?: number;
  offset?: number;
  stage?: string | null;
}

export interface ITournamentGameTourResult {
  rank_old: number;
  rank_new: number;
  rank_delta: number;
  rating: number;
  rating_delta: number;
  points: number;
  points_delta: number;
  is_last_game: boolean;
  white_calories_loss: number;
  black_calories_loss: number;
}

export interface ITournamentGameTournament {
  id: number;
  slug: string;
  title: string;
  subtitle: string;
  tours_count: number;
  finish: string;
  kind: TournamentType;
  status: TournamentStatus;
}

export interface ITournamentFaq {
  text: string;
  title: string;
}

export interface ITournamentParams {
  minStartTime?: string;
  maxStartTime?: string;
  limit?: number;
  offset?: number;
  dateSortDirection?: 'asc' | 'desc' | null;
  sortBy?: string;
  community?: number | string;
  statuses?: TournamentStatus[];
  minFinishTime?: string;
  maxFinishTime?: string;
  history?: boolean;
  tournamentType?: TournamentType;
  locationRegion?: number;
  boardType?: number;
  isPaid?: boolean;
  featured?: boolean;
}

export interface ITournamentGamePlayerStanding {
  uid: string;
  points: number;
  rank: number;
  ready_for_arena: boolean;
}

export interface ITournamentGameStanding {
  white_player: ITournamentGamePlayerStanding;
  black_player: ITournamentGamePlayerStanding;
}

export interface ITournamentInfoData {
  organizer: ITournamentOrganizer | null;
  participation: string;
  system: string;
  region?: string;
  rating?: string;
  foa_rating?: string;
  players?: string;
  duration?: string;
  rounds?: number;
  status: string;
  pgn?: string;
}

export interface ITournamentInfo {
  infoData: ITournamentInfoData;
  about: string;
  images: IImageThumbnails | null;
  pgnName?: string;
  className?: string;
}

export interface IDesktopTournamentInfo extends ITournamentInfo {
  defaultImage: string;
}

export interface IMobileTournamentInfo extends ITournamentInfo {
  defaultImage: string;
}

export interface ITournamentTableInfo {
  infoData: ITournamentInfoData;
  pgnName?: string;
}

export interface ITournamentSponsorsData {
  count: number;
  next: string | null;
  previous: string | null;
  results: ITournamentSponsor[];
}

export interface ITournamentSponsor {
  id: number;
  image: string;
  logo: string;
  link: string;
  link_text: string;
  text: string;
}

export interface ITournamentStream {
  link: string;
  description: string;
  tour_number?: number | null;
}

export interface IRoundBoardsParams {
  tournamentId: number | string;
  roundId: number | string;
  limit?: number;
  offset?: number;
  status?: TournamentBoardStatus;
}

export interface IPulseItem {
  measure_datetime: string;
  rate: number;
}

export interface ITableTournament {
  day: string;
  time: string;
  event: string;
  status: string | JSX.Element;
  prizeFund?: string;
  monthDate: string;
  user_signed: boolean;
  signup_opened: boolean;
  available: boolean;
  id: number | null;
  allowed_regions: {
    id: number;
    name: string;
  }[];
  msLeftToEnd: number;
}

export interface IScheduleColumn {
  title: string;
  minWidth: string;
}

export interface IBroadcastsData {
  count: number;
  boards_amount: number;
  max_participants: number;
  next: string | null;
  previous: string | null;
  results: ITournamentExpandedData[];
}

export interface ITournamentYou {
  you: ITournamentPlayer | null;
}

export interface IRoundMyBoard {
  timer_end_time?: string;
}

export interface IRoundBoardMove {
  fen: string;
  long_san: string;
  san: string;
  is_white_move: boolean;
  made_in?: string | null;
  move_number: number;
  analysis?: IGameMoveAnalysis;
}

export interface IRoundBoardData {
  active_boards_count: number;
  count: number;
  next: string;
  previous: string;
  players_free_win: number[];
  results: IRoundBoard[];
}

export interface IRoundBoard {
  black_player: IGamePlayer;
  board_id: string;
  created: string;
  finished_at: string;
  moves: IRoundBoardMove[];
  result: GameResult | null;
  status: TournamentBoardStatus;
  tour_desk_number: number;
  tour_v2_id: number;
  tournament_v2_id: number;
  white_ms_left: number | null;
  black_ms_left: number | null;
  white_player: IGamePlayer;
  my_board: IRoundMyBoard | null;
  black_uid: string;
  white_uid: string;
}

export interface ISubscribedRoundBoard extends IRoundBoard {
  playerTurn: chessSide;
  white_move_end_time: null | string;
  black_move_end_time: null | string;
  white_pre_move_ms_left: number;
  black_pre_move_ms_left: number;
  best_board?: boolean;
  playingPhCfg: IGameCfg['playingPhCfg'] | null;
  analysis?: IGameMoveAnalysis;
}

export const enum TournamentPhase {
  /** Турнир еще не начался, регистрация еще не открыта */
  UPCOMING_SIGNUP_CLOSED,

  /** Турнир еще не начался, регистрация открыта */
  UPCOMING_SIGNUP_OPENED,

  /** Турнир швейцарка в процессе, мы не участвуем */
  SWISS_LIVE_VIEWER,

  /** Турнир швейцарка в процессе, мы участвуем */
  SWISS_LIVE_PLAYER,

  /** Турнир швейцарка в процессе и одно из условий:
      + Идет пейринг (показываем таймер)
      + Технологическая пауза (показываем таймер)
      + Когда закончили игру в раунде и ждем конца раунда
   */

  /** Закончили игру в раунде, ждем конца раунда */
  SWISS_LIVE_PLAYER_NEXT_ROUND_PENDING,

  /** Техническая пауза / Пейринг */
  SWISS_LIVE_PLAYER_NEXT_ROUND_PAUSE,

  /** Турнир арена в процессе, мы не участвуем */
  ARENA_LIVE_VIEWER,

  /** Турнир арена в процессе, мы участвуем */
  ARENA_LIVE_PLAYER,

  /** Турнир в процессе */
  LIVE,

  /** Турнир закончил, мы не участвуем */
  ENDED_VIEWER,

  /** Турнир закончил, мы участвуем */
  ENDED_PLAYER,

  /** Игроку нельзя участвовать в турнире по какой-то причине */
  NO_PERMISSION,
}

export const enum JoinButtonState {
  /** Обычная кнопка Join */
  COMMON,

  /** Платный Join */
  PAID_TOURNAMENT,

  /** Нужен Про аккаунт */
  NEEDS_PRO_ACCOUNT,

  /** Не подключен FIDE ID */
  FIDE_ID_NOT_CONNECTED,

  /** Аккаунт либо ждет одобрения или отказ в аппруве — показываем отключенный join */
  JOIN_DISABLED_WITH_CAPTION,

  /** Участвует в турнире */
  JOINED,
}

export type TournamentSubscriptions = {
  subscribe: (options: TSubscribeOnTournamentGame) => void;
  unsubscribe: (boardId: string) => void;
};

export enum TournamentPrizeTypes {
  cash = 'cash',
  championship_goods = 'championship_goods',
}

export type TournamentPrizeByRankItem = Readonly<{
  rank: number;
  value?: string;
  name?: string;
}>;

export type TournamentsPrize = Readonly<{
  prize_type: TournamentPrizeTypes;
  fund?: number;
  currency?: string;
  prizes_by_rank?: TournamentPrizeByRankItem[];
}>;

export type TournamentsEntryPrice = {
  id?: string;
  amount?: number;
  currency?: 'eur' | 'usd';
};

export interface ITournamentBoardPoint {
  points: number | null;
  board_uid: string;
  winning_streak?: number;
  tour_number?: number;
  color: eChessColor;
  cumulative_byes?: number;
  cumulative_points?: number;
  group_number?: number | null;
  is_tie_break?: boolean;
  opponent_id?: number;
}

export interface ITournamentPlayer {
  player_id: number;
  player_uid: string;
  full_name: string;
  age: number | null;
  avatar_thumbnails: IImageThumbnails | null;
  buchholz: null | number;
  has_left: boolean;
  ready_for_arena?: boolean | null;
  performance_rating?: number;
  nationality_id: number;
  points: number | null;
  points_per_board: ITournamentBoardPoint[];
  rank: number;
  rating: number;
  foa_title?: string | null;
  fide_title?: string | null;
  fide_id?: number | null;
  uid?: string;
  group_number?: number | null;
  tie_break_points?: ITournamentBoardPoint[] | null;
  disqualified: boolean | null;
  cheated: boolean | null;
  tie_breaks?: {
    bh?: number;
    bhc1?: number;
    win?: number;
  };
  access_level: eAccessLevel;
}

export interface ITournamentPlayersData {
  count: number;
  countries_amount: number;
  data: ITournamentPlayer[];
}

export interface ITournamentData {
  id: number;
  slug?: string;
  title: string | null;
  image: string | null;
  subtitle: string | null;
  time_control: ITimeControl;
  start: string;
  ms_left_to_start: number;
  finish: string;
  ms_left_to_end: number;
  rating_type: GameRatingMode;
  status: TournamentStatus;
  max_participants: number;
  signed_up_amount: number;
  allowed_countries: ICountry[];
  allowed_regions: IRegion[];
  allowed_titles: ArenaTitleType[];
  kind: TournamentType;
  mode?: TournamentMode;
  rating_max: null | number;
  rating_min: null | number;
  registered_players: number;
  user_signed?: boolean;
  main_image: IImageThumbnails | null;
  tours_count?: number;
  age_max?: number | null;
  only_community_members?: boolean;
  faq?: string | null;
  price: string | null;
  with_new_gaming?: boolean;

  prize_fund?: number | null;
  prize_fund_currency?: string | null;
  is_participate?: boolean;
  signup_opened: boolean;
  product_sku?: IProductSku | null;
  video_meeting_link?: string | null;
  featured?: boolean;
}

export interface ITournamentsData {
  count: number;
  boards_amount: number;
  players_amount: number;
  registered_players_total: number;
  next: string | null;
  previous: string | null;
  results: ITournamentData[];
}

export interface ITournamentExpandedData extends ITournamentData {
  about: string; // Html content
  location: null | string;
  event: number | null;
  tours_count: number;
  community_that_organized: ITournamentOrganizer | null;
  max_participants: number;
  sharing_image_medium: { full: string };
  sharing_image_small: { full: string };
  signup_finish: string;
  signup_end_ms_left: number;
  signup_start: string;
  signup_start_ms_left: number;
  // similar_tournaments: []; // какой интерфейс?
  age_max: null | number;
  unsigned_amount: number;
  to_faq: ITournamentFaq[];
  faq: string; // не нужно будет, когда переведем на to_faq
  attendance_info: string | null; // Html content
  schedule_info: string | null; // Html content
  description: string | null; // Html content
  news_tag: string | null;
  city: number | null;
  ready_for_arena: boolean;
  stages?: string[] | null;
  active_stage?: string | null;
  team_play: boolean;
  unavailability_reason?: TournamentJoinLimit | null;
  pause_end_datetime?: string;
  next_tour_start_datetime?: string;
  slug: string;
  player_status: TournamentParticipantStatus;
  arbitrators?: string | Arbitrator[];
  prize?: TournamentsPrize;
  entry_price?: TournamentsEntryPrice | null;
  draft_id: number | null;

  // TODO: удалить на бэке
  // Эти поля не используются
  // sponsor: string; // ??
  // slug: string; // ??
  // sponsor_title: string; // ??
  // prize_fund: number; // ??
  // prize_fund_currency: string; // ??
  // product: null | string; // ??
  // promoted: boolean; // ??
  // press: null | string; // Html content
  // contacts: null; // Html content
  // available: boolean;
  // move_time_limit: number;
  // broadcast_type: TourmanentBroadcastType;
}

export interface ITournamentGameTour {
  id: number;
  start: string;
  number: number;
  start_ms_left: number;
}

export interface ITourGameData extends IBoardData {
  tournament: ITournamentGameTournament;
  // !!! TODO: Временное решение, так как надо дождаться поддержки на бекенде, удалить потом tour
  tour: ITournamentGameTour;
  tour_v2: ITournamentGameTour;
  tour_desk_number: number;
}

export interface ITournamentRound {
  board_type: BoardType;
  boards_count: number;
  start: string;
  ms_left_to_start: number;
  finish: string | null;
  ms_left_to_end: number;
  id: number;
  pgn_file: number | null;
  status: TournamentRoundStatus;
  time_control: ITimeControl;
  number: number;
  tournament_id: number;
  tournament: number; // TODO: переименовать в tournament_id на фронте
  name?: string | null;
  short_name?: string | null;
  tournament_stage?: string | null;
  // is_tie_break: boolean;
}

export interface ITournamentTeamsPlayTeam {
  id: number;
  name: string;
  category: string;
  country: number | null;
  event_name: string;
  points: number;
  tie_break: number;
  in_team: boolean;
}

export interface ITournamentTeamPlayTeamsData {
  count: number;
  data: ITournamentTeamsPlayTeam[];
}

export interface ITournamentPointsPerBoard {
  board_uid: string;
  points: number;
}

export interface ITournamentTeamsPlayPlayer {
  avatar_thumbnails: {
    medium: string;
    small: string;
  };
  player_id: number;
  full_name: string;
  points: number;
  tie_breaks: {
    bh: number;
  };
  team_name: string;
  nationality_id: number | null;
  points_per_board?: ITournamentPointsPerBoard[];
}

export interface ITournamentTeamPlayPlayersData {
  count: number;
  countries_amount: number;
  data: ITournamentTeamsPlayPlayer[];
}
