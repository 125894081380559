import { Chessgun, chessSide } from 'chessgun/core';

import {
  IShortChallengeRequestParams,
  IInviteData,
  ILobbyTagItem,
  IRoundBoardMove,
  LobbyServerTags,
  IRequestError,
  eAccessLevel,
  GameRatingMode,
  IChallengeItem,
  IImageThumbnails,
  ITimeControl,
  ITourGameData,
  SocketStatus,
  ChallengeStatus,
} from '@types';
import { Dispatch } from 'react';

import { IContextActionProps } from '../_common.types';
import { IGameState } from '../_types';
import { useLobbyContextActions } from './_lobby_context.actions';
import { GameWSController, LobbyWSController } from './ws';
import { IGameRequestState } from './ws/_types';

export enum eLobbyContextActionType {
  RESET_GAMES = 'RESET GAMES',
  RESET_GAME = 'RESET GAME',
  SET_WS_STATUS = 'SET WS STATUS',
  UPDATE_GAME = 'UPDATE GAME',
  UPDATE_GAMES = 'UPDATE GAMES',
  UPDATE_GAME_DATA_BY_ID = 'UPDATE GAME DATA BY ID',
  SET_UID = 'SET UID',
  SET_PLAYER_UID = 'SET PLAYER UID',
  SET_GAME_REQUEST_STATE = 'SET GAME REQUEST STATE',
  SET_PINGS_WITHOUT_ANSWER = 'SET PINGS WITHOUT ANSWER',
  ADD_GAME_WS = 'ADD GAME WS',
  WS_LOBBY_SERVER_SUBSCRIBE_TAG = 'WS LOBBY SERVER SUBSCRIBE TAG',
  WS_LOBBY_SERVER_UNSUBSCRIBE_TAG = 'WS LOBBY SERVER UNSUBSCRIBE TAG',
  WS_LOBBY_SERVER_RESET_TAGS = 'WS LOBBY SERVER RESET TAGS',
  SET_ANALYSIS = 'SET ANALYSIS',
  SET_BOARD_DATA_REQUEST = 'SET BOARD DATA REQUEST',
  SET_BOARD_DATA = 'SET BOARD DATA',
  SET_INVITE_DATA = 'SET INVITE DATA',
  SET_INVITE_DATA_REQUEST = 'SET INVITE DATA REQUEST',
  SET_REQUEST_EXPIRED = 'SET REQUEST EXPIRED',
  CANCEL_INVITE_REQUEST = 'CANCEL INVITE REQUEST',
  SET_SELECTED_INVITE = 'SET SELECTED INVITE',
  SET_INVITE_FRIEND_NAME = 'SET INVITE FRIEND NAME',
  SET_SHORT_CHALLENGES = 'SET SHORT CHALLENGES',
  ADD_SHORT_CHALLENGE_DATA = 'ADD SHORT CHALLENGE DATA',
  REMOVE_SHORT_CHALLENGE_DATA = 'REMOVE SHORT CHALLENGE DATA',
  ADD_SHORT_CHALLENGE_DATA_REQUEST = 'ADD SHORT CHALLENGE DATA REQUEST',
  REMOVE_SHORT_CHALLENGE_DATA_REQUEST = 'REMOVE SHORT CHALLENGE DATA REQUEST',
  ADD_CANCEL_SHORT_CHALLENGE_REQUEST = 'ADD CANCEL SHORT CHALLENGE REQUEST',
  REMOVE_CANCEL_SHORT_CHALLENGE_REQUEST = 'REMOVE CANCEL SHORT CHALLENGE REQUEST',
  SET_INVITE_CHALLENGE_DATA = 'SET INVITE CHALLENGE DATA',
  SET_INVITE_CHALLENGE_DATA_REQUEST = 'ADD INVITE CHALLENGE DATA REQUEST',
  SET_CANCEL_INVITE_CHALLENGE_DATA_REQUEST = 'SET CANCEL CHALLENGE INVITE DATA REQUEST',
  SET_CLEAR_SHORT_CHALLENGES_REQUEST = 'SET CLEAR SHORT CHALLENGES REQUEST',

  // new api
  SET_GAMES_IN_QUEUE_DATA = 'SET GAMES IN QUEUE DATA',
  SET_SHORT_CHALLENGES_REQUEST = 'SET SHORT CHALLENGES REQUEST',
}

export interface IContextGameState extends IGameState {
  ws: GameWSController;
  engine: Chessgun;
}

export interface ILobbyContextGame {
  meta: null;
  state?: IGameState;
  chessgun: Chessgun;
  ws: GameWSController;
}

export interface ILobbyContextGameData {
  boardData: ITourGameData | null;
  boardDataRequest: boolean;
  analysis: boolean | null;
}

export type TOwnerResponse = {
  access_level?: eAccessLevel;
  avatar?: IImageThumbnails | null;
  birth_date?: string;
  country?: number | null;
  fide_blitz: number | null;
  fide_bullet: number | null;
  fide_rapid: number | null;
  foa_title_wchess?: string | null;
  otb_title?: string | null;
  full_name?: string;
  rating: number;
  uid: string;
  worldchess_blitz: number;
  worldchess_bullet: number;
  worldchess_rapid: number;
  worldchess_daily: number;
  worldchess_classic: number;
};

export interface IGameInQueueDataResponse {
  id: number;
  created_at: string;
  desired_color: chessSide | null;
  invite_code: string | null;
  opponent: string | null;
  opponent_anonymous_uid: string | null;
  owner: TOwnerResponse;
  owner_anonymous_uid: string | null;
  rating_from: number;
  rating_to: number;
  rating_type: GameRatingMode;
  status: ChallengeStatus;
  time_control: ITimeControl;
}

export interface IGameInQueueData {
  id: number;
  createdAt: string;
  desiredColor: chessSide | null;
  inviteCode: string | null;
  opponent: string | null;
  opponentAnonymousUid: string | null;
  owner: TOwnerResponse | null;
  ownerAnonymousUid: string | null;
  ratingFrom: number;
  ratingTo: number;
  ratingType: GameRatingMode;
  status: ChallengeStatus;
  timeControl: ITimeControl;
}

export interface ILobbyContextState {
  wsStatus: SocketStatus;
  ws: LobbyWSController;
  pingsWithoutAnswer: number;
  uid: string | null;
  playerUid: string | null;
  shortChallenges: IChallengeItem[];
  shortChallengeRequests: IShortChallengeRequestParams[];
  cancelShortChallengeRequests: number[];
  inviteChallenge: IChallengeItem | null;
  inviteChallengeRequest: boolean;
  cancelInviteChallengeRequest: boolean;
  clearShortChallengesRequest: boolean;
  shortChallengesRequest: boolean;

  inviteData: IInviteData | null;
  inviteDataRequest: boolean;
  requestExpired: boolean;
  cancelInviteRequest: boolean;
  selectedInvite: string | null;
  inviteFriendName: string | null;
  game_request: IGameRequestState | null;
  games: { [gameId: string]: ILobbyContextGame };
  gamesData: { [gameId: string]: ILobbyContextGameData };
  count: number;
  subscribedTags: ILobbyTagItem[];
  gamesInQueue: IGameInQueueData[] | null;
}

export interface ILobbyContextActions
  extends ReturnType<typeof useLobbyContextActions> {}

export type ILobbyContextDispatch = Dispatch<ILobbyContextAction>;

export type ILobbyContextActionProps = IContextActionProps<
  ILobbyContextState,
  eLobbyContextActionType
>;

// export type ILobbyContextAction = IContextAction<eLobbyContextActionType>;

interface ILobbyContextActionMessage {
  type: eLobbyContextActionType;
}

interface ISetWsStatus extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_WS_STATUS;
  payload: SocketStatus;
}

export interface IUpdateGame extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.UPDATE_GAME;
  payload: { gId: string; gameState: IGameState };
}

export interface IResetGames extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.RESET_GAMES;
  payload?: undefined;
}

export interface IResetGame extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.RESET_GAME;
  payload: string;
}

interface IUpdateGames extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.UPDATE_GAMES;
  payload: {
    [gameId: string]: ILobbyContextGame;
  };
}

interface IUpdateGameDataById extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.UPDATE_GAME_DATA_BY_ID;
  payload: {
    gameId: string;
    lobbyGame: ILobbyContextGame;
  };
}

interface ISetUid extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_UID;
  payload: string | null;
}

interface ISetPlayerUid extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_PLAYER_UID;
  payload: string | null;
}

interface ISetGameRequestState extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_GAME_REQUEST_STATE;
  payload: IGameRequestState | null;
}

interface ISetPingsWithoutAnswer extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_PINGS_WITHOUT_ANSWER;
  payload: number;
}

interface ISetInviteData extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_INVITE_DATA;
  payload: IInviteData | null;
}

interface ISetInviteDataRequest extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_INVITE_DATA_REQUEST;
  payload: boolean;
}

interface ISetRequestExpired extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_REQUEST_EXPIRED;
  payload: boolean;
}

interface ICancelInviteRequest extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.CANCEL_INVITE_REQUEST;
  payload: boolean;
}

interface ISetSelectedInvite extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_SELECTED_INVITE;
  payload: string | null;
}

interface ISetInviteFriendName extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_INVITE_FRIEND_NAME;
  payload: string | null;
}

interface IAddGameWs extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.ADD_GAME_WS;
  payload: { ws: GameWSController; gameId: string };
}

interface IWsLobbySubscribeTag extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.WS_LOBBY_SERVER_SUBSCRIBE_TAG;
  payload: ILobbyTagItem;
}

interface IWsLobbyUnsubscribeTag extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.WS_LOBBY_SERVER_UNSUBSCRIBE_TAG;
  payload: {
    name: LobbyServerTags;
    id?: string | string[] | number | number[];
  };
}

interface IWsLobbyResetTag extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.WS_LOBBY_SERVER_RESET_TAGS;
  payload?: undefined;
}

interface ISetGamesInQueueData extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_GAMES_IN_QUEUE_DATA;
  payload: IGameInQueueData[];
}

interface ISetAnalysis extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_ANALYSIS;
  payload: { analysis: boolean | null; gId: string };
}

interface ISetBoardDataRequest extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_BOARD_DATA_REQUEST;
  payload: { gId: string; inRequest: boolean };
}

interface ISetBoardData extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_BOARD_DATA;
  payload: { gId: string; data: ITourGameData };
}

interface ISetShortChallenges extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_SHORT_CHALLENGES;
  payload: IChallengeItem[];
}

interface IAddShortChallengeData extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.ADD_SHORT_CHALLENGE_DATA;
  payload: IChallengeItem;
}

interface IRemoveShortChallengeData extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.REMOVE_SHORT_CHALLENGE_DATA;
  payload: number;
}

interface IAddShortChallengeDataRequest extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.ADD_SHORT_CHALLENGE_DATA_REQUEST;
  payload: IShortChallengeRequestParams;
}

interface IRemoveShortChallengeDataRequest extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.REMOVE_SHORT_CHALLENGE_DATA_REQUEST;
  payload: IShortChallengeRequestParams;
}

interface IAddCancelShortChallengeRequest extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.ADD_CANCEL_SHORT_CHALLENGE_REQUEST;
  payload: number;
}

interface IRemoveCancelShortChallengeRequest
  extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.REMOVE_CANCEL_SHORT_CHALLENGE_REQUEST;
  payload: number;
}

interface ISetInviteChallengeData extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_INVITE_CHALLENGE_DATA;
  payload: IChallengeItem | null;
}

interface ISetInviteChallengeDataRequest extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_INVITE_CHALLENGE_DATA_REQUEST;
  payload: boolean;
}

interface ISetCancelInviteChallengeDataRequest
  extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_CANCEL_INVITE_CHALLENGE_DATA_REQUEST;
  payload: boolean;
}

interface ISetClearShortChallengesDataRequest
  extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_CLEAR_SHORT_CHALLENGES_REQUEST;
  payload: boolean;
}

interface ISetShortChallengesRequest extends ILobbyContextActionMessage {
  type: eLobbyContextActionType.SET_SHORT_CHALLENGES_REQUEST;
  payload: boolean;
}

export type ILobbyContextAction =
  | ISetWsStatus
  | IResetGames
  | IResetGame
  | IUpdateGame
  | IUpdateGames
  | IUpdateGameDataById
  | ISetUid
  | ISetPlayerUid
  | ISetGameRequestState
  | ISetPingsWithoutAnswer
  | ISetInviteData
  | ISetInviteDataRequest
  | ISetRequestExpired
  | ICancelInviteRequest
  | ISetSelectedInvite
  | ISetInviteFriendName
  | IAddGameWs
  | IWsLobbySubscribeTag
  | IWsLobbyUnsubscribeTag
  | IWsLobbyResetTag
  | ISetGamesInQueueData
  | ISetAnalysis
  | ISetBoardDataRequest
  | ISetBoardData
  | ISetShortChallenges
  | IAddShortChallengeData
  | IRemoveShortChallengeData
  | IAddShortChallengeDataRequest
  | IRemoveShortChallengeDataRequest
  | IAddCancelShortChallengeRequest
  | IRemoveCancelShortChallengeRequest
  | ISetInviteChallengeData
  | ISetInviteChallengeDataRequest
  | ISetCancelInviteChallengeDataRequest
  | ISetClearShortChallengesDataRequest
  | ISetShortChallengesRequest;

export type ILobbyContextProps = {
  state: ILobbyContextState;
  actions: ILobbyContextActions;
};

export type TCreateGSSocket = {
  settings: {
    boardId: string;
    playerId: string;
    userType: 'player' | 'viewer';
    region: string | null;
  };
};

export type TSubscribeOnTournamentGame = Pick<TCreateGSSocket, 'settings'> & {
  moves?: IRoundBoardMove[];
};

export type TBaseChallengeError = { code: string; detail: string };

export type TChallengeRequestError = IRequestError<TBaseChallengeError>;
