import { ICommunitySocialMediaIcon } from '@types';
import {
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
} from '@components/atoms/icons';

export const communitySocialMediaIcons: ICommunitySocialMediaIcon[] = [
  {
    icon: TwitterIcon,
    iconWidth: 16,
    iconHeight: 15,
    name: 'twitter',
  },
  {
    icon: FacebookIcon,
    iconWidth: 9,
    iconHeight: 15,
    name: 'facebook',
  },
  {
    icon: InstagramIcon,
    iconWidth: 19,
    iconHeight: 16,
    name: 'instagram',
  },
];
