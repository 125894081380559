export const textColors = {
  white: '#ffffff',
  brown: '#b4966e',
  brown_light: '#d6a76a',
  grey_middle: '#adacac',
  grey: '#666666',
  grey_dark: '#757575',
  yellow: '#f2c94c',
  yellow_light: '#f3e3c9',
  green: '#a2c91f',
  coral: '#ff6c58',
  black: '#000000',
  brown_deep: '#3b3124',
  red: '#e30000',
  orange: '#ff6f32',
  gold_light: '#ffbb60',
  blue: '#577cff',
  blue_dark: '#4a25ff',
  light_blue: '#55aae7',
  pink_darker: '#cb307c',
};

export const buttonColors = {
  blue: '#3c40c6',
  blue_light: '#565bf4',
  blue_deep: '#2f329f',
  blue_dark: '#141541',
  green: '#a2c91f',
  green_light: '#b3e311',
  green_deep: '#8bac1a',
  green_dark: '#35420a',
  coral: '#ff6c58',
  coral_light: '#ff4a31',
  coral_deep: '#be3f37',
  coral_dark: '#541f1b',
  brown: '#b4966e',
  brown_light: '#d1af82',
  brown_deep: '#9c825f',
  brown_dark: '#1e1912',
  brown_dark_lighter: '#3a3028',
  yellow: '#ffde32',
  yellow_light: '#ffd705',
  yellow_deep: '#c4aa25',
  yellow_dark: '#544910',
  grey_light: '#dfdede',
  grey: '#adacac',
  grey_middle: '#666666',
  grey_deep: '#303030',
  grey_dark: '#191919',
  white: '#ffffff',
  black: '#000000',
  pink: '#dca4ff',
  light_blue: '#55aae7',
  light_blue_lighter: '#70bff9',
  light_blue_darker: '#4296d1',
  bluish_green: '#00f0ff',
  pink_darker: '#cb307c',
};

export const shapeColors = {
  coral: '#fc594f',
  blue: '#3c40c6',
  bright_blue: '#577cff',
  blue_light: '#565bf4',
  purple: '#8d73ff',
  orange: '#ff9e58',
  yellow: '#ffde32',
  white: '#ffffff',
  grey: '#525252',
  grey_deep_lighter: '#343434',
  grey_deep: '#282828',
  grey_dark: '#191919',
  black: '#000000',
  pink: '#dca4ff',
  brown: '#b4966e',
  brown_light: '#cdab7d',
  green: '#35fc81',
  bluish_green: '#00f0ff',
  bluish_green_dark: '#10888f',
  light_blue: '#55aae7',
  pink_darker: '#cb307c',
};

export const chessboardColors = {
  grey_brown: '#a49a8d',
  grey_brown_deep: '#8b8378',
  brown_deep: '#77513b',
  brown: '#beac96',
  blue: '#9bcfff',
};

/* ============= Adaptive ============= */

//  TODO: refactor неймингов
export const screenSizes = {
  PC: '(max-width: 1460px)',
  TABLET: '(max-width: 1280px)',
  MOBILE: '(max-width: 960px)',
  PHONE: '(max-width: 768px)',
};

export const navbarHeight = '10px';
