import { useHelpersStore } from '@store/storeshed';
import { useState, useEffect, useMemo } from 'react';
import { formatNumber } from '@utils/_helpers/_common';

const DEFAULT_PRICE_FLOAT = 2500; // 25.00 интерпретация
const DEFAULT_ANNUAL_PRICE_FLOAT = 4999; // 49.99 интерпретация
const DEFAULT_MONTHLY_PRICE_FLOAT = 599; // 5.99 интерпретация

/**
 * Получает цену про подписки
 */
export const useProPlanPrice = () => {
  const plans = useHelpersStore('plans');
  const [price, setPrice] = useState(DEFAULT_PRICE_FLOAT);

  useEffect(() => {
    if (plans && plans.pro_plan) {
      setPrice(plans.pro_plan.amount);
    }
  }, [plans]);

  return formatNumber(price / 100, 0);
};

/**
 * Получает цену новых про подписок
 */
export const useNewProPlanPrices = () => {
  const plans = useHelpersStore('plans');

  const [annualPrice, setAnnualPrice] = useState(DEFAULT_ANNUAL_PRICE_FLOAT);
  const [monthlyPrice, setMonthlyPrice] = useState(DEFAULT_MONTHLY_PRICE_FLOAT);

  const promoCodeDiscount = 0;
  const discountedAnnualPrice = useMemo(
    () => annualPrice - promoCodeDiscount,
    [annualPrice, promoCodeDiscount]
  );

  useEffect(() => {
    if (!plans) return;

    if (plans.pro_plan_annual) {
      setAnnualPrice(plans.pro_plan_annual.amount);
    }

    if (plans.pro_plan_monthly) {
      setMonthlyPrice(plans.pro_plan_monthly.amount);
    }
  }, [plans]);

  return {
    annualFull: formatNumber(discountedAnnualPrice / 100, 2),
    annualByMonth: formatNumber(discountedAnnualPrice / 12 / 100, 1),
    monthly: formatNumber(monthlyPrice / 100, 2),
    discount: formatNumber(
      100 - (discountedAnnualPrice / 12 / monthlyPrice) * 100,
      0
    ),
    oldAnnualFull: formatNumber(annualPrice / 100, 2),
    promoCodeDiscount: promoCodeDiscount / 100,
  };
};
