import React from 'react';

import { IBaseIcon } from './_types';
import { shapeColors } from '@styles/_variables';

/**
 * Иконка: Facebook
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - CSS класс иконки
 */
export const FacebookIcon: React.FC<IBaseIcon> = ({
  fill = shapeColors['white'],
  width = '100%',
  height = '100%',
  className = '',
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 8 15">
    <path
      d="M2.37443 15V8.43727H0V5.72267H2.37443V3.65371C2.37443 1.30044 3.76986 0 5.90685 0C6.92968 0 8 0.183419 8 0.183419V2.4945H6.82009C5.65845 2.4945 5.2968 3.219 5.2968 3.96185V5.72267H7.89041L7.4758 8.43727H5.2968V15H2.37443Z"
      fill={fill}
    />
  </svg>
);
