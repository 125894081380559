import { ICouponData, ranksProducts } from '@types';
import { BaseService } from './_base.service';
import { httpInstance } from '.';

export interface IBuyTitleResult {
  amount: number;
  customer: number;
  message: string;
  sku: ranksProducts;
  status: string;
}

export interface IBuyPlanResult {
  code: string;
  invoice_url: string;
}

export interface IPaidAnalysisCheckoutSessionUrlParams {
  fideId: number;
  gameId: string;
  successUrl: string;
  cancelUrl: string;
}

export interface IPaidTournamentCheckoutSessionUrlParams {
  priceId: string | number;
  successUrl: string;
  cancelUrl: string;
  tournamentId: string | number;
}

export interface ISubscriptionCheckoutSessionUrlParams {
  priceId: 'pro-annual' | 'pro-monthly';
  successUrl: string;
  cancelUrl: string;
}

export interface ICustomerPortalUrlParams {
  successUrl: string;
}

export interface ICheckoutSessionUrlResponse {
  checkout_session_url: string;
}

export interface ICustomerPortalUrlResponse {
  customer_portal_url: string;
}

// Copied from shared
export class PaymentService extends BaseService {
  /**
   * Осуществляет запрос на получение stripe-токена для оплаты товара/подписки
   * @param data данные оплаты
   */
  getStripeToken(data: URLSearchParams, stripe_key: string) {
    return this.ajax.post<{ id: string }>('https://api.stripe.com/v1/tokens', {
      body: data.toString(),
      headers: {
        Authorization: `Bearer ${stripe_key}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  /**
   * Осуществляет проверку валидности промокода
   * @param coupon промокод
   */
  checkCoupon(coupon: string) {
    return this.ajax.post<ICouponData>('coupon/', {
      body: { coupon },
    });
  }

  /**
   * Осуществляет покупку продукта (титулы)
   * @param stripeToken токен, полученный от запроса к stripe api
   * @param sku stripe-id товара
   * @param coupon промокод
   */
  buyProduct({
    stripeToken,
    sku,
    coupon,
    fideId,
    gameId,
  }: {
    stripeToken: string;
    sku: string;
    coupon?: string;
    fideId?: number;
    gameId?: string;
  }) {
    const data: {
      stripeToken: string;
      sku: string;
      coupon?: string;
      from_fide_id?: number;
      for_board_uid?: string;
    } = {
      stripeToken,
      sku,
    };

    if (coupon) {
      data['coupon'] = coupon;
    }

    if (fideId) {
      data['from_fide_id'] = fideId;
    }

    if (fideId) {
      data['for_board_uid'] = gameId;
    }

    return this.ajax.post<IBuyTitleResult>('charge/product/', {
      body: data,
    });
  }

  /**
   * Осуществляет покупку подписки (pro)
   * @param stripeToken токен, полученный от запроса к stripe api
   * @param plan stripe-id подписки
   */
  buyPlan(stripeToken: string, plan: string, coupon?: string) {
    const data: { stripeToken: string; plan: string; coupon?: string } = {
      stripeToken,
      plan,
    };

    if (coupon) {
      data['coupon'] = coupon;
    }

    return this.ajax.post<IBuyPlanResult>('charge/plan/', {
      body: data,
      // timeout: 20000,
    });
  }

  /**
   * Осуществляет покупку подписки в подарок (pro)
   * @param stripeToken - токен, полученный от запроса к stripe api
   * @param gift_for - profile_id юзера, которому покупается подписка
   * @param plan - stripe-id подписки
   */
  buyGiftPlan({
    stripeToken,
    plan,
    gift_for,
    coupon,
  }: {
    stripeToken: string;
    plan: string;
    gift_for: number | string;
    coupon?: string;
  }) {
    const data: {
      stripeToken: string;
      plan: string;
      gift_for: number | string;
      coupon?: string;
    } = {
      stripeToken,
      plan,
      gift_for,
    };

    if (coupon) {
      data['coupon'] = coupon;
    }

    return this.ajax.post<IBuyPlanResult>('gift/plan/', {
      body: data,
      // timeout: 20000,
    });
  }

  /**
   * Осуществляет отмену автопродления подписки (pro)
   * @param stripeId stripe-id подписки
   */
  cancelSubscription(stripeId: string) {
    return this.ajax.post(`subscriptions/${stripeId}/cancel/`, {
      body: { notify: true },
    });
  }

  /**
   * Осуществляет возобновление автопродления подписки (pro)
   * @param stripeId stripe-id подписки
   */
  reactivateSubscription(stripeId: string) {
    return this.ajax.post(`subscriptions/${stripeId}/reactivate/`, {
      body: { notify: true },
    });
  }

  /**
   * Осуществляет замену привязанной карты для оплаты
   * @param stripeToken токен, полученный от запроса к stripe api
   */
  changeCard(stripeToken: string) {
    return this.ajax.post(`me/change-card/`, {
      body: { stripeToken },
    });
  }

  /**
   * Запрос для получения ссылки на страницу с оплатой платного анализа
   */
  getPaidAnalysisCheckoutSessionUrl({
    fideId,
    gameId,
    successUrl,
    cancelUrl,
  }: IPaidAnalysisCheckoutSessionUrlParams) {
    return this.ajax.post<ICheckoutSessionUrlResponse>(
      `payments-v2/get-checkout-session-url/paid-game-analysis/`,
      {
        body: {
          from_fide_id: fideId,
          for_board_uid: gameId,
          success_url: successUrl,
          cancel_url: cancelUrl,
        },
      }
    );
  }

  /**
   * Запрос для получения ссылки на страницу с оплатой подписки
   */
  getSubscriptionCheckoutSessionUrl({
    priceId,
    successUrl,
    cancelUrl,
  }: ISubscriptionCheckoutSessionUrlParams) {
    return this.ajax.post<ICheckoutSessionUrlResponse>(
      `payments-v2/get-checkout-session-url/subscription/${priceId}/`,
      {
        body: {
          success_url: successUrl,
          cancel_url: cancelUrl,
        },
      }
    );
  }

  /**
   * Запрос для получения ссылки на страницу портала, где пользователь
   * может управлять своими подписками, платежными методами и др.
   */
  getCustomerPortalUrl({ successUrl }: ICustomerPortalUrlParams) {
    return this.ajax.post<ICustomerPortalUrlResponse>(
      `payments-v2/get-customer-portal-url/`,
      { body: { success_url: successUrl } }
    );
  }

  /**
   * Запрос для получения ссылки на страницу с оплатой платного турнира
   * Может вернуть код невозможности приконнектиться к турниру (unavailability_reason)
   */
  getPaidTournamentCheckoutSessionUrl({
    priceId,
    successUrl,
    cancelUrl,
    tournamentId,
  }: IPaidTournamentCheckoutSessionUrlParams) {
    return this.ajax.post<ICheckoutSessionUrlResponse>(
      `payments-v2/get-checkout-session-url/paid-tournaments/${priceId}/`,
      {
        body: {
          success_url: successUrl,
          cancel_url: cancelUrl,
          tournament_id: tournamentId,
        },
      }
    );
  }
}

export const paymentService = new PaymentService({ instance: httpInstance });
