import React from 'react';

import { IBaseIcon } from './_types';
import { shapeColors } from '@styles/_variables';

/**
 * Иконка: Мультиборд
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - внешний класс
 */
export const TwoBoardsIcon: React.FC<IBaseIcon> = ({
  fill = shapeColors['white'],
  width = '100%',
  height = '100%',
  className = '',
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path stroke={fill} d="M1.2.5h7.1v7.1H1.2z" />
    <path stroke={fill} d="M8.2.5h7.1v7.1H8.2zM15.7 11.5H.7M15.7 14.5H.7" />
  </svg>
);
