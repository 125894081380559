import dayjs, { UnitType } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

export * from './_common';
export * from './_dates';
export * from './_dev_console';
export * from './_equality';
export * from './_game';
export * from './_generate_classNames';
export * from './_get_country_by_id';
export * from './_get_original_ending';
export * from './_get_player_points';
export * from './_time_localization';
export * from './_tournament';

import {
  IBoardEndedData,
  IFilterColumn,
  INextTitleByTypeProgress,
  INextTitleProgress,
  ITitleProgress,
  TFideProp,
  TWCProp,
  AccountVerification,
  GamePlayerType,
  GameRatingMode,
  GameResult,
  IAdvancedTimeControl,
  IProSubscription,
  IRatingsData,
  ISubscriptionData,
  ITimeControl,
  IUserData,
} from '@types';
import { IChessgunHistoryItem, chessSide } from 'chessgun/core';
import { ITabElement } from 'shared/atoms';

import { regexDate, regexEng, regexUrl } from '../_regex';
import { eDoneResult } from '@store/context/_common.types';
import { proPlan, userRatings } from '@constants';
import { syncedDate } from '@utils/_helpers/_common';
import { getEval, myColorIsWhite } from '@utils/_helpers/_game';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// TODO: задать тип regex
const regexExpression = (regex: string | RegExp, regexCondition = 'ig') =>
  new RegExp(regex, regexCondition);

export const baseValidator = (
  value: string,
  errorText = 'Fill in the value'
): string | null => {
  if (!value.length && !value.trim().length) {
    return errorText;
  }
  return null;
};

export const urlValidator = (
  value: string,
  errorText = 'Fill in the value properly'
): string | null => {
  if (value?.length && !value.match(regexExpression(regexUrl))) {
    return errorText;
  }
  return null;
};

export const engValidator = (
  value: string,
  errorText: string
): string | null => {
  const regExp = regexExpression(regexEng);
  if (value?.length && !regExp.test(value)) {
    return errorText;
  }
  return null;
};

export function dateCompareValidator({
  value,
  compareValue,
  message,
  isAfter,
  isBefore,
  isSameOrAfter,
  isSameOrBefore,
  units,
  format,
}: {
  value: string;
  compareValue: string;
  message: string;
  isAfter?: boolean;
  isBefore?: boolean;
  isSameOrAfter?: boolean;
  isSameOrBefore?: boolean;
  units?: UnitType;
  format?: string;
}) {
  const dayjsValue = dayjs(value, format);
  const dayjsCompareValue = dayjs(compareValue, format);

  if (!dayjsValue.isValid() || !dayjsCompareValue.isValid()) return null;

  if (isAfter && !dayjsValue.isAfter(dayjsCompareValue, units || 'minutes')) {
    return message;
  }
  if (isBefore && !dayjsValue.isBefore(dayjsCompareValue, units || 'minutes')) {
    return message;
  }

  if (
    isSameOrAfter &&
    !dayjsValue.isSameOrAfter(dayjsCompareValue, units || 'minutes')
  ) {
    return message;
  }
  if (
    isSameOrBefore &&
    !dayjsValue.isSameOrBefore(dayjsCompareValue, units || 'minutes')
  ) {
    return message;
  }

  return null;
}

export const isProPlan = (
  subscriptions: ISubscriptionData[] | undefined
): boolean => {
  if (subscriptions) {
    for (const property in subscriptions) {
      const {
        is_active,
        plan: { stripe_id },
      } = subscriptions[property];

      if (is_active && proPlan.includes(stripe_id)) {
        return true;
      }
    }
  }

  return false;
};

export const dateValidator = (value: string): string | null => {
  if (!value.length && !value.match(regexExpression(regexDate))) {
    return 'Not valid';
  }

  if (
    syncedDate() < new Date(value) ||
    new Date('1900-01-01') > new Date(value)
  ) {
    return 'Not valid';
  }

  return null;
};

/**
 * Задаёт в css переменные значения vh и vw без учёта скроллбара
 */
export const getVhVwUnits = (): { vh: number; vw: number } | null => {
  const viewportScale = window.visualViewport?.scale;
  const viewportHeight = window.visualViewport?.height;

  if (!viewportScale || !viewportHeight) return null;

  const height = viewportScale * viewportHeight;
  const width = document.body.clientWidth;

  const vh = Math.floor(height * 10) * 0.001;
  const vw = Math.floor(width * 10) * 0.001;

  return { vh, vw };
};

/**
 * Возвращает рейтинг пользователя в соответствии с выбранным таймконтролом и типом рейтинга
 * @param { IRatingsData | null} ratings - рейтинги пользователя
 * @param {ITabElement} currentRate - тип рейтинга
 * @param {IAdvancedTimeControl} timeControl - таймконтрол
 * @returns {number} rating  - рейтинг
 */
export const userGameRating = (
  ratings: IRatingsData | null,
  currentRate: ITabElement,
  timeControl: IAdvancedTimeControl | ITimeControl
): number => {
  if (ratings) {
    const selectedRating = userRatings.find(
      (rating) => rating.board_type === timeControl.board_type
    );

    if (currentRate.id === GameRatingMode.FIDERATED) {
      return selectedRating &&
        ratings[selectedRating.fide_prop as TFideProp] !== 0
        ? ratings[selectedRating.fide_prop as TFideProp]
        : 0;
    }

    if (
      currentRate.id === GameRatingMode.RATED ||
      currentRate.id === GameRatingMode.UNRATED
    ) {
      return selectedRating && ratings[selectedRating.wc_prop as TWCProp] !== 0
        ? ratings[selectedRating.wc_prop as TWCProp]
        : 1200;
    }
  }
  return currentRate.id === GameRatingMode.RATED ||
    currentRate.id === GameRatingMode.UNRATED
    ? 1200
    : 0;
};

/**
 * Возвращает тип игрока
 * @param {boolean} subscription - есть ли про подписка
 * @param {IUserData | null} userData - данные об игре
 * @returns {GamePlayerType} type  - тип игрока
 */
export const getPlayerType = (
  subscription: boolean,
  userData: IUserData | null
): GamePlayerType => {
  if (userData) {
    if (
      subscription &&
      userData.fide_id &&
      userData.fide_verified_status !== AccountVerification.ON_CHECK
    ) {
      return GamePlayerType.FIDE;
    }
    return GamePlayerType.WORLDCHESS;
  }
  return GamePlayerType.ANONYMOUS;
};

/**
 * Возвращает недоступный титул для fide игрока в соответствии с типом таймконтрола
 * @param {ITitleProgress | null} availableTitle - титулы
 * @param {ITimeControl | undefined} timeControl - таймконтроль в игре
 * @returns {INextTitleByTypeProgress | null} timeControlTitle - титул в соответствии с типом таймконтрола или null
 */
export const nextTitleProgressInfo = (
  titleProgress: ITitleProgress | null,
  timeControl: ITimeControl | undefined
): INextTitleByTypeProgress | null => {
  if (titleProgress && titleProgress.next_title_progress && timeControl) {
    const timeControlTitle =
      titleProgress.next_title_progress[
        timeControl.board_type_name as keyof INextTitleProgress
      ];

    return timeControlTitle;
  }

  return null;
};

/**
 * Возвращает выиграл ли пользователь
 * @param {IBoardEndedData} gameEnded - информация об окончании игры
 * @param {chessSide} myColor - цвет фигур пользователя
 * @returns {boolean} win  - выиграл ли пользователь
 */
export const winGame = (
  gameEnded: IBoardEndedData,
  myColor: chessSide
): boolean => {
  if (gameEnded?.result === GameResult.DRAW) return true;

  return myColorIsWhite(myColor)
    ? gameEnded.result === GameResult.WHITE_WIN
    : gameEnded.result === GameResult.BLACK_WIN;
};

/**
 * Возвращает поворот доски для пользователя
 * @param {chessSide} myColor - цвет фигур пользователя
 * @returns {number} angle  - поворот доски
 */
export const myBoardRotation = (myColor: chessSide): number => {
  return myColorIsWhite(myColor) ? 0 : 180;
};

/**
 * Возвращает ссылку на видео с ютуба
 * @param {string} link - линк
 * @returns {string} link
 */
export const getYoutubeVideoLink = (link: string) => {
  const youTubeWatchText = '/watch?v=';

  if (link.includes('/watch?v=')) {
    const res = link.split(youTubeWatchText);

    return `${res[0]}/embed/${res[1]}`;
  }

  return link;
};

/**
 * Проверка соответствует ли ход последнему на доске
 * @param {IChessgunHistoryItem[]} moves - массив ходов доски
 * @param {number} moveNumber - номер хода
 * @param {boolean} isWhiteMove - ход белых?
 * @returns {boolean} isCurrentMove
 */
export const isCurrentMove = (
  moves: (IChessgunHistoryItem | null)[],
  moveNumber: number,
  isWhiteMove: boolean
): boolean => {
  const lastMove = moves[moves.length - 1];

  const boardIsWhiteMove = lastMove && lastMove?.turn === chessSide.WHITE;

  const isCurrentMove =
    Math.round(moves.length / 2) === moveNumber &&
    boardIsWhiteMove === isWhiteMove;

  return isCurrentMove;
};

/**
 * Вычисление суммарного количество ходов доски на момент хода
 * @param {number} moveNumber - номер хода для рассчёта
 * @param {boolean} isWhiteMove - цвет хода для рассчёта
 * @returns {number} payloadMovesLength
 */
export const getMovesLength = (
  moveNumber: number,
  isWhiteMove: boolean
): number => {
  let payloadMovesLength = moveNumber * 2;

  if (isWhiteMove && payloadMovesLength > 0) {
    payloadMovesLength -= 1;
  }

  return payloadMovesLength;
};

/**
 * возвращает лимит рейтинга для турнира
 * @param {number | null} maxRating - максимальный рейтинг
 * @param {number | null} minRating - минимальный рейтинг
 * @returns {rating} string
 */
export const getTournamentRatingLimits = (
  maxRating?: number | null,
  minRating?: number | null
): string | null => {
  if (maxRating && minRating) return `${minRating}-${maxRating}`;

  if (maxRating) return `<${maxRating}`;

  if (minRating) return `>${minRating}`;

  return null;
};

export const getCurrentAdvantage = (
  lineEval: number | null,
  mateIn: number | null
): number => {
  if (mateIn) {
    const blackPlayerMate = mateIn < 0;
    return blackPlayerMate ? -4.7 : 4.7;
  } else if (lineEval !== null) {
    return lineEval / 100;
  } else {
    return 0;
  }
};

export const getCurrentEval = (
  lineEval: number | null,
  mateIn: number | null
): string => {
  if (mateIn) {
    return `${mateIn}M`.substring(1);
  } else if (lineEval !== null) {
    return getEval(lineEval / 100);
  } else {
    return '';
  }
};

export const botExtensionsDetect = (): boolean => {
  const chessvision = document.getElementById('chessvision-wrapper');

  let chessMasterDetected = false;
  const labels = document.getElementsByTagName('label');
  for (let index = 0; index < labels.length; index++) {
    const item = labels[index];
    if (item.innerText.includes('Auto Step')) {
      const chessMaster: HTMLDivElement | null = item.closest('div[id]');
      if (chessMaster && chessMaster.innerText.includes('Chess Master')) {
        chessMasterDetected = true;
      }
    }
  }

  if (chessvision || chessMasterDetected) {
    return true;
  }

  return false;
};

export const calcTournamentPoints = (
  tournamentPoints: number | null | undefined,
  result: GameResult,
  color: 'white' | 'black'
): number | null | undefined => {
  const points = tournamentPoints === null ? 0 : tournamentPoints;

  if (points === undefined) {
    return tournamentPoints;
  }

  if (result === GameResult.DRAW) {
    return points + 0.5;
  }

  if (
    (color === 'white' && result === GameResult.WHITE_WIN) ||
    (color === 'black' && result === GameResult.BLACK_WIN)
  ) {
    return points + 1;
  } else {
    return points;
  }
};

export const getShowConnectionStatus = (
  playerType: 'top' | 'bottom',
  playerDisconnected: boolean,
  isPlayerMove: boolean
) => {
  if (playerType === 'bottom') {
    return !playerDisconnected;
  }

  if (playerType === 'top') {
    return !(playerDisconnected && isPlayerMove);
  }
};
/**
 * Возарщает значение либо null если пустая строка
 * @param {string } value - значение
 * @returns {string | null}
 */
export const getNullIfEmptyString = (value: string | null) => {
  if (typeof value === 'string') {
    return value.length ? value : null;
  }

  return value;
};

/**
 * Форматирование урла
 * @param {string} url - url
 * @returns {string | null}
 */
export const formatUrl = (url: string): string | null => {
  if (url && !url.includes('http')) {
    return 'http://' + url.replace(/www./i, '');
  }

  return getNullIfEmptyString(url.replace(/www./i, ''));
};

/**
 * Получение строки урла для фильтра
 */
export const getFilterURLParams = (
  filterColumnValues?: IFilterColumn['values']
): string => {
  if (filterColumnValues) {
    const checkedFilterValues = Object.entries(filterColumnValues).filter(
      ([_, { checked }]) => checked
    );
    const urlParams = checkedFilterValues.map(([_, { value }]) => value).join();
    return urlParams;
  }

  return '';
};

export const formatNGResult = (gameResult: eDoneResult): GameResult => {
  switch (gameResult) {
    case eDoneResult.BLACK_WIN:
      return GameResult.BLACK_WIN;
    case eDoneResult.WHITE_WIN:
      return GameResult.WHITE_WIN;
    case eDoneResult.DRAW:
      return GameResult.DRAW;
    case eDoneResult.ABORT:
      return GameResult.ABORTED;

    default:
      return GameResult.ABORTED;
  }
};

export const isRatingFrozen = (
  proSubscription: IProSubscription | null,
  fideTitle: string | null | undefined
) => {
  if (!!fideTitle && ['WIM', 'IM', 'WGM', 'GM'].includes(fideTitle)) {
    return false;
  }

  if (!!proSubscription?.data && !proSubscription?.data?.is_active) {
    return true;
  }

  return false;
};
