import { httpStatuses } from '@constants';
import { UseFormState } from '@utils/form/_useForm.hook';
import {
  ICardInputs,
  IFideUsersResponse,
  IFindFidePlayers,
  IProSubscription,
  IRequestError,
  ISystemPopups,
  IUpdateFide,
  IUpdateFideError,
  IUserData,
} from '@types';
import {
  fideSubscriptionActions,
  paymentActions,
  userDataActions,
} from './index';
import { findProPlan } from '@utils/_helpers/_common';
import { TMainStore } from '@store/storeshed';
import { paymentService } from '@services/_payment.service';
import { userService } from '@services/_user.service';
import dayjs from 'dayjs';

export const createFideSubscriptionActions = () => {
  // TODO: Мб место в payment store?
  /**
   * Задает состояние запроса на покупку продукта.
   * @param {boolean} inRequest - выполняется ли запрос на покупку продукта.
   */
  const setProductRequest = (
    { fideSubscriptionStore }: TMainStore,
    inRequest: boolean
  ) => {
    fideSubscriptionStore.dispatch('product_request', inRequest);
  };

  /**
   * Задает состояние запроса на регистрацию FIDE аккаунта.
   * @param {boolean} inRequest - выполняется ли запрос на регистрацию FIDE аккаунта.
   */
  const setFideRequest = (
    { fideSubscriptionStore }: TMainStore,
    inRequest: boolean
  ) => {
    fideSubscriptionStore.dispatch('fide_request', inRequest);
  };

  /**
   * Задает ошибку от запроса на регистрацию FIDE аккаунта.
   * @param {string} error - текст ошибки.
   */
  const setFideError = (
    { fideSubscriptionStore }: TMainStore,
    error: IUpdateFideError | null
  ) => {
    fideSubscriptionStore.dispatch('fide_error', error);
  };

  /**
   * Задает состояние запроса на поиск FIDE аккаунта.
   * @param {boolean} inRequest - выполняется ли запрос на поиск FIDE аккаунта.
   */
  const setFindFideRequest = (
    { fideSubscriptionStore }: TMainStore,
    inRequest: boolean
  ) => {
    fideSubscriptionStore.dispatch('find_fide_request', inRequest);
  };

  /**
   * Задает найденные FIDE аккаунты.
   * @param {boolean} inRequest - выполняется ли запрос на поиск FIDE аккаунта.
   */
  const setFindFideUsers = (
    { fideSubscriptionStore }: TMainStore,
    users: IFideUsersResponse | null
  ) => {
    fideSubscriptionStore.dispatch('find_fide_users', users);
  };

  /**
   * Задаёт состояние fide (pro) подписки пользователя.
   */
  const setProSubscription = (
    { fideSubscriptionStore }: TMainStore,
    data: IProSubscription | null
  ) => {
    fideSubscriptionStore.dispatch('pro_subscription', data);
  };

  // TODO: Payment тоже?
  /**
   * Задает состояние запроса на автопродние подписки.
   * @param {boolean} inRequest - выполняется ли запрос на автопродние подписки.
   */
  const setAutorenewRequest = (
    { fideSubscriptionStore }: TMainStore,
    inRequest: boolean
  ) => {
    fideSubscriptionStore.dispatch('autorenew_request', inRequest);
  };

  /**
   * Задает состояние запроса на изменение статуса free-account-requested
   * @param {boolean} inRequest - выполняется ли запрос на изменение статуса free-account-requested
   */
  const setUpdateFreeAccountRequest = (
    { fideSubscriptionStore }: TMainStore,
    inRequest: boolean
  ) => {
    fideSubscriptionStore.dispatch('free_account_request', inRequest);
  };

  /**
   * Выполняет запрос на регистрацию аккаута FIDE.
   * Перед запросом задает user.fide_request значение true, после - false.
   * Если регистрация прошла успешно, то выпоняет коллбэк.
   * @param {object} data - регистрационные данные для аккаунта FIDE.
   * @param {Function} successCallback - функция коллбэк при успешном выполнении запроса.
   * @param {Function} errorCallback - функция коллбэк при ошибке запроса.
   */
  const registrationFide = async (
    {}: TMainStore,
    data: IUpdateFide,
    successCallback: () => void,
    errorCallback: (e: IUpdateFideError) => void
  ) => {
    fideSubscriptionActions.setFideError(null);
    fideSubscriptionActions.setFideRequest(true);

    try {
      const { ok } = await userService.updateFide(data);

      if (ok) {
        userDataActions.getMe();
        successCallback();
      }
    } catch (err) {
      const error = err as IRequestError<IUpdateFideError>;

      if (error.status === httpStatuses.TOO_LARGE) {
        const imagesError: IUpdateFideError = {
          national_id_selfie: 'error',
          photo: 'error',
        };
        fideSubscriptionActions.setFideError(imagesError);
        errorCallback(imagesError);
      } else {
        const errorData: IUpdateFideError = error.data;
        fideSubscriptionActions.setFideError(errorData);
        errorCallback(errorData);
      }
    }

    fideSubscriptionActions.setFideRequest(false);
  };

  /**
   * Выполняет запрос на поиск аккаута FIDE.
   * Перед запросом задает user.find_fide_request значение true, после - false.
   * Если данные получены, то задает эти данные.
   * @param {object} data - данные поиска аккаунта FIDE.
   */
  const findFidePlayers = async (
    {}: TMainStore,
    data: IFindFidePlayers,
    nextStep?: () => void
  ) => {
    fideSubscriptionActions.setFindFideRequest(true);

    try {
      const { ok, data: result } = await userService.findFidePlayers(data);

      if (ok) {
        fideSubscriptionActions.setFindFideUsers(result);

        if (!result.results.length && nextStep) {
          nextStep();
        }
      }
    } catch (err) {
      console.log(err);
    }

    fideSubscriptionActions.setFindFideRequest(false);
  };

  /**
   * Выполняет запрос на изменение статуса free-account-requested
   * Перед запросом задает user.activation_code_request значение true, после - false.
   * В случае успешного запроса задает регистрационные данные переходит к следующему шагу.
   * @param {boolean} freePro - uid, полученный при проверке кода.
   * @param {Function} nextStep - переход к следующему шагу создания аккаунта.
   */
  const updateFreeAccount = async (
    { userDataStore }: TMainStore,
    freePro: boolean
  ) => {
    fideSubscriptionActions.setUpdateFreeAccountRequest(true);

    try {
      const { ok } = await userService.updateFreeAccount(freePro);

      if (ok) {
        const userData = userDataStore.get('data');
        if (userData) {
          userDataActions.setUserData({
            ...userData,
            free_account_requested: freePro,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }

    fideSubscriptionActions.setUpdateFreeAccountRequest(false);
  };

  /**
   * Выполняет запрос на отмену автопродления подписки.
   * Перед запросом задает user.autorenew_request значение true, после - false.
   * Если отмена прошла успешно, то обновляет данные пользователя.
   * @param {string} stripeId - stripe-id товара.
   */
  const cancelSubscription = async (
    { fideSubscriptionStore }: TMainStore,
    stripeId: string,
    { alert }: Pick<ISystemPopups, 'alert'>
  ) => {
    fideSubscriptionActions.setAutorenewRequest(true);
    try {
      const { ok } = await paymentService.cancelSubscription(stripeId);

      if (ok) {
        userDataActions.getMeData();

        // т.к. запросы на вкл/выкл автообновления подписки асмнхронны,
        // запрашиваем данные юзера с периодичностью 2 сек,
        // пока cancel_at_period_end будет НЕ null
        let intervalIterations = 0;
        const interval = setInterval(async () => {
          intervalIterations += 1;
          await userDataActions.getMeData();

          const proSubscription = fideSubscriptionStore.get('pro_subscription');
          if (
            typeof proSubscription?.data?.cancel_at_period_end === 'boolean' ||
            intervalIterations >= 15
          ) {
            clearInterval(interval);

            if (proSubscription?.data?.cancel_at_period_end === null) {
              alert('Server error. Try again later');
            }
          }
        }, 2000);
      }
    } catch (err) {
      alert("Can't cancel subscription. Please contact support@chessarena.com");
    }
  };

  /**
   * Выполняет запрос на возобновление автопродления подписки.
   * Перед запросом задает user.autorenew_request значение true, после - false.
   * Если отмена прошла успешно, то обновляет данные пользователя.
   * @param {string} stripeId - stripe-id товара.
   */
  const reactivateSubscription = async (
    { fideSubscriptionStore }: TMainStore,
    stripeId: string,
    { alert }: Pick<ISystemPopups, 'alert'>
  ) => {
    fideSubscriptionActions.setAutorenewRequest(true);
    const proSubscription = fideSubscriptionStore.get('pro_subscription');

    try {
      const { ok } = await paymentService.reactivateSubscription(stripeId);

      if (ok) {
        userDataActions.getMeData();

        let intervalIterations = 0;
        const interval = setInterval(async () => {
          intervalIterations += 1;
          await userDataActions.getMeData();

          if (
            typeof proSubscription?.data?.cancel_at_period_end === 'boolean' ||
            intervalIterations >= 15
          ) {
            clearInterval(interval);

            if (proSubscription?.data?.cancel_at_period_end === null) {
              alert('Server error. Try again later');
            }
          }
        }, 2000);
      }
    } catch (err) {
      const error = err as IRequestError<IUpdateFideError>;
      if (error.status === httpStatuses.NOT_ACCEPTABLE) {
        const date = proSubscription?.data?.period.upper;

        alert(
          `Please subscribe manually after ${
            date ? dayjs(date).format('MMM DD, YYYY') : 'subscription ends'
          }`
        );
      } else {
        alert(
          "Can't reactivate subscription. Please contact support@chessarena.com"
        );
      }
    }
  };

  /**
   * Выполняет запрос на замену привязанной карты для оплаты.
   * Перед запросом задает user.autorenew_request значение true, после - false.
   * Если замену прошла успешно, то закрывает попап.
   * @param {string} stripe_key - api ключ stripe.
   * @param {string} card - данные карты.
   * @param {Function} successCallback - callback функция.
   *
   */
  const changeCard = async (
    { paymentStore }: TMainStore,
    {
      stripe_key,
      card,
      successCallback,
    }: {
      stripe_key: string;
      card: UseFormState<ICardInputs>;
      successCallback: () => void;
    }
  ) => {
    fideSubscriptionActions.setAutorenewRequest(true);

    await paymentActions.getStripeToken({ stripe_key, card });

    try {
      const token = paymentStore.get('stripe_token');

      if (token) {
        const { ok } = await paymentService.changeCard(token);

        if (ok) {
          // dispatch(createAuthUserActions(userService).getMeData());
          successCallback();
        }
      }
    } catch (err) {
      console.log(err);
    }

    fideSubscriptionActions.setAutorenewRequest(false);
  };

  const hydrateProSubscription = (
    _: TMainStore,
    userData: IUserData | null
  ) => {
    if (userData) {
      const currentFide = findProPlan(userData);
      fideSubscriptionActions.setProSubscription({
        fide_id: userData.fide_id,
        is_active: currentFide ? currentFide.is_active : false,
        verified: userData.fide_verified_status,
        free_requested: userData.free_account_requested,
        data: currentFide,
      });
    }
  };

  return {
    setProductRequest,
    setProSubscription,
    setFideRequest,
    setFideError,
    setFindFideRequest,
    setFindFideUsers,
    setAutorenewRequest,
    setUpdateFreeAccountRequest,

    registrationFide,
    findFidePlayers,
    updateFreeAccount,
    cancelSubscription,
    reactivateSubscription,
    changeCard,

    hydrateProSubscription,
  };
};
