import Router from 'next/router';

import {
  AccountVerification,
  eAccessLevel,
  GameRatingMode,
  IRequestError,
  ITournamentExpandedData,
  TournamentBoardType,
  TournamentJoinLimit,
} from '@types';
import { initTournamentState } from '../../stores/_tournament.store';
import { tournamentActions } from './index';
import { getAgeNumber } from '@utils/_helpers/_common';
import { delayedUpdate } from '../../_utils';
import { eTeamsWithPlayersGroups } from '@components/molecules/tournament/_teamGroupedResult';
import { TMainStore } from '@store/storeshed';
import { tournamentService } from '@services/_tournament.service';
import { httpStatuses, routesConstants } from '@constants';

export const createTournamentCommonActions = () => {
  const setTournamentData = async (
    { tournamentStore: store }: TMainStore,
    data: ITournamentExpandedData
  ) => {
    await delayedUpdate(() => {
      store.dispatch('boards_type', TournamentBoardType.ONLINE);
    });

    await delayedUpdate(() => {
      store.dispatch('tournament_data', data);
    });

    await delayedUpdate(() => {
      store.dispatch('ready_for_arena', data.ready_for_arena);
    });

    await delayedUpdate(() => {
      tournamentActions.checkTournamentJoinLimit();
    });
  };

  /**
   * Запрашивает информацию о турнире.
   * @param {number | string} tournamentId - id турнира
   */
  const requestTournament = async (
    _: TMainStore,
    { tournamentId }: { tournamentId: number | string }
  ) => {
    try {
      const { ok, data } = await tournamentService.getTournament(tournamentId);

      if (ok) {
        tournamentActions.setTournamentData(data);
      }
    } catch (err) {
      console.log({ err });
      const error = err as IRequestError;
      if (error.status === httpStatuses.NOT_FOUND) {
        Router.replace(`/not_found`);
      }

      console.log(error);
    }
  };

  /**
   * Выполняет запрос на получение информации о турнире.
   * @param {number | string} tournamentId - id турнира
   */
  const getTournament = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    store.dispatch('tournament_data_request', true);

    await tournamentActions.requestTournament({ tournamentId });

    await delayedUpdate(() => {
      store.dispatch('tournament_data_request', false);
    }, 30);
  };

  /**
   * Выполняет запрос на обновление информации о турнире.
   * @param {number | string} tournamentId - id турнира
   */
  const updateTournament = async (
    store: TMainStore,
    tournamentId: number | string
  ) => {
    await tournamentActions.requestTournament({ tournamentId });
  };

  /**
   * Выполняет запрос на присоединение к турниру
   * @param {number | string} tournamentId - id турнира
   */
  const joinTournament = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    try {
      store.dispatch('participation_request', true);

      const { ok, data } = await tournamentService.joinTournament(tournamentId);

      if (ok) {
        store.dispatch('tournament_data', data);
        store.dispatch('ready_for_arena', data.ready_for_arena);
        tournamentActions.getTournamentYou(data.id);
        tournamentActions.updateTournamentStanding(tournamentId);
      }
    } catch (err) {
      const error = err as IRequestError<{
        signup_error_code: TournamentJoinLimit;
      }>;
      console.log(error);
      if (error.data.signup_error_code) {
        store.dispatch('join_limit', error.data.signup_error_code);
      }
    }

    store.dispatch('participation_request', false);
  };

  /**
   * Выполняет запрос на выход из турнира
   * @param {number | string} tournamentId - id турнира
   */
  const signoutTournament = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    try {
      store.dispatch('participation_request', true);

      const { ok, data } = await tournamentService.signoutTournament(
        tournamentId
      );

      if (ok) {
        store.dispatch('tournament_data', data);
        store.dispatch('tournament_you', null);

        tournamentActions.updateTournamentStanding(tournamentId);
      }
    } catch (err) {
      console.log(err);
    }

    store.dispatch('participation_request', false);
    tournamentActions.checkTournamentJoinLimit();
  };

  /**
   * Выполняет запрос на выход из турнира
   * @param {number | string} tournamentId - id турнира
   */
  const leaveTournament = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    try {
      const { ok, data } = await tournamentService.leaveTournament(
        tournamentId
      );

      if (ok) {
        store.dispatch('tournament_data', data);

        tournamentActions.getTournamentYou(tournamentId);
        tournamentActions.updateTournamentStanding(tournamentId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // TODO: обработка рейтинг лимитов (как вычислить рейтинг пользователя именно для турнира?)
  /**
   * Проверка, может ли пользователь участвовать в тунире
   */
  const checkTournamentJoinLimit = async ({
    tournamentStore: store,
    userDataStore,
  }: TMainStore) => {
    const userData = userDataStore.get('data');
    const userDataRequest = userDataStore.get('data_request');

    let reason: TournamentJoinLimit | null = null;

    const tournamentData = store.get('tournament_data');

    // console.log(
    //   'join skipped?: ',
    //   !tournamentData || tournamentData.user_signed || userDataRequest,
    //   {
    //     tournamentData,
    //     'userSigned?': tournamentData?.user_signed,
    //     userDataRequest,
    //   }
    // );
    if (!tournamentData || tournamentData.user_signed || userDataRequest)
      return;

    const unavailabilityReason =
      tournamentData.unavailability_reason !==
      TournamentJoinLimit.TOURNAMENT_NOT_PAID
        ? tournamentData.unavailability_reason
        : null;

    if (unavailabilityReason) {
      setTimeout(() => {
        store.dispatch('join_limit', unavailabilityReason);
      }, 50);

      return;
    }

    // проверка на фиде турнир
    if (
      tournamentData.rating_type === GameRatingMode.FIDERATED &&
      userData &&
      (userData?.access_level !== eAccessLevel.PRO ||
        !userData?.fide_id ||
        userData?.fide_verified_status === AccountVerification.ON_CHECK)
    ) {
      reason = TournamentJoinLimit.FIDE_PLAYERS_ONLY;
    }

    // проверка на кол-во участников
    if (tournamentData.max_participants === tournamentData.signed_up_amount) {
      reason = TournamentJoinLimit.TOO_MANY_PLAYERS;
    }

    // проверка на разрешенные страны
    if (tournamentData.allowed_countries?.length > 0 && userData?.country) {
      const countryIds: number[] = [];
      tournamentData.allowed_countries.forEach((country) =>
        countryIds.push(country.id)
      );

      if (!countryIds.includes(userData.country)) {
        reason = TournamentJoinLimit.SIGNUP_COUNTRY_LIMIT;
      }
    }

    // TODO: проверка на рейтинг (сначала понять, по какому рейтингу вычислять)

    // TODO: проверка на клуб

    // проверка на возраст
    if (
      userData?.birth_date &&
      tournamentData?.age_max &&
      getAgeNumber(userData.birth_date) > tournamentData.age_max
    ) {
      reason = TournamentJoinLimit.AGE_TOO_HIGH;
    }

    setTimeout(() => {
      store.dispatch('join_limit', reason);
    }, 50);
  };

  /**
   * Выполняет запрос на получение сертификата в формате pdf
   * @param {number} tournamentId - id турнира
   * @param {number} playerId - id пользователя
   */
  const getTournamentCertificatePdf = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number,
    playerId: number
  ) => {
    try {
      store.dispatch('tournament_certificate_pdf_request', true);

      const { ok, data } = await tournamentService.getTournamentCertificatePdf(
        tournamentId,
        playerId
      );

      if (ok) {
        store.dispatch('tournament_certificate_pdf', data);
      }
    } catch (err) {
      console.log(err);
    }

    store.dispatch('tournament_certificate_pdf_request', false);
  };

  /**
   * Задает состояние запроса турнирного сертификата в формате html
   * @param {boolean} inRequest - причина
   */
  const setCertificateRawRequest = (
    { tournamentStore: store }: TMainStore,
    inRequest: boolean
  ) => {
    store.dispatch('tournament_certificate_image_request', inRequest);
  };

  /**
   * Выполняет запрос на получение сертификата в формате html
   * @param {number} tournamentId - id турнира
   * @param {number} playerId - id пользователя
   */
  const getTournamentCertificateImage = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number,
    playerId: number
  ) => {
    try {
      store.dispatch('tournament_certificate_image_request', true);

      const { ok, data } =
        await tournamentService.getTournamentCertificateImage(
          tournamentId,
          playerId
        );

      if (ok) {
        store.dispatch('tournament_certificate_image', data.link);
      }
    } catch (err) {
      console.log(err);
    }

    store.dispatch('tournament_certificate_image_request', false);
  };

  /**
   * Запрос на получение pgn файла турнира и обработка состояний создания PGN
   * 202 статус код — PGN в процессе создания, необходимо перезапросить
   * 204 — PGN не будет сформирован
   */
  const requestTournamentPgnResult = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    try {
      const { ok, data, status } =
        await tournamentService.getTournamentPgnResult(tournamentId);

      if (ok && status === 202) {
        const tournamentDataSlug = store.get('tournament_data')?.slug;
        const tournamentPath = `${routesConstants.TOURNAMENTS}/${
          tournamentDataSlug ?? tournamentId
        }`;

        setTimeout(() => {
          if (!Router.asPath.includes(tournamentPath)) return;

          tournamentActions.requestTournamentPgnResult(tournamentId);
        }, 2000);
      }

      if (ok && typeof data === 'object' && data?.link && !data?.error) {
        store.dispatch('tournament_pgn', data.link ? data.link : null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Выполняет запрос на получение pgn файла турнира
   * @param {number} tournamentId - id турнира
   */
  const getTournamentPgnResult = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    store.dispatch('tournament_pgn_request', true);

    await tournamentActions.requestTournamentPgnResult(tournamentId);

    store.dispatch('tournament_pgn_request', false);
  };

  /**
   * Выполняет запрос на остановку участия в арена турнире
   * @param {number} tournamentId - id турнира
   */
  const pauseArenaTournament = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    store.dispatch('ready_for_arena_request', true);

    try {
      const { ok, data } = await tournamentService.pauseTournament(
        tournamentId
      );

      if (ok) {
        store.dispatch('tournament_data', data);
        store.dispatch('ready_for_arena', false);
      }
    } catch (err) {
      console.log(err);
    }

    store.dispatch('ready_for_arena_request', false);
  };

  /**
   * Выполняет запрос на возобновление участия в арена турнире
   * @param {number} tournamentId - id турнира
   */
  const resumeArenaTournament = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    store.dispatch('ready_for_arena_request', true);

    try {
      const { ok, data } = await tournamentService.resumeTournament(
        tournamentId
      );

      if (ok) {
        store.dispatch('tournament_data', data);
        store.dispatch('ready_for_arena', true);
      }
    } catch (err) {
      const error = err as IRequestError<{ code: TournamentJoinLimit }>;
      console.log(error);
      if (error.data.code) {
        console.log('resumeArenaTournament');
        store.dispatch('join_limit', error.data.code);
      }
    }

    store.dispatch('ready_for_arena_request', false);
  };

  /**
   * Задает участие в арена турнире
   * @param {boolean} readyForArena - участие
   */
  const setReadyForArena = (
    { tournamentStore: store }: TMainStore,
    readyForArena: boolean
  ) => {
    store.dispatch('ready_for_arena', readyForArena);
  };

  /**
   * Обнуляет турнирный стейт
   */
  const resetTournament = ({ tournamentStore: store }: TMainStore) => {
    console.log(
      '%c~RESET TOURNAMENT DATA~',
      'background-color: black; color: lightgreen;'
    );
    store.dispatch({ ...initTournamentState });
  };

  /**
   * Устанавливает текущую вкладку таблицы командного турнира
   * @param {eTeamsWithPlayersGroups} group - текущая вкладка группы
   */
  const setTeamPlayCurrentGroup = (
    { tournamentStore: store }: TMainStore,
    group: eTeamsWithPlayersGroups
  ) => {
    store.dispatch('team_play_current_group', group);
  };

  const setTournamentPause = (
    { tournamentStore: store }: TMainStore,
    endTime: string
  ) => {
    const tournamentData = store.get('tournament_data');

    if (!tournamentData) return;

    const nextTourstartDatetime = new Date(endTime);
    nextTourstartDatetime.setMinutes(nextTourstartDatetime.getMinutes() + 1);

    store.dispatch('tournament_data', {
      ...tournamentData,
      pause_end_datetime: endTime,
      next_tour_start_datetime: nextTourstartDatetime.toISOString(),
    });
  };

  return {
    setTournamentData,
    requestTournament,
    getTournament,
    updateTournament,

    checkTournamentJoinLimit,
    joinTournament,
    signoutTournament,
    leaveTournament,
    getTournamentCertificatePdf,
    setCertificateRawRequest,
    getTournamentCertificateImage,
    requestTournamentPgnResult,
    getTournamentPgnResult,
    resetTournament,
    pauseArenaTournament,
    resumeArenaTournament,
    setReadyForArena,

    setTeamPlayCurrentGroup,
    setTournamentPause,
  };
};
