import { FormEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import { IInputProps, IInputState } from './_inputTypes';
import { onValidate } from '@utils/form/_utils';

export type UseFormProps<T extends string> = Record<T, IInputProps<T>>;

// TODO: рефактор
export type UseFormState<T extends string> = Record<T, IInputState<T>>;

const defaultInputState: IInputState<string> = {
  value: '',
  defaultValue: '',
  required: false,
  touched: false,
  isChanged: false,
  errorMessage: null,
  validators: null,
  validator: () => null,
  formatter: (val) => val,
};

/**
 * Взято из @shared, теперь это актуальная версия
 * Хук для валидации формы. Принимает на вход следующие параметры:
 * @param {object} inputs  - содержит информацию по каждому инпуту.
 * @param {object} inputs[inputName] - содержит информацию по конкретному инпуту.
 * @param {string} inputs[inputName].value - значение поля.
 * @param {string} inputs[inputName].defaultValue - значение по умолчанию.
 * @param {boolean} inputs[inputName].required - должен ли инпут быть заполнен.
 * @param {boolean} inputs[inputName].isChanged - изменилось ли значение поля.
 * @param {Function} inputs[inputName].validator - валидирует значение инпута при вводе. Должен возвращать
 * @param inputs[inputName].errorMessage - сообщение об ошибке, полученое из валидатора.
 * либо null, либо ошибку string.
 * @param {Function} inputs[inputName].formatter - форматирует значение при вводе.
 * @param {Function} submitHandler - функция подтверждения формы. Принимает на вход параметр state {UseFormState<Inputs>}.
 *
 * @returns {object} result - объект с состоянием и функциями.
 * @returns {object} result.inputState - состояние всех инпутов.
 * @returns {Function} result.onChange - функция для инпута.
 * @returns {Function} result.updateValue - функция для обновления значения поля (в т.ч. дефолтного).
 * @returns {boolean} result.isValid - все ли поля валидны.
 * @returns {Function} result.onSubmit - функция для подтверждения формы.
 *
 *
 */
export function useForm<T extends string>(
  inputs: UseFormProps<T>,
  submitHandler?: (
    state: UseFormState<T>,
    setState?: React.Dispatch<React.SetStateAction<UseFormState<T>>>
  ) => void
) {
  const [state, setState] = useState<UseFormState<T>>(initState(inputs));

  function initState($inputs: UseFormProps<T>): UseFormState<T> {
    let initValue: Partial<UseFormState<T>> = {};

    Object.entries($inputs).forEach(([name, inputData]) => {
      let inputState: IInputState<string> = { ...defaultInputState };

      if (typeof inputData === 'string') {
        inputState = {
          ...inputState,
          value: inputData,
        };
      }

      if (typeof inputData === 'object') {
        inputState = {
          ...inputState,
          ...inputData,
        };
      }

      initValue = {
        ...initValue,
        [name]: {
          ...inputState,
          defaultValue: inputState.value,
        },
      };
    });

    return initValue as UseFormState<T>;
  }

  const inputState = useMemo(() => {
    return Object.keys(state).reduce((acc, key) => {
      const item = state[key as T];
      acc[key as T] = {
        ...item,
        errorMessage: item.touched
          ? onValidate<T>({
              value: item.value,
              state,
              validators: item.validators,
              validator: item.validator,
              name: key as T,
            })
          : item.errorMessage,
      };
      return acc;
    }, {} as UseFormState<T>);
  }, [state]);

  const isValid = useMemo(() => {
    const haveErrors = (Object.keys(state) as T[]).find((key) => {
      const errorMessage = onValidate<T>({
        value: state[key].value,
        state: state,
        validators: state[key].validators,
        validator: state[key].validator,
        name: key as T,
      });
      if (errorMessage) console.log('form haveError', { [key]: errorMessage });
      return (
        !!errorMessage || (state[key].required && state[key].value.length === 0)
      );
    });
    return !haveErrors?.length;
  }, [state]);

  const hasErrors = useMemo(() => {
    return !!(Object.keys(inputState) as T[]).find((key) => {
      return inputState[key].errorMessage !== null;
    });
  }, [inputState]);

  const isChanged = useMemo(() => {
    const fieldsChanged = (Object.keys(state) as T[]).find((key) => {
      return state[key].value !== state[key].defaultValue;
    });

    return !!fieldsChanged?.length;
  }, [state]);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const {
      target: { name = null, value },
    } = event;

    if (!name) {
      throw new Error("useForm onChange: input don't have name");
    }

    const $name = name as T;

    if (state[$name] === undefined) {
      throw new Error(
        "useForm onChange: state don't have field with provided name"
      );
    }

    const { validator, validators, formatter } = state[$name];

    setState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[$name],
        value: formatter(value),
        errorMessage: onValidate<T>({
          value,
          state,
          validators,
          validator,
          name: $name,
        }),
        isChanged: value !== prevState[$name].defaultValue,
      },
    }));
  };

  const onBlur = (name?: string) => {
    if (!name) return;

    const $name = name as T;

    if (state[$name] === undefined) {
      throw new Error(
        "useForm onBlur: state don't have field with provided name"
      );
    }

    setState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[$name],
        touched: true,
      },
    }));
  };

  const updateValue = (name: T, value: string, updateDefault = false) => {
    if (!name) {
      throw new Error("useForm updateValue: input don't have name");
    }

    const $name = name as T;

    if (state[$name] === undefined) {
      throw new Error(
        "useForm updateValue: state don't have field with provided name"
      );
    }

    const { validator, validators, formatter } = state[$name];

    setState((prevState) => ({
      ...prevState,
      [$name]: {
        ...prevState[$name],
        value: formatter(value),
        errorMessage: onValidate<T>({
          value,
          state,
          validators,
          validator,
          name: $name,
        }),
        defaultValue: updateDefault
          ? formatter(value)
          : prevState[$name].defaultValue,
        isChanged: !updateDefault && value !== prevState[$name].defaultValue,
      },
    }));
  };

  const setFieldError = useCallback((fieldName: T, error: string) => {
    if (!fieldName || !error) return;

    const $name = fieldName as T;

    setState((prevState) => ({
      ...prevState,
      [$name]: {
        ...prevState[$name],
        errorMessage: error,
        isChanged: false,
      },
    }));
  }, []);

  const onSubmit = (evt?: FormEvent<HTMLFormElement> | MouseEvent): void => {
    evt?.preventDefault();

    if (!!submitHandler && typeof submitHandler === 'function') {
      submitHandler(state, setState);
    }
  };

  const polluteForm = (data: UseFormProps<T>) => {
    const newState = initState(data);
    setState(newState);
  };

  return {
    inputState,
    onChange,
    onBlur,
    updateValue,
    onSubmit,
    isValid,
    isChanged,
    hasErrors,
    setFieldError,
    polluteForm,
  };
}
