import { checkEmailErrorCodes } from './_common.types';
import { chessSide } from 'chessgun/core';
import { IPgnMove } from './_game.types';

export enum SocketStatus {
  INITIAL_CONNECTING = 'INITIAL CONNECTING',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED',
  RECONNECTING = 'RECONNECTING',
  LOGIN_RECONNECTING = 'LOGIN RECONNECTING',
}

export type ICardInputs =
  | 'card_number'
  | 'exp_month'
  | 'exp_year'
  | 'securitycode'
  | 'name';

export interface ICheckEmailError {
  code: checkEmailErrorCodes;
  details: string;
  error_msg?: string;
  is_verified?: boolean;
}

export interface INotationRow {
  [chessSide.WHITE]: IPgnMove;
  [chessSide.BLACK]: IPgnMove | null;
}
