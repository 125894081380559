import { IRatingLimits } from 'shared/atoms';
import { IAdvancedTimeControl } from '@types';

export const initialRatingLimits: IRatingLimits = {
  lower: 1100,
  upper: 1300,
};

export const initialTimeControl: IAdvancedTimeControl = {
  additional_time: '00:00:00',
  additional_time_move: 0,
  white_start_time: '00:03:00',
  black_start_time: '00:03:00',
  board_type: 3,
  board_type_name: 'blitz',
  id: 9,
  increment: '00:00:00',
  increment_number: 0,
  increment_start_move: 0,
  name: 'Blitz 3 min',
  start_time: '00:03:00',
  start_time_number: 3,
};

export const requestQueuePollingInterval = 3000;
