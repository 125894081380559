import { LocaleType } from '@types';

export const timePlural = (time: number, timeLocalization: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2]; // 1 час/минута, 2/3/4 часа/минуты, 5 и > часов/минут
  return timeLocalization[
    time % 100 > 4 && time % 100 < 20 ? 2 : cases[time % 10 < 5 ? time % 10 : 5]
  ];
};

export const getTimeText = (
  hours: number,
  locale: LocaleType,
  localization: string,
  ruLocalization: string[]
) => {
  if (hours === null) {
    return '';
  }
  if (locale !== LocaleType.RU) {
    return localization;
  }
  return timePlural(hours, ruLocalization);
};
