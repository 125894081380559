import Router from 'next/router';

import {
  IRequestError,
  ITournamentExpandedData,
  ITournamentSponsorsData,
  ITournamentStream,
  TournamentBoardType,
  ITournamentPlayersData,
} from '@types';

import { httpStatuses } from '@constants';
import { IBroadcastLobbyPageProps } from '@pages/broadcasts/[id]/index.page';
import { tournamentActions } from '.';
import { TMainStore } from '@store/storeshed';
import { newsService } from '@services/_news.service';
import { broadcastService } from '@services/_broadcast.service';

export const createTournamentBroadcastActions = () => {
  const setBroadcastTournamentData = (
    { tournamentStore: store }: TMainStore,
    data: ITournamentExpandedData
  ) => {
    store.dispatch('boards_type', TournamentBoardType.BROADCAST);
    store.dispatch('tournament_data', data);
  };

  /**
   * Выполняет запрос на получение информации о турнире для бродкаста.
   * @param {number | string} tournamentId - id турнира
   */
  const getBroadcastTournament = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    store.dispatch('tournament_data_request', true);

    try {
      const { status, data } = await broadcastService.getBroadcastTournament(
        tournamentId
      );

      if (status === httpStatuses.OK) {
        tournamentActions.setBroadcastTournamentData(data);

        // if (data.stages?.length) {
        //   store.dispatch('stages_tabs', data.stages);
        //   store.dispatch(
        //     'current_stage',
        //     data.active_stage ? data.active_stage : data.stages[0]
        //   );
        // }
      }
    } catch (err) {
      const error = err as IRequestError;
      if (error.status === httpStatuses.NOT_FOUND) {
        Router.push(`/not_found`);
      }

      console.log(error);
    }

    store.dispatch('tournament_data_request', false);
  };

  const setBroadcastSponsors = (
    { tournamentStore: store }: TMainStore,
    data: ITournamentSponsorsData
  ) => {
    store.dispatch('tournament_sponsors', data.results);
  };

  /**
   * Выполняет запрос на получение информации о спонсорах бродкаста
   * @param {number | string} tournamentId - id турнира
   */
  const getBroadcastSponsors = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    store.dispatch('tournament_sponsors_request', true);

    try {
      const { ok, data } = await broadcastService.getBroadcastSponsors(
        tournamentId
      );

      if (ok) {
        tournamentActions.setBroadcastSponsors(data);
      }
    } catch (err) {
      console.log(err);
    }

    store.dispatch('tournament_sponsors_request', false);
  };

  const setBroadcastStreams = (
    { tournamentStore: store }: TMainStore,
    data: ITournamentStream[]
  ) => {
    store.dispatch('tournament_streams', data);
  };

  /**
   * Выполняет запрос на получение информации о стримах бродкаста
   * @param {number | string} tournamentId - id турнира
   */
  const getBroadcastStreams = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    store.dispatch('tournament_streams_request', true);

    try {
      const { ok, data } = await broadcastService.getBroadcastStreams(
        tournamentId
      );

      if (ok) {
        tournamentActions.setBroadcastStreams(data);
      }
    } catch (err) {
      console.log(err);
    }

    store.dispatch('tournament_streams_request', false);
  };

  const setBroadcastPlayersData = (
    { tournamentStore: store }: TMainStore,
    data: ITournamentPlayersData
  ) => {
    store.dispatch('tournament_players_data', data);
  };

  /**
   * Выполняет запрос на получение участников бродкаста.
   * @param {number | string} broadcastId - id турнира
   */
  const getBroadcastPlayers = async (
    { tournamentStore: store }: TMainStore,
    broadcastId: number | string
  ) => {
    store.dispatch('tournament_players_data_request', true);

    try {
      const { ok, data } = await broadcastService.getBroadcastStanding({
        id: broadcastId,
        limit: 50,
      });

      if (ok) {
        tournamentActions.setBroadcastPlayersData(data);
      }
    } catch (err) {
      console.log(err);
    }

    store.dispatch('tournament_players_data_request', false);
  };

  /**
   * Выполняет запрос на получение участников бродкаста для выбранной стадии.
   * @param {number | string} broadcastId - id турнира
   * @param {string} stage - стадия турнира
   */
  const getBroadcastCurrentStagePlayers = async (
    { tournamentStore: store }: TMainStore,
    broadcastId: number | string,
    stage: string
  ) => {
    store.dispatch('current_stage_players_request', true);

    try {
      const { ok, data } = await broadcastService.getBroadcastStanding({
        id: broadcastId,
        limit: 50,
        stage,
      });

      if (ok) {
        store.dispatch('current_stage_players', data);
      }
    } catch (err) {
      console.log(err);
    }

    store.dispatch('current_stage_players_request', false);
  };

  /**
   * Выполняет запрос на получение новостей по турниру бродкаста
   * @param {string} tag - новостной тэг
   */
  const getTaggedNews = async (
    { tournamentStore: store }: TMainStore,
    tag: string
  ) => {
    store.dispatch('tagged_news_request', true);

    try {
      const { ok, data } = await newsService.getTaggedNews(tag);

      if (ok) {
        store.dispatch('tagged_news', data);
      }
    } catch (err) {
      console.log(err);
    }

    store.dispatch('tagged_news_request', false);
  };

  const setCurrentStage = (
    { tournamentStore: store }: TMainStore,
    stage: string
  ) => {
    store.dispatch('current_stage', stage);
  };

  const hydrateBroadcastData = (
    _: TMainStore,
    {
      broadcastStreams,
      broadcastPlayers,
      broadcastSponsors,
      broadcastTournament,
    }: Partial<IBroadcastLobbyPageProps>
  ) => {
    // tournamentActions.resetTournament();

    if (broadcastStreams) {
      tournamentActions.setBroadcastStreams(broadcastStreams);
    }
    if (broadcastPlayers) {
      tournamentActions.setBroadcastPlayersData(broadcastPlayers);
    }

    if (broadcastSponsors) {
      tournamentActions.setBroadcastSponsors(broadcastSponsors);
    }

    if (broadcastTournament) {
      tournamentActions.setBroadcastTournamentData(broadcastTournament);
    }
  };

  return {
    setBroadcastTournamentData,
    getBroadcastTournament,

    setBroadcastSponsors,
    getBroadcastSponsors,

    setBroadcastStreams,
    getBroadcastStreams,

    setBroadcastPlayersData,
    getBroadcastPlayers,
    getBroadcastCurrentStagePlayers,
    getTaggedNews,
    setCurrentStage,

    hydrateBroadcastData,
  };
};
