import React from 'react';
import { IGameInQueueDataResponse } from '@store/context/lobby_context/_lobby_context.types';
import { chessSide } from 'chessgun/core';
import {
  AccountVerification,
  eAccessLevel,
  eChessColor,
  figureType,
  GameRatingMode,
  IImageThumbnails,
  ITimeControl,
} from './_common.types';
import {
  ITournamentGameTour,
  ITournamentGameTournament,
  TournamentBoardStatus,
} from './_tournament.types';
import { IChessFigureIcon } from './_components.types';

export enum GameResult {
  WHITE_WIN = 1,
  DRAW = 2,
  BLACK_WIN = 3,
  ABORTED = 4,
  CANCELLED = 5,
}

export enum oppMode {
  HUMAN = 'human',
  FRIEND = 'friend',
  BOT = 'bot',
}

export enum GameType {
  SINGLE_GAME = 'single game',
  TOURNAMENT = 'tournament',
  BROADCAST = 'broadcast',
  NONE = 'none',
}

export enum GameUserType {
  PLAYER = 'player',
  VIEWER = 'viewer',
  NONE = 'none',
}

export interface IRatingLimits {
  lower: number;
  upper: number;
}

export enum EndReason {
  TIMEOUT = 'TIMEOUT',
  CLASSIC = 'CLASSIC',
  TIME_CONTROL = 'TIME_CONTROL',
  RESIGN = 'RESIGN',
  DRAW = 'DRAW',
  ABORT = 'ABORT',
  DISCONNECT = 'DISCONNECT',
  DRAW_OFFER = 'DRAW_OFFER',
  FOLD_REPETITION = 'FOLD_REPETITION', // Draw because of 5 repetitions,
  THREEFOLD_REPETITION = 'THREEFOLD_REPETITION',
  ALREDY_ENDED = 'ALREADY ENDED',
  FAIR_PLAY_VIOLATION = 'FAIR_PLAY_VIOLATION', // bot extesions
}

export enum GameStatus {
  NONE = 'none',
  INITIALIZING = 'initializing',
  CREATING = 'creating',
  CREATED = 'created',
  SUBSCRIBED = 'subscribed',
  STARTED = 'started',
  ENDED = 'ended',
  ABORTED = 'aborted',
  ERROR = 'error',

  DISCONNECTED = 'disconnected', // для реконнекта после разъединения сокетов
}

export enum TimeLimitWarning {
  NoTimeLimitWarning = 0,
  IdleTimeLimitWarning = 1,
  EndGameTimeLimitWarning = 2,
}

export enum InviteErrors {
  FIDE_RATED_GAME_UNAVAILABLE_FOR_USER = 'non_premium_available_rating',
  ANON_AVAILABLE_RATING = 'anon_available_rating',
  REQUEST_NOT_VERIFIED = 'request_not_verified',
  INVALID_CODE = 'friend_invite_code_is_invalid',
  OWNER_DISCONNECTED = 'owner_disconnected',
  TOO_MANY_ACTIVE_GAMES = 'player_has_too_many_active_single_game_boards',
  CHALLENGE_NOT_FOUND = 'challenge_not_found',
}

export interface IPgnMove {
  id: string;
  position: string;
  figure: React.JSXElementConstructor<IChessFigureIcon> | null;
  figureType?: figureType;
  check?: boolean;
  checkmate?: boolean;
}

export interface IInviteData {
  uid: string;
  created: string;
  player_rating: number;
  invite_code: string;
  player_uid: string;
  rating: GameRatingMode[];
  opp_mode: oppMode;
  time_control: ITimeControl;
  rating_limits: {
    lower: string;
    upper: string;
    bounds: string;
  };
  desired_color: eChessColor | null;
  is_new_gaming?: boolean;
}

export interface IInviteBody {
  player_uid: string;
  rating: string[]; // GameRatingMode
  opp_mode: string; // oppMode
  time_control: number[]; // ITimeControl
  rating_limits?: IRatingLimits;
  desired_color?: eChessColor | null;
  opp_uid?: string;
  rematch?: boolean;
}

export interface IInviteGameData {
  player_uid: string;
  opp_mode: string;
  rating: string;
  time_control: number;
  ratingLimits?: IRatingLimits;
  desired_color?: eChessColor;
  opp_uid?: string;
  rematch?: boolean;
  is_new_gaming?: boolean;
  is_multiregion?: boolean;
}

export type CreateInviteBodyParams = {
  desired_color?: 'white' | 'black';
  rating_from?: number | undefined;
  rating_to?: number | undefined;
  time_control_id?: number;
  owner_anonymous_uid?: string | undefined;
  with_bot?: number;
};

export type Invite = {
  desiredColor?: 'white' | 'black';
  inviteType: 'bot' | ''; // add later
  ratingFrom?: number;
  ratingTo?: number;
  timeControlId: number;
  ownerAnonymousUid?: string;
};

export type CreateInviteParams = Invite;
export interface IBoardResult {
  result: GameResult;
  white_uid: string;
  white_elo: number;
  white_delta: number;
  white_elo_result: number;
  white_calories_loss: number;
  black_elo: number;
  black_delta: number;
  black_elo_result: number;
  black_calories_loss: number;
}

export interface IInviteErrorData {
  code: InviteErrors;
  detail: string;
  friend_name?: string | null;
}

export interface IBoardEndedData {
  black_ms_left: number;
  reason: EndReason;
  result: GameResult;
  white_ms_left: number;
  finished_at?: string | null;
  board_id: string;
}

export enum PolygonColors {
  WHITE = '#8E8378',
  BLACK = 'black',
  RED = '#F4574D',
  GREEN = '#9FC620',
}

export interface IPolygon {
  color: PolygonColors;
  startPoints: string;
  points: string;
}

export interface ITimeSpentItem {
  fen: string;
  is_white_move: boolean;
  ms_spent: number;
}

export interface IClassifiedPolygon extends IPolygon {
  from?: { x: number; y: number };
  to?: { x: number; y: number };
}

export interface IKeyMomentInfo {
  isWhiteMove: boolean;
  color: PolygonColors;
  san: string | null;
  lan: string | null;
  mateIn: number | null;
  fen: string;
  moveNumber: number;
  moveEval: number;
  left: number;
  claclassification: 'good' | 'bad' | null;
}

export interface IChartData {
  baseItems: IPolygon[];
  classifiedItems: IClassifiedPolygon[];
  d: string;
  startD: string;
  keyMoments: IKeyMomentInfo[] | null;
}

export interface IChartBoundares {
  maxTimeSpent: number;
  maxEval: number;
}

export interface IAnalysisMove {
  eval?: number | null;
  long_san: string;
  mate_in?: number | null;
  fen: string;
  san: string | null;
  is_white_move: boolean;
  classification?: 'bad' | 'good' | '' | null;
  key_moment?: boolean | null;
}

export interface IMyMove {
  created: string;
  fen: string;
  san: string;
  lan: string;
}

export interface ILineMoves {
  [chessSide.WHITE]: IPgnMove | null;
  [chessSide.BLACK]: IPgnMove | null;
}

export interface ISuspicionReport {
  id: number;
  sender_id: number;
  suspect_id: number;
  board_uid: string;
  user_estimation: number;
  created_at: string;
}

export interface IGamePlayer {
  full_name: string | null;
  first_name?: string;
  last_name?: string;
  rating: number;
  avatar?: IImageThumbnails | null;
  avatar_thumbnails?: IImageThumbnails | null;
  nationality_id?: number | null;
  country?: number | null;
  nickname?: string;
  fide_id?: number | null;
  otb_title?: string | null;
  foa_title_wchess?: string | null;
  uid?: string;
  birth_date?: string;
  player_id?: number;
  tournament_points?: number | null;
  tournament_rank?: number | null;
  ready_for_arena?: boolean;
  is_online?: boolean;
  foa_title?: string | null;
  fide_title?: string | null;
  fide_verified_status?: AccountVerification | null;
  access_level?: eAccessLevel;
  is_simple_bot?: boolean | null;
}

export interface IMoveAnalysisItem {
  line_eval: number | null;
  line: (string | { lan: string; san: string })[];
  mate_in: number | null;
}

export interface IGameMoveAnalysis {
  depth: number;
  multipv: IMoveAnalysisItem[] | null;
}

export interface IGameMove {
  fen: string;
  long_san: string;
  san: string;
  move_number: number;
  is_white_move: boolean;
  created: string;
  black_ms_left: number;
  white_ms_left: number;
  eval?: number | null;
  analysis?: IGameMoveAnalysis;
  made_in?: string | null;
  ms_spent: number;
}

export interface IBoardData {
  analysis: boolean | null;
  board_id: string;
  player_status: string;
  result: GameResult | null;
  black_player: IGamePlayer;
  white_player: IGamePlayer;
  time_control: ITimeControl;
  rating: GameRatingMode;
  rematch_opp_mode: oppMode;
  black_ms_left: number;
  created: string;
  finished_at: string | null;
  moves: IGameMove[];
  status: TournamentBoardStatus;
  white_ms_left: number;
  pgn: string | null;
  chat_created: boolean;
  tournament: ITournamentGameTournament | null;
  tour: ITournamentGameTour | null;
  tour_desk_number: number;
  suspicion_reports: ISuspicionReport[];
}

export interface IGameData {
  result: number;
  rating: GameRatingMode;
  black_player_profile: IGamePlayer;
  white_player_profile: IGamePlayer;
  black_uid: string;
  white_uid: string;
  time_control: ITimeControl | null;
  board_pgn: string;
  pgn_download_name: string;
  created: string;
  black_delta: number | null;
  white_delta: number | null;
  color: eChessColor;
  white_calories_loss?: number;
  black_calories_loss?: number;
  game_type: string | null;
  tournament_id?: string;
}

export interface IShortChallengeRequestParams {
  desiredColor?: eChessColor;
  ratingType: GameRatingMode;
  ratingFrom?: number;
  ratingTo?: number;
  timeControlId: number;
  ownerAnonymousUid?: string | null;
  inviteType?: 'bot' | 'challenge';
}

export interface IInviteChallengeRequestParams {
  desiredColor?: eChessColor;
  timeControlId: number | null;
  ratingFrom?: number;
  ratingTo?: number;
  inviteType?: 'bot' | 'challenge';
  withOpponentUid?: string;
  ratingType?: GameRatingMode;
}

export interface ILongChallengeRequestParams {
  opponentId: number;
  timeControlId: number;
  desiredColor?: eChessColor;
  ratingType?: GameRatingMode;
}

export interface IChallengesQueueRequestData {
  count: number;
  next: string | null;
  previous: string | null;
  results: IGameInQueueDataResponse[];
}

export interface IAcceptShortChallengeRequestParams {
  inviteId: number;
}

export interface ISuspicionReportRequestParams {
  boardUid: string;
  senderId: number;
  userEstimation: number;
}

export interface IAwaitingPlayerData {
  created: string;
  desired_color: chessSide | null;
  fide_blitz: number | null;
  fide_bullet: number | null;
  fide_rapid: number | null;
  invite_code: string;
  is_new_gaming: boolean;
  is_multiregion: boolean;
  opp_mode: 'human' | 'friend';
  player_rating: number;
  player_uid: string;
  profile?: Omit<IGamePlayer, 'rating'>;
  rating: string[];
  rating_limits: IRatingLimits;
  time_control: ITimeControl;
  uid: string;
  worldchess_blitz: number;
  worldchess_bullet: number;
  worldchess_rapid: number;
}

export type GetGameRegionByIdResponse = Record<string, string>;
