import {
  IProfileData,
  IProfileEvent,
  IRatingsData,
  IPlayerTournaments,
  IStatisticData,
  IRatingsChartsData,
  IProSubscription,
  ITournamentData,
  ICertificatesData,
} from '@types';
import { Store, useStore, useStoreComputed } from 'storeshed';

export interface IProfileState {
  data: IProfileData | null;
  data_request: boolean;
  events_history: IProfileEvent[] | null;
  events_history_request: boolean;
  ratings: IRatingsData | null;
  ratings_request: boolean;
  tournaments: IPlayerTournaments | null;
  tournaments_request: boolean;
  wc_stats: IStatisticData | null;
  fide_stats: IStatisticData | null;
  otb_stats: IStatisticData | null;
  wc_charts: IRatingsChartsData | null;
  fide_charts: IRatingsChartsData | null;
  otb_charts: IRatingsChartsData | null;
  stats_request: boolean;
  pro_subscription: IProSubscription | null;
  upcoming_tournaments: ITournamentData[] | null;
  upcoming_tournaments_request: boolean;
  is_online: boolean;
  certificates: ICertificatesData;
}

export const profileInitialState: IProfileState = {
  data: null,
  data_request: false,
  events_history: null,
  events_history_request: false,
  ratings: null,
  ratings_request: false,
  tournaments: null,
  tournaments_request: false,
  wc_stats: null,
  fide_stats: null,
  otb_stats: null,
  wc_charts: null,
  fide_charts: null,
  otb_charts: null,
  stats_request: false,
  pro_subscription: null,
  upcoming_tournaments: null,
  upcoming_tournaments_request: false,
  is_online: false,
  certificates: {
    pdf: [],
    png: [],
    zip: '',
  },
};

export const profileStore = new Store<IProfileState>({
  ...profileInitialState,
});

type TUseProfileStore = {
  <K extends keyof IProfileState>(key: K): IProfileState[K];
};
type TUseProfileComputed = {
  <K extends keyof IProfileState, R>(
    key: K,
    cb: (state: IProfileState) => R
  ): R;
};

export const useProfileStore: TUseProfileStore = (key) => {
  return useStore(profileStore, key);
};

export const useProfileComputed: TUseProfileComputed = (key, cb) => {
  return useStoreComputed(profileStore, key, cb);
};
