import { useMemo } from 'react';

import { useUserDataComputed } from '@store/storeshed';
import { useProfileComputed } from '@store/storeshed/stores/_profile.store';
import { AccountVerification, eFideIdStatus } from '@types';

export const useFideIdStatus = (isProfile?: boolean) => {
  const userFideId = useUserDataComputed('data', ({ data }) => data?.fide_id);
  const userFideVerifiedStatus = useUserDataComputed(
    'data',
    ({ data }) => data?.fide_verified_status
  );
  const profileFideId = useProfileComputed('data', ({ data }) => data?.fide_id);
  const profileFideVerifiedStatus = useProfileComputed(
    'data',
    ({ data }) => data?.fide_verified_status
  );

  const fideId = isProfile ? profileFideId : userFideId;
  const fideVerifiedStatus = isProfile
    ? profileFideVerifiedStatus
    : userFideVerifiedStatus;

  const fideIdStatus: eFideIdStatus = useMemo(() => {
    if (fideId && fideVerifiedStatus === AccountVerification.VERIFIED) {
      return eFideIdStatus.CONNECTED;
    }

    if (fideVerifiedStatus === AccountVerification.ON_CHECK) {
      return eFideIdStatus.ON_CHECK;
    }

    if (fideVerifiedStatus === AccountVerification.NOT_VERIFIED) {
      return eFideIdStatus.REJECTED;
    }

    return eFideIdStatus.NOT_CONNECTED;
  }, [fideId, fideVerifiedStatus]);

  return fideIdStatus;
};
