import {
  GameRatingMode,
  GameResult,
  IGameMove,
  IGameMoveAnalysis,
  IGamePlayer,
  ITimeControl,
  ITournamentGameTour,
  ITournamentGameTournament,
  TournamentBoardStatus,
} from '@types';

import { ApiNotification } from '@services/_notifications.service';

import { eDoneReason, eDoneResult, ePhase } from './_common.types';

// ======================= WS TYPES ======================= //

/**
 * Подробная информация {@link https://gitlab.com/worldchess/new-gaming/game-server}
 */

export enum eNewGamingActions {
  GAME_STATE = 'GAME_STATE',
  RSVD_DEBUG = 'RSVD_DEBUG',
  RSVD_TEST_PAYLOAD_TYPE = 'RSVD_TEST_PAYLOAD_TYPE',
  GAME_REQUEST_STATE = 'GAME_REQUEST_STATE',
  RSVD_PING = 'RSVD_PING',
}

export enum eCause {
  YOU_CONNECTED = 'YOU_CONNECTED',
  RIVAL_CONNECTED = 'RIVAL_CONNECTED',
  RIVAL_DISCONNECTED = 'RIVAL_DISCONNECTED',
  PHASE_CHANGED = 'PHASE_CHANGED',
  FIRST_MOVE_WARN = 'FIRST_MOVE_WARN',
  DRAW_OFFER = 'DRAW_OFFER',
  SCHEDULED_WARN = 'SCHEDULED_WARN',
  CONNECTING_WARN = 'CONNECTING_WARN',
  BAD_MOVE = 'BAD_MOVE',
  MOVED = 'MOVED',
  DRAW_REJECTED = 'DRAW_REJECTED',
  UNEXPECTED = 'UNEXPECTED',
}

export enum eDrawRule {
  DRAW_OFFER_WHITE = 'DRAW_OFFER_WHITE',
  DRAW_OFFER_BLACK = 'DRAW_OFFER_BLACK',
  DRAW_50_MOVES = 'DRAW_50_MOVES',
  DRAW_3_FOLD_REPETITION = 'DRAW_3_FOLD_REPETITION',
}

export interface ITurn {
  start: {
    absNum: number; // as opposite to colorNumber it increases when any player makes a move
    clr: boolean; // "true" for white, "false" for black
    clk: IGameClock; // copy of game clock right before start of the turn
    ts: number; // start of turn in server's time
    trueTs: number; // actual start of turn in server's time (affected by "pre first move" sub-phase)
  };
  end: {
    fen: string;
    clk: IGameClock; // copy of game clock right after end of the turn
    ts: string; // when turn was completed in server's time
    san: string;
    uci: string;
  };
}

export interface IGameClock {
  spent: number; // time spent in the game, millis
  left: number; // time left, millis
  limit: number; // maximum length of the game with all increases, millis
  creation: string; // when clock created, ignore it
}

/**
 * Котроль времени игрока с сервера
 */
export interface IServerPlayerTimeControll {
  init: number; // initial time, millis
  incrOnEachMove: {
    // how time is increases on each move
    incr: number; // increase, millis
  };
  incrOnNumberedMove: {
    // how time is increased on specified move number, millis
    incr: number; // increase, millis
    colorNumber: number; // as opposite to absNumber it increases only if corresponding color makes a move
  };
}

export interface IFirstMoveCfg {
  warns: number[]; // when to send warnings to player AFTER start of his turn, millis
  abort: number; // when to abort game if player didn't make his move AFTER start of his turn, millis
}

export interface IPreFirstMoveCfg {
  timeout: number; // when to end this sub-phase if player didn't make his move AFTER start of his turn, millis
  abortOnTimeout: boolean; // is game will be aborted after `timeout`
}

export type PublicExtra = {
  // any JSON object for public usage, e.g. with tournament description
  rId?: string; // not null for multiregion game (set by CGS)
  multiboardGameOn?: { gId: string; mbId: string; untilTs: number };

  white_player?: PublicExtraPlayer;
  black_player?: PublicExtraPlayer;
  result?: GameResult | null;
  tour?: Pick<ITournamentGameTour, 'number'>;
  time_control?: PublicExtraTimeControl;
  rating?: GameRatingMode;

  tour_id?: number;
  tour_number?: number;
  tournament_finish?: ITournamentGameTournament['finish'];
  tournament_id?: ITournamentGameTournament['id'];
  tournament_slug?: ITournamentGameTournament['slug'];
  tournament_status?: ITournamentGameTournament['status'];
  tournament_title?: ITournamentGameTournament['title'];
  tournament_kind?: ITournamentGameTournament['kind'];
};

export type PublicExtraPlayer = Pick<
  IGamePlayer,
  | 'full_name'
  | 'birth_date'
  | 'nationality_id'
  | 'rating'
  | 'fide_id'
  | 'fide_verified_status'
  | 'player_id'
  | 'otb_title'
  | 'foa_title_wchess'
  | 'access_level'
> & {
  avatar?: string | null;
};

export type PublicExtraTimeControl = Pick<
  ITimeControl,
  'board_type_name' | 'start_time' | 'increment'
>;

export interface IGameCfg {
  gId: string; // game identifier
  wPId: string; // white player identifier
  bPId: string; // black player identifier
  scheduledPhCfg?: {
    // configuration of "scheduled phase" of the game
    warns: number[]; // when to send warnings to players BEFORE phase end ("start"), millis
    start: number; // when to proceed to next phase
  };
  connectingPhaseCfg: {
    // configuration of "connecting phase" of the game
    warns: number[]; // when to send warnings to players AFTER phase start, millis
    abort: number; // when to abort game if not all players connected AFTER phase start, millis
  };
  playingPhCfg: {
    // configuration of "playing phase" of the game
    wPreFirstMoveCfg: IPreFirstMoveCfg; // config of "pre first move" sub-phase for white player
    bPreFirstMoveCfg: IPreFirstMoveCfg; // config of "pre first move" sub-phase for black player
    wFirstMoveCfg: IFirstMoveCfg; // configuration of first move for white player
    bFirstMoveCfg: IFirstMoveCfg; // configuration of first move for black player
    wTc: IServerPlayerTimeControll; // white player time control
    bTc: IServerPlayerTimeControll; // black player time control
    lossIfDisconnectedFor: number;
  };
  playerSessionCfg: {
    // configuration of player session
    minTurnDuration: number; // minimal duration of turn which server can register, millis
    maxLatency: number; // maximal latency of player session, millis
    minFirstMoveLatency: number; // latency which is applied for first move, millis
  };
  publicExtra: PublicExtra;
}

/**
 * Стэйт игры с сервера {@link https://gitlab.com/worldchess/new-gaming/game-server}
 */
export interface IGameState {
  cause: eCause; // the reason this message was sent
  currPh: ePhase; // current game phase
  // state of connection statuses
  misc: {
    wSessions: number; // white ws sessions
    wSessionsTs: number; // white ws sessions last message
    bSessions: number; // black ws sessions
    bSessionsTs: number; // black ws sessions last message
    vSessions: number; // viewers ws sessions
    ts: number; // state creation timestamp
    version: number; // state version, increases each time when state is changed
  };
  // state of "scheduling phase"
  scheduledPh: {
    phEnd: number; // when phase is ended
  };
  // state of "connecting phase", null if not yet
  connectingPhase: {
    phaseEnd: string; // when phase is ended
  } | null;
  // state of "playing phase", null if not yet
  playingPh: {
    bClk: IGameClock; // last registered clock state for black player
    currTurn: ITurn['start'] | null; // last finished turn, null if not yet
    draw: eDrawRule[]; // draw possibilities, can be empty if draw is not possible
    lastTurn: ITurn | null; // last finished turn, null if not yet
    wClk: IGameClock; // last registered clock state for white player
    phStart: number; // when phase is started, effectively start of the game
    turns: ITurn[]; // all turns in order
  } | null;
  // state of "done phase", null if not yet
  donePh: {
    phStart: string; // when phase is started, effectively end of the game
    result: eDoneResult; // game result
    reason: eDoneReason; // reason why game is ended
  } | null;
  // TODO: make it optional because it could be undefined
  cfg: IGameCfg; // configuration of the game, immutable
}

export enum IMessageAdapter {
  START = 'START',
  FIRST_MOVE_WARN = 'FIRST_MOVE_WARN',
  MOVE = 'MOVE',
  ABORT = 'ABORT',
  RESIGN = 'RESIGN',
}

export type TNewGamingMessageType = IGameState;

export interface INewGamingMessage {
  payloadType: eNewGamingActions;
  payload: TNewGamingMessageType;
}

export interface INGPlayerLatencies {
  [userUid: string]: {
    byRegion: { [name: string]: number };
  };
}

export enum eBroadcastCause {
  STATUS_CHANGED = 'STATUS_CHANGED',
  MOVED = 'MOVED',
  MOVES_CHANGED = 'MOVES_CHANGED',
  TIME_CHANGED = 'TIME_CHANGED',
  MOVE_ANALYZED = 'MOVE_ANALYZED',
}

export interface IBroadcastMove {
  san: string;
  uci: string;
  fen: string;
  moveNumber: number;
}

interface IBroadcastMessage {
  boardId: string;
}

export interface IBroadcastStatusChanged extends IBroadcastMessage {
  cause: eBroadcastCause.STATUS_CHANGED;
  status: TournamentBoardStatus;
  result: GameResult | null;
}

export interface IBroadcastMoved extends IBroadcastMessage {
  cause: eBroadcastCause.MOVED;
  bTime: number;
  wTime: number;
  move: IBroadcastMove;
}

export interface IBroadcastMovesChanged extends IBroadcastMessage {
  cause: eBroadcastCause.MOVES_CHANGED;
  moves: IGameMove[];
}

export interface IBroadcastBoardTimeChanged extends IBroadcastMessage {
  cause: eBroadcastCause.TIME_CHANGED;
  bTime: number;
  wTime: number;
  lastMoveMadeIn: string | null;
}

export interface IBroadcastMoveAnalyzed extends IBroadcastMessage {
  cause: eBroadcastCause.MOVE_ANALYZED;
  moveNumber: number;
  analysis: IGameMoveAnalysis;
  fen: string;
}

export type IBroadcastBoardStatePayload =
  | IBroadcastStatusChanged
  | IBroadcastMoved
  | IBroadcastMovesChanged
  | IBroadcastBoardTimeChanged
  | IBroadcastMoveAnalyzed;

export type IUserNotificationNewPayload = ApiNotification;
