import { UseFormState } from '@utils/form/_useForm.hook';
import { IInputStateValidatorsItem } from '@utils/form/_inputTypes';

export function onValidate<T extends string>({
  value,
  state,
  name,
  validators,
  validator,
}: {
  value: string;
  state: UseFormState<T>;
  name: T;
  validators?: IInputStateValidatorsItem<T>[] | null;
  validator: (val: string) => string | null;
}) {
  if (!!validators?.length) {
    for (let i = 0; i < validators.length; i++) {
      const errorMessage = validators[i].validator({
        value,
        state,
        name,
      });
      if (errorMessage) return errorMessage;
    }
  }
  return validator(value);
}
