import { tournamentActions } from './index';
import { delayedUpdate } from '../../_utils';
import { eTeamsWithPlayersGroups } from '@components/molecules/tournament/_teamGroupedResult';
import { TMainStore } from '@store/storeshed';
import { tournamentsService } from '@services/_tournaments.service';
import { tournamentService } from '@services/_tournament.service';
import {
  ITournamentPlayer,
  ITournamentPlayersData,
  ITournamentTeamPlayPlayersData,
  ITournamentTeamPlayTeamsData,
} from '@types';

interface IRequestTeamPlayTeams {
  tournamentId: number | string;
  limit?: number;
  userPlayerId?: number;
  offset?: number;
  successCallback?: (data: ITournamentTeamPlayTeamsData) => void;
}

interface IRequestTeamPlayPlayers {
  tournamentId: number | string;
  limit?: number;
  offset?: number;
  successCallback?: (data: ITournamentTeamPlayPlayersData) => void;
}

export const createTournamentStandingActions = () => {
  /**
   * Задает значение номера страницы для фильтра участников турнира
   * @param {number} page - номер страницы для фильтра
   */
  const setStandingPage = (
    { tournamentStore: store }: TMainStore,
    pageNumber: number
  ) => {
    store.dispatch('standing_page_number', pageNumber);
  };

  /**
   * Задает значение лимита для фильтра участников турнира
   * @param {number} page - лимит для фильтра
   */
  const setStandingLimit = (
    { tournamentStore: store }: TMainStore,
    limit: number
  ) => {
    store.dispatch('standing_page_limit', limit);
    localStorage.setItem(
      'tournament_page_standing_page_limit',
      limit.toString()
    );
  };

  /**
   * Задает значение информации об участниках турнира.
   * @param {ITournamentPlayersData} playersData - данные об участниках турнира
   */
  const setTournamentPlayersData = (
    { tournamentStore: store }: TMainStore,
    playersData: ITournamentPlayersData | null
  ) => {
    store.dispatch('tournament_players_data', playersData);
  };

  /**
   * Выполняет запрос на получение участников турнира.
   * @param {number | string} tournamentId - id турнира
   */
  const getTournamentPlayers = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string,
    limit?: number
  ) => {
    store.dispatch('tournament_players_data_request', true);

    try {
      const { data } = await tournamentsService.getTournamentStanding({
        id: tournamentId,
        limit: limit || 50,
      });

      if (data) {
        await delayedUpdate(() => {
          store.dispatch('tournament_players_data', data);
        });
      }
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      store.dispatch('tournament_players_data_request', false);
    }, 75);
  };

  /**
   * Выполняет запрос на получение пользователя в турнира.
   * @param {number | string} tournamentId - id турнира
   */
  const getTournamentYou = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string,
    loading?: boolean
  ) => {
    store.dispatch('tournament_you_request', true);
    store.dispatch('tournament_you_loading', loading || false);

    try {
      const { ok, data } = await tournamentService.getTournamentStandingYou(
        tournamentId
      );

      if (ok) {
        tournamentActions.setTournamentYou(data.you);
      }
    } catch (error) {
      console.log(error);
    }

    store.dispatch('tournament_you_request', false);
    setTimeout(() => {
      store.dispatch('tournament_you_loading', false);
    }, 75);
  };

  /**
   * Задает значение количества загруженных данных об участниках турнира.
   * @param {number} playersCount - количество участников
   */
  const setLoadedPlayersCount = (
    { tournamentStore: store }: TMainStore,
    playersCount: number
  ) => {
    store.dispatch('loaded_players_count', playersCount);
  };

  /**
   * Задает значение информации о пользователе как об участнике турнира
   * @param {ITournamentPlayer | null} youData - данные о пользователе в турнире
   */
  const setTournamentYou = (
    { tournamentStore: store }: TMainStore,
    youData: ITournamentPlayer | null
  ) => {
    store.dispatch('tournament_you', youData);
    store.dispatch('ready_for_arena', youData?.ready_for_arena || false);
  };

  /**
   * Выполняет запрос на получение победителей турнира.
   * @param {number | string} tournamentId - id турнира
   */
  const getTournamentWinners = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string
  ) => {
    store.dispatch('tournament_winners_request', true);

    try {
      const { data } = await tournamentsService.getTournamentStanding({
        id: tournamentId,
        limit: 3,
      });

      if (data) {
        store.dispatch('tournament_winners', data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      store.dispatch('tournament_winners_request', false);
    }
  };

  /**
   * Делает запрос за порцией данных команд командных турниров
   */
  const requestTeamPlayTeams = async (
    {}: TMainStore,
    {
      tournamentId,
      limit,
      userPlayerId,
      offset,
      successCallback,
    }: IRequestTeamPlayTeams
  ) => {
    try {
      const { ok, data } = await tournamentService.getTournamentTeamPlayTeams({
        tournamentId,
        limit,
        offset,
        userPlayerId,
      });

      if (ok) {
        successCallback?.(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Выполняет запрос на получение информации о командах командной игры турнира.
   * @param {number | string} tournamentId - id турнира
   */
  const getTournamentTeamPlayTeams = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string,
    limit?: number,
    userPlayerId?: number
  ) => {
    store.dispatch('team_play_data_request', true);

    await tournamentActions.requestTeamPlayTeams({
      tournamentId,
      limit,
      userPlayerId,
      successCallback: (data) => {
        store.dispatch('team_play_teams', data);
      },
    });

    store.dispatch('team_play_data_request', false);
  };

  /**
   * Выполняет запрос на обновление информации о командах командной игры турнира.
   * @param {number | string} tournamentId - id турнира
   */
  const updateTournamentTeamPlayTeams = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string,
    limit?: number,
    userPlayerId?: number
  ) => {
    store.dispatch('team_play_update_request', true);

    await tournamentActions.requestTeamPlayTeams({
      tournamentId,
      limit,
      userPlayerId,
      successCallback: (data) => {
        store.dispatch('team_play_teams', data);
      },
    });

    store.dispatch('team_play_update_request', false);
  };

  /**
   * Выполняет запрос на получение следующей порции информации о командах командной игры турнира.
   * @param {number | string} tournamentId - id турнира
   */
  const pushTournamentTeamPlayTeams = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string,
    limit?: number,
    offset?: number,
    userPlayerId?: number
  ) => {
    store.dispatch('team_play_update_request', true);

    await tournamentActions.requestTeamPlayTeams({
      tournamentId,
      limit,
      offset,
      userPlayerId,
      successCallback: (data) => {
        const teamsData = store.get('team_play_teams');

        if (!teamsData || !teamsData.data.length) {
          store.dispatch('team_play_teams', data);
        } else {
          const newGroupTeams = [...teamsData.data];
          newGroupTeams.push(...data.data);

          store.dispatch('team_play_teams', {
            ...data,
            data: newGroupTeams,
          });
        }
      },
    });

    store.dispatch('team_play_update_request', false);
  };

  /**
   * Делает запрос за порцией данных игроков командных турниров
   */
  const requestTeamPlayPlayers = async (
    {}: TMainStore,
    { tournamentId, limit, offset, successCallback }: IRequestTeamPlayPlayers
  ) => {
    try {
      const { ok, data } = await tournamentService.getTournamentTeamPlayPlayers(
        {
          tournamentId,
          limit,
          offset,
        }
      );

      if (ok) {
        successCallback?.(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Выполняет запрос на получение информации об игроках командной игры турнира.
   * @param {number | string} tournamentId - id турнира
   */
  const getTournamentTeamPlayPlayers = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string,
    limit?: number,
    offset?: number
  ) => {
    store.dispatch('team_play_data_request', true);

    await tournamentActions.requestTeamPlayPlayers({
      tournamentId,
      limit,
      offset,
      successCallback: (data) => {
        store.dispatch('team_play_players', data);
      },
    });

    store.dispatch('team_play_data_request', false);
  };

  /**
   * Выполняет запрос на обновление информации об игроках командной игры турнира.
   * @param {number | string} tournamentId - id турнира
   */
  const updateTournamentTeamPlayPlayers = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string,
    limit?: number,
    offset?: number
  ) => {
    store.dispatch('team_play_update_request', true);

    await tournamentActions.requestTeamPlayPlayers({
      tournamentId,
      limit,
      offset,
      successCallback: (data) => {
        store.dispatch('team_play_players', data);
      },
    });

    store.dispatch('team_play_update_request', false);
  };

  /**
   * Выполняет запрос на получение следующей порции информации об игроках командной игры турнира.
   * @param {number | string} tournamentId - id турнира
   */
  const pushTournamentTeamPlayPlayers = async (
    { tournamentStore: store }: TMainStore,
    tournamentId: number | string,
    limit?: number,
    offset?: number
  ) => {
    store.dispatch('team_play_update_request', true);

    await tournamentActions.requestTeamPlayPlayers({
      tournamentId,
      limit,
      offset,
      successCallback: (data) => {
        const playersData = store.get('team_play_players');

        if (!playersData || !playersData.data.length) {
          store.dispatch('team_play_players', data);
        } else {
          const newGroupPlayers = [...playersData.data];
          newGroupPlayers.push(...data.data);

          store.dispatch('team_play_players', {
            ...data,
            data: newGroupPlayers,
          });
        }
      },
    });

    store.dispatch('team_play_update_request', false);
  };

  /**
   * Выполняет запрос на получение информации командного турнира
   * в зависимости от текущей выбранной вкладки таблицы
   * @param {number | string} tournamentId - id турнира
   */
  const getTeamPlayData = async (
    {}: TMainStore,
    {
      currentGroup,
      tournamentId,
      limit,
      offset,
      userPlayerId,
    }: {
      currentGroup: eTeamsWithPlayersGroups;
      tournamentId: number | string;
      limit?: number;
      offset?: number;
      userPlayerId?: number;
    }
  ) => {
    if (currentGroup === eTeamsWithPlayersGroups.TEAMS) {
      tournamentActions.getTournamentTeamPlayTeams(
        tournamentId,
        limit,
        userPlayerId
      );
    } else if (currentGroup === eTeamsWithPlayersGroups.PLAYERS) {
      tournamentActions.getTournamentTeamPlayPlayers(
        tournamentId,
        limit,
        offset
      );
    }
  };

  /**
   * Выполняет запрос на обновление информации командного турнира
   * в зависимости от текущей выбранной вкладки таблицы
   * @param {number | string} tournamentId - id турнира
   */
  const updateTeamPlayData = async (
    {}: TMainStore,
    {
      currentGroup,
      tournamentId,
      limit,
      offset,
      userPlayerId,
    }: {
      currentGroup: eTeamsWithPlayersGroups;
      tournamentId: number | string;
      limit?: number;
      offset?: number;
      userPlayerId?: number;
    }
  ) => {
    if (currentGroup === eTeamsWithPlayersGroups.TEAMS) {
      tournamentActions.updateTournamentTeamPlayTeams(
        tournamentId,
        limit,
        userPlayerId
      );
    } else if (currentGroup === eTeamsWithPlayersGroups.PLAYERS) {
      tournamentActions.updateTournamentTeamPlayPlayers(
        tournamentId,
        limit,
        offset
      );
    }
  };

  /**
   * Выполняет запрос на получение следующей порции информации командного турнира
   * в зависимости от текущей выбранной вкладки таблицы
   * @param {number | string} tournamentId - id турнира
   */
  const pushStandingsForGroup = async (
    {}: TMainStore,
    {
      currentGroup,
      tournamentId,
      limit,
      offset,
      userPlayerId,
    }: {
      currentGroup: eTeamsWithPlayersGroups;
      tournamentId: number | string;
      limit?: number;
      offset?: number;
      userPlayerId?: number;
    }
  ) => {
    if (currentGroup === eTeamsWithPlayersGroups.TEAMS) {
      tournamentActions.pushTournamentTeamPlayTeams(
        tournamentId,
        limit,
        offset,
        userPlayerId
      );
    } else if (currentGroup === eTeamsWithPlayersGroups.PLAYERS) {
      tournamentActions.pushTournamentTeamPlayPlayers(
        tournamentId,
        limit,
        offset
      );
    }
  };

  return {
    setStandingPage,
    setStandingLimit,
    setTournamentPlayersData,
    getTournamentPlayers,
    setLoadedPlayersCount,
    getTournamentYou,
    setTournamentYou,
    getTournamentWinners,

    getTournamentTeamPlayTeams,
    pushTournamentTeamPlayTeams,
    updateTournamentTeamPlayTeams,

    getTournamentTeamPlayPlayers,
    pushTournamentTeamPlayPlayers,
    updateTournamentTeamPlayPlayers,

    getTeamPlayData,
    updateTeamPlayData,
    pushStandingsForGroup,

    requestTeamPlayTeams,
    requestTeamPlayPlayers,
  };
};
