import { ReactNode } from 'react';

import { ePopupPaths } from '@constants';
import { IBaseButton } from '@components/atoms/buttons/_baseButton';
import { GameRatingMode, ILocalization } from '@types';

export interface IPopup {
  closePopup: () => void;
  type?: string;
}

export type TPasswordPopupType = Extract<
  ePopupPaths,
  | ePopupPaths.PASSWORD
  | ePopupPaths.PASSWORD_CHANGE
  | ePopupPaths.PASSWORD_RESET
  | ePopupPaths.SET_PASSWORD
>;

export interface IPasswordPopup {
  type?: TPasswordPopupType;
}

export type IRegistrationInputs =
  | 'first_name'
  | 'last_name'
  | 'country'
  | 'email'
  | 'password'
  | 'password_repeat';

export type IFideInputs =
  | 'first_name'
  | 'last_name'
  | 'date_of_birth'
  | 'place_of_birth'
  | 'nationality'
  | 'federation'
  | 'gender';

export type IPasswordForm = 'password' | 'password_repeat' | 'current_password';

export interface IUpgradeButton {
  GTMDataLayerEvent?: (props: object) => void;
  className?: string;
  buttonClassName?: string;
  showUpgradeWhenOnCheck?: boolean;
}

export interface IShareButton {
  socialNetwork: 'facebook' | 'twitter';
  path?: string;
  className?: string;
}

export interface IShowMoreButton {
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export interface ICopyButton extends IBaseButton {
  mode?: 'text' | 'button';
  copyText: string;
  className?: string;
  children: ReactNode;
}

export interface IGameSharing {
  l: ILocalization;
  sharingOpened: boolean;
  setSharingOpened: (sharingOpened: boolean) => void;
  className?: string;
}

export interface IFilterColumn {
  name: string;
  values: {
    [k: string]: { checked: boolean; value: string | number };
  };
}

export interface ITabElement {
  text?: string;
  element?: JSX.Element;
  value?: string;
  icon?: React.FC;
  iconWidth?: string;
  iconHeight?: string;
  id: GameRatingMode | string | number;
  disabled?: boolean;
}

export interface IChip {
  label: string;
  value: string;
}

export enum chessFigureStyle {
  STROKE = 'stroke',
  FILLED = 'filled',
}

export interface IChessFigureIcon {
  figureStyle?: chessFigureStyle;
  theme?: string;
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
}
