import {
  ICertificatesData,
  IGameData,
  IPlayerEventsHistoryResponse,
  IPlayerTournaments,
  IRatingsData,
  IRequestPagination,
  IStackedGamesEvent,
} from '@types';
import { BaseService } from './_base.service';
import { createAuthorizationHeaders } from '@utils/_requests';
import { httpInstance } from '.';

export class PlayersService extends BaseService {
  /**
   * Выполняет запрос на получение истории игр пользователя по id
   * @param {number | string} tournamentId - id пользователя
   */
  getPlayerGameHistory(userId: string | number) {
    return this.ajax.get<IRequestPagination<IGameData>>(
      `online/players/${userId}/game-history`
    );
  }

  /**
   * Выполняет запрос на получение рейтингов пользователя по id
   * @param {number | string} tournamentId - id пользователя
   */
  getPlayerRatings(userId: string | number) {
    return this.ajax.get<IRatingsData>(`online/players/${userId}/ratings/`);
  }

  /**
   * Выполняет запрос на получение сыграных турниров пользователя по id
   * @param {number | string} tournamentId - id пользователя
   */
  getPlayerTournaments(userId: string | number) {
    return this.ajax.get<IPlayerTournaments>(
      `online/players/${userId}/tournaments`
    );
  }

  /**
   * Выполняет запрос на получение сыграных турниров пользователя по id
   * @param {number | string} tournamentId - id пользователя
   */
  getPlayerEventsHistory(userId: string | number) {
    return this.ajax.get<IPlayerEventsHistoryResponse>(
      `online/players/${userId}/event-history`
    );
  }

  /**
   * Выполняет запрос на получение сыграных за период времени турниров пользователя по id
   * @param {number | string} tournamentId - id пользователя
   * @param {string} dateFrom - начальная дата
   * @param {string} dateTo - конечная дата
   */
  getPlayerEventsHistoryFromDates({
    userId,
    dateFrom,
    dateTo,
  }: {
    userId: string | number;
    dateFrom: string;
    dateTo: string;
  }) {
    return this.ajax.get<{ resp: IStackedGamesEvent[] }>(
      `/online/players/${userId}/events?date_from=${dateFrom}&date_to=${dateTo}`
    );
  }

  /**
   * Получает сертфикаты игрока
   */
  getCertificates(userId: string, jwt?: string) {
    return this.ajax.get<ICertificatesData>(
      `online/players/${userId}/title-certificates/`,
      createAuthorizationHeaders(jwt)
    );
  }
}

export const playersService = new PlayersService({ instance: httpInstance });
