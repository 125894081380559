import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { ISystemPopups, SocketStatus } from '@types';
import { ILobbyContextProps, ILobbyContextState } from './_lobby_context.types';
import { lobbyReducer } from './_lobby_context.reducer';
import { LobbyWSController } from './ws';
import { applyEventsOnLobbyWs } from './helpers/_apply_events_on_ws';
import { useLobbyContextActions } from './_lobby_context.actions';
import { useAppStore } from '@store/storeshed';
import { useAuthStore } from '@store/storeshed';
import { usePrevious } from './hooks/_use_previous.hook';

const initState: ILobbyContextState = {
  wsStatus: SocketStatus.INITIAL_CONNECTING,
  ws: new LobbyWSController(),
  pingsWithoutAnswer: 0,
  uid: null,
  playerUid: null,
  shortChallenges: [],
  shortChallengeRequests: [],
  cancelShortChallengeRequests: [],
  inviteChallenge: null,
  inviteChallengeRequest: false,
  cancelInviteChallengeRequest: false,
  clearShortChallengesRequest: false,
  shortChallengesRequest: false,
  inviteData: null,
  inviteDataRequest: false,
  requestExpired: false,
  cancelInviteRequest: false,
  selectedInvite: null,
  inviteFriendName: null,
  game_request: null,
  games: {},
  gamesData: {},
  count: 0,
  subscribedTags: [],
  gamesInQueue: null,
};

interface ILobbyContextProvider extends PropsWithChildren {
  popups: ISystemPopups;
}

export const LobbyContext = React.createContext({} as ILobbyContextProps);

export const LobbyContextProvider: React.FC<ILobbyContextProvider> = ({
  popups,
  children,
}) => {
  const userUid = useAuthStore('uid');

  const regions = useAppStore('regions');

  const [state, dispatch] = React.useReducer(lobbyReducer, initState);

  const actions = useLobbyContextActions(state, dispatch);

  applyEventsOnLobbyWs(state, actions, popups);

  const prevUID = usePrevious(userUid);

  useEffect(() => {
    actions.setUID(userUid);

    if (userUid === null) return;

    if (userUid !== prevUID) {
      state.ws.reconnectCauseOfUidChange(userUid);
    }
  }, [userUid]);

  const getPlayerGames = useCallback(() => {
    if (state.playerUid && state.uid === state.playerUid) {
      // actions.resetGames();
      actions.getPlayerGames(state.uid);
    }
  }, [state.playerUid, state.uid]);

  const getPlayerRegionGames = useCallback(() => {
    if (regions && state.playerUid && state.uid === state.playerUid) {
      regions.forEach((regionName) => {
        if (state.uid) {
          actions.getPlayerGames(state.uid, regionName);
        }
      });
    }
  }, [regions, state.playerUid, state.uid]);

  useEffect(() => {
    getPlayerGames();
  }, [getPlayerGames]);

  useEffect(() => {
    getPlayerRegionGames();
  }, [getPlayerRegionGames]);

  useEffect(() => {
    state.ws.dependencies.addDependency({
      id: 'lobby-global',
      pages: new Set([]),
      callback: () => {
        getPlayerGames();
        getPlayerRegionGames();
      },
    });

    return () => {
      state.ws.dependencies.deleteDependency('lobby-global');
    };
  }, [getPlayerGames, getPlayerRegionGames, state.ws.dependencies]);

  return (
    <LobbyContext.Provider
      value={{
        state,
        actions,
      }}
    >
      {children}
    </LobbyContext.Provider>
  );
};

export const useLobbyContext = () => useContext(LobbyContext);
