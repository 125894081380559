import { Howl } from 'howler';
import { ISounds, SoundType, eSoundsTheme } from '@types';
import { appService } from '@services/_app.service';

export class SoundsController {
  private _preparedSounds = false;
  private _sounds: eSoundsTheme = eSoundsTheme.OFF;
  private _soundsData: Partial<Record<SoundType, Howl>> = {};

  private eventListener: (() => void) | null = null;

  constructor() {
    this.getSounds();
  }

  set sounds(sounds: eSoundsTheme) {
    this._sounds = sounds;
  }

  /**
   * Воспроизводит звук
   */
  public playSound(soundType: SoundType) {
    const sound = this._soundsData[soundType];

    if (this._sounds === eSoundsTheme.OFF || !sound) {
      return;
    }

    sound.play();
  }

  private setSoundsData(soundsData: ISounds) {
    const newSounds: { [key: string]: Howl } = {};
    Object.entries(soundsData).forEach(([key, value]) => {
      const sound = new Howl({
        src: `data:audio/wav;base64,${value}`,
        volume: 0.5,
      });
      newSounds[key] = sound;
    });

    this._soundsData = newSounds;

    this.eventListener = this.prepareHowler.bind(this);

    window.addEventListener('touchstart', this.eventListener);
    window.addEventListener('click', this.eventListener);
  }

  private async getSounds() {
    try {
      const { data } = await appService.getSounds();

      this.setSoundsData(data);
    } catch (error) {
      console.log(error);
    }
  }

  private prepareHowler() {
    if (this._preparedSounds) return;

    this._soundsData.knock?.volume(0);
    this._soundsData.knock?.play();

    this._soundsData.knock?.on('end', () => {
      this._soundsData.knock?.volume(0.5);
    });

    if (this.eventListener) {
      window.removeEventListener('touchstart', this.eventListener);
      window.removeEventListener('click', this.eventListener);

      this.eventListener = null;
    }
  }
}
