import React from 'react';

/**
 * Обработчик ошибок для Email форм
 * @param {string} email - Почта
 * @param {string} invalidText - Текст ошибки
 */
export const emailIsNotValid = (
  email: string,
  invalidText = 'Email is invalid'
): string | null => {
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return !emailRegex.test(email) ? invalidText : null;
};

/**
 * Валидатор для кода активации
 * @param {string} activeCode - код активации
 * @param {string} invalidText - Текст ошибки
 */
export const activeCodeIsNotValid = (
  activeCode: string,
  invalidText = 'Invalid activation code'
): string | null => {
  const activeCodeRegex = new RegExp(/^(\d){1,6}$/g);
  return !activeCodeRegex.test(activeCode) ? invalidText : null;
};

/**
 * Валидатор для пароля
 * @param {string} password - пароль
 * @param {string} invalidText - Текст ошибки
 */
export const passwordValidtor = (
  password: string,
  invalidText = 'Weak password'
) => {
  const passwordRegex = new RegExp('^(?=.*[a-z|A-Z])(?=.*[0-9])(?=.{8,})');

  return !passwordRegex.test(password) ? invalidText : null;
};

/**
 * Валидатор для номера карты
 * @param {string} val - номер карты
 */
export const cardNumberValidator = (val: string) => {
  const numbers = val.replace(/\s+/g, '');

  return numbers.length && numbers.length < 15 ? 'Incorrect number' : null;
};

/**
 * Валидирует текст на длину
 * @param {string} val - текст для проверки
 * @param {string} invalidText - Текст ошибки
 */
export const lengthValidator = (val: string, invalidText: string) => {
  if (val.length < 1) {
    return invalidText;
  }
  return null;
};

/**
 * Валидирует текст на английские символы, пробелы и тире
 * @param {string} val - текст для проверки
 * @param {string} invalidText - Текст ошибки
 */
export const engValidator = (val: string, invalidText = 'Incorrect name') => {
  const textRegex = new RegExp(/^([a-zA-Z]+[\-\s]*[a-zA-Z]*)*$/g);

  return !textRegex.test(val) ? invalidText : null;
};

type IChangeInputs = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
interface IBaseValidation {
  defaultValue: string | number | readonly string[] | undefined;
  validation?: (value: string) => void;
  changeEvent?: React.ChangeEventHandler<IChangeInputs>;
}

/**
 * Обработчик ошибок для форм input/textarea/select
 * @param defaultValue
 * @param validation
 */
export const BaseValidation = ({
  defaultValue,
  validation,
  changeEvent,
}: IBaseValidation) => {
  const [value, setValue] = React.useState(defaultValue);
  const [error, setError] = React.useState<string | void>();

  const onChange = (e: React.ChangeEvent<IChangeInputs>): void => {
    const v = e.target.value;
    const error = validation && validation(v);

    // change event передается через пропс
    changeEvent && changeEvent(e);
    // при изменении данных обновляем стейт
    setValue(v);
    // Если есть валидация выдаем ошибку
    setError(error);
  };

  return {
    value,
    onChange,
    error,
  };
};
