import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Provider } from 'react-redux';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import themes from '@chessarena/components/lib/themes';
import { $BoardStyles } from 'chessgun/themes';

import {
  ApplicationContextProvider,
  useApplicationContext,
} from '@application';
import { Settings } from '@components/molecules/popups/_Settings';
import { wrapper } from '@store/_store';
import { LobbyContextProvider } from '@store/context/lobby_context/_lobby.context';
import { NotificationsContextProvider } from '@store/context/notifications/_provider';
import { PopupsContextProvider } from '@store/context/popupsContext/_context';
import { mainStore, useUserDataStore } from '@store/storeshed';
import '@styles/globals.scss';
import { TNextPageWithLayoutAndComponentProps } from '@types';
import { useSystemPopups } from '@utils/hooks/_useSystemPopups.hook';
import { IntercomProvider } from '../HOCS/IntercomProvider';
import { GameLobbyContextProvider } from '@store/context/gameLobbyContext';
import { ActiveGamesContextProvider } from '@store/context/active-games/_context';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';
import { ShouldForwardProp } from 'styled-components/dist/types';

type AppPropsWithLayout = AppProps & {
  Component: TNextPageWithLayoutAndComponentProps;
};

declare global {
  interface Window {
    stores: typeof mainStore;
    HackneyGamesSDK: {
      setup: (params: { botName: string }) => void;
      telegramAuth: () => Promise<void>;
      expandTMA: () => void;
    };
    Telegram: {
      WebApp: {
        enableClosingConfirmation: () => void;
        disableVerticalSwipes: () => void;
      };
    };
  }
}

const PopupsRouter = dynamic(() =>
  import('@components/molecules/popups/_PopupsRouter').then(
    (r) => r.PopupsRouter
  )
);

function MyAppWithStore({ Component, pageProps }: AppPropsWithLayout) {
  const { userData, theme } = useApplicationContext(); //todo убрать, когда избавимся от storeshed
  const userDataFromStore = useUserDataStore('data');

  const { confirm, alert, popups } = useSystemPopups();

  const router = useRouter();

  async function waitForTelegramSDK(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (window.Telegram) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  const telegramAuth = useCallback(async function telegramAuth() {
    await waitForTelegramSDK();
    window.HackneyGamesSDK.setup({ botName: 'TheWorldChess_bot' });
    window.HackneyGamesSDK.telegramAuth().then(() => {
      console.log('Authentication success.');
    });
    window.HackneyGamesSDK.expandTMA();
    window.Telegram.WebApp.enableClosingConfirmation();
    window.Telegram.WebApp.disableVerticalSwipes();
  }, []);

  useEffect(() => {
    window.stores = mainStore;
    telegramAuth();
  }, [telegramAuth]);

  useEffect(() => {
    if (userDataFromStore) {
      gtmPush({
        event: GTM_EVENTS.USER_IS_LOGGED,
        paramUserId: userDataFromStore?.player.player_id,
      });
    }
  }, [userDataFromStore]);

  useEffect(() => {
    const { paidResult, subscriptionType } = router.query;
    if (userData && paidResult) {
      if (paidResult === '1') {
        gtmPush({
          event: GTM_EVENTS.PURCHASE,
          paramUserId: userData.player.player_id,
          paramProductId: subscriptionType as string,
        });
        gtmPush({
          event:
            subscriptionType === 'pro-annual'
              ? GTM_EVENTS.PURCHASE_YEAR
              : GTM_EVENTS.PURCHASE_MONTH,
          paramUserId: userData.player.player_id,
        });
      }
      router.replace(window.location.pathname, undefined, {
        shallow: true,
      });
    }
  }, [router, userData]);

  const getLayout = Component.getLayout ?? ((page) => page);

  // Если подключать этот компонент через dynamic, при редиректах постоянно
  // происходит переподключение к сокетам
  // const Settings = dynamic(() =>
  //   import('@components/molecules/popups/_Settings').then((r) => r.Settings)
  // );

  const SystemPopups = dynamic(() =>
    import('@components/molecules/popups/_SystemPopups').then(
      (r) => r.SystemPopups
    )
  );

  const LobbyTagsUnsubscribe = dynamic(() =>
    import('../HOCS/LobbyTagsUnsubscribe').then((r) => r)
  );

  const [mounted, setMounted] = useState(false);

  useLayoutEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <IntercomProvider>
        <ThemeProvider theme={themes[theme]}>
          <Settings />

          <NotificationsContextProvider>
            <LobbyContextProvider popups={popups}>
              <GameLobbyContextProvider>
                <ActiveGamesContextProvider>
                  {getLayout(<Component {...pageProps} userData={userData} />)}
                </ActiveGamesContextProvider>
              </GameLobbyContextProvider>

              <SystemPopups alert={alert} confirm={confirm} />

              <PopupsContextProvider>
                <PopupsRouter />
              </PopupsContextProvider>

              <LobbyTagsUnsubscribe />
            </LobbyContextProvider>
          </NotificationsContextProvider>
        </ThemeProvider>
      </IntercomProvider>

      {!mounted && <div className="visibility-hidden-block" />}
    </>
  );
}

export default function MyApp(props: AppPropsWithLayout) {
  const { store } = wrapper.useWrappedStore(props);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Provider store={store}>
        <$BoardStyles />
        <DefaultSeo
          dangerouslySetAllPagesToNoIndex={
            process.env.NEXT_PUBLIC_ENV !== 'master'
          }
          dangerouslySetAllPagesToNoFollow={
            process.env.NEXT_PUBLIC_ENV !== 'master'
          }
        />
        <ApplicationContextProvider
          initProps={{
            theme: props.pageProps.theme,
            locale: props.pageProps.locale,
          }}
        >
          <MyAppWithStore {...props} />
        </ApplicationContextProvider>
      </Provider>
    </StyleSheetManager>
  );
}

const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }
  return true;
};
