import { Reducer } from 'react';
import {
  createStore,
  applyMiddleware,
  Store,
  combineReducers,
  CombinedState,
  AnyAction,
} from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { MainState, ThunkResult, IStoreAction, IUserState } from '@types';
import { gameReducer } from './reducers/_game.reducer';

const rootReducer = () =>
  combineReducers<MainState>({
    game: gameReducer,
  });

export type RootState = ReturnType<
  Reducer<CombinedState<MainState>, AnyAction>
>;
export type RootDispatchType = (args: ThunkResult<RootState>) => IStoreAction;
export type RootStore = Store<RootState> & {
  dispatch: ThunkDispatch<RootState & IUserState, object, IStoreAction> &
    ((x: ThunkResult<void>) => ThunkResult<void>);
};

export const store: RootStore = createStore(
  rootReducer(),
  composeWithDevTools(applyMiddleware(thunk))
);
const makeStore = () => store;

export const wrapper = createWrapper(makeStore);

// store.subscribe(() => {
//   const token = mainStore.authStore.get('token');

//   const lsToken = getLSItem(lsKeys.JWT);
//   console.log('lsToken', lsToken);

//   if (token !== null) {
//     userService.ajax.addHeaders({ Authorization: `JWT ${token}` });

//     if (token !== lsToken) {
//       console.log('update jwt');
//       console.log('old jwt', lsToken);
//       console.log('new jwt', token);

//       setLSItem(lsKeys.JWT, token);
//       // Записываем время обновления токена для последующего рефреша на сервере
//       setLSItem(lsKeys.JWT_SET_TIME, syncedDate().getTime());
//     }
//   } else {
//     if (lsToken) {
//       console.log('remove jwt');
//       removeLSItem(lsKeys.JWT);
//       removeLSItem(lsKeys.JWT_SET_TIME);
//       userService.ajax.removeHeaders('Authorization');
//     }
//   }
// });
