import {
  checkEmailErrorCodes,
  eAutoPromotionTheme,
  eBoardThemes,
  ePremovesTheme,
  eSoundsTheme,
  GameStatus,
  GameType,
  IActivationError,
  IAvailableTitleData,
  IChallengesList,
  ICouponData,
  IFideUsersResponse,
  IGameData,
  IGamePlayer,
  INewsCommonData,
  IPlayerTournaments,
  IProfileData,
  IProfileEvent,
  IProSubscription,
  IRatingsChartsData,
  IRatingsData,
  IRegistrationData,
  IRequestPagination,
  IStatisticData,
  IStripeError,
  ITabElement,
  ITourGameData,
  ITournamentData,
  ITournamentExpandedData,
  ITournamentPlayer,
  ITournamentPlayersData,
  ITournamentRound,
  ITournamentSponsor,
  ITournamentStream,
  ITournamentTeamPlayPlayersData,
  ITournamentTeamPlayTeamsData,
  IUpdateFideError,
  IUpdateMeError,
  IUserData,
  TimeLimitWarning,
  TournamentBoardStatus,
  TournamentBoardType,
  TournamentJoinLimit,
  GameUserType,
  IBoardEndedData,
  IPulseItem,
  ISubscribedRoundBoard,
} from '@types';
import {
  Chessgun,
  IChessgunAnalysisItem,
  IChessgunHistoryItem,
  chessSide,
  figureType,
} from 'chessgun/core';

import { eTeamsWithPlayersGroups } from '@components/molecules/tournament/_teamGroupedResult';
import { eLastMoveTheme, ePossibleMovesTheme } from 'chessgun/lib/ui';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

/* ========================== edit profile ========================== */

export interface IEditProfileState {
  edit_profile_data: IUserData | null;
  edit_profile_request: boolean;
  error: undefined | IUpdateMeError;
}

/* ========================== profile ========================== */

export interface IProfileState {
  data: IProfileData | null;
  data_request: boolean;
  game_history: IRequestPagination<IGameData> | null;
  game_history_request: boolean;
  events_history: IProfileEvent[] | null;
  events_history_request: boolean;
  ratings: IRatingsData | null;
  ratings_request: boolean;
  tournaments: IPlayerTournaments | null;
  tournaments_request: boolean;
  wc_stats: IStatisticData | null;
  fide_stats: IStatisticData | null;
  otb_stats: IStatisticData | null;
  wc_charts: IRatingsChartsData | null;
  fide_charts: IRatingsChartsData | null;
  otb_charts: IRatingsChartsData | null;
  stats_request: boolean;
  pro_subscription: IProSubscription | null;
  upcoming_tournaments: ITournamentData[] | null;
  upcoming_tournaments_request: boolean;
  is_online: boolean;
}

/* ========================== game ========================== */

export enum gameActionTypes {
  SET_GAME_DATA = 'set game data',
  SET_GAME_DATA_REQUEST = 'set game data request',

  SET_GAME_TYPE = 'set game type',
  SET_GAME_USER_TYPE = 'set game user type',
  SET_GAME_ENGINE = 'set game engine',
  SET_LIVE_GAME_ENGINE = 'set live game engine',
  SET_BOARD_FLIPPED = 'set board flipped',
  SET_PLAYER_TURN = 'set player turn',
  SET_GAME_STATUS = 'set game status',

  SET_GAME_REVIEW_MODE = 'set game review mode',
  SET_ANALYZE_MODE = 'set analyze mode',

  SET_GAME_BOARD_ID = 'set game board id',

  SET_PLAYER_WARNING = 'set player warning',
  SET_OPPONENT_WARNING = 'set opponent warning',

  SET_GAME_ENDED = 'set game ended',
  ADD_MOVE = 'add move',
  SET_OPPONENT_DISCONNECTED = 'set opponent disconnected',

  SET_GAME_PLAYERS = 'set game players',
  SET_GAME_BLACK_PLAYER = 'set game black player',
  SET_GAME_WHITE_PLAYER = 'set game white player',
  SET_MY_COLOR = 'set my color',
  SET_BOARD_ROTATION = 'set board rotation',

  SET_GAME_MOVES_HISTORY = 'set game moves history',
  SET_GAME_SELECTED_MOVE = 'set game selected move',
  SET_GAME_SELECTED_MOVE_ANALYSIS = 'set game selected move analysis',

  SET_GAME_PGN_REQUEST = 'set game pgn request',
  SET_GAME_PGN = 'set game pgn',
  SET_PGN_FILE_NAME = 'set pgn file name',

  SET_GAME_BLACK_CAPTURED = 'set game black captured',
  SET_GAME_WHITE_CAPTURED = 'set game white captured',

  SET_GAME_BLACK_ADVANTAGE = 'set game black advantage',
  SET_GAME_WHITE_ADVANTAGE = 'set game white advantage',

  SET_BLACK_PLAYER_MS_LEFT = 'set black player ms left',
  SET_WHITE_PLAYER_MS_LEFT = 'set white player ms left',
  SET_BLACK_PLAYER_MOVE_END_TIME = 'set black player move end time',
  SET_WHITE_PLAYER_MOVE_END_TIME = 'set white player move end time',

  SET_WHITE_PULSE_HISTORY = 'set white pulse history',
  SET_BLACK_PULSE_HISTORY = 'set black pulse history',

  SET_GAME_VIEWERS_COUNT = 'set game viewers count',

  RESET_GAME = 'reset game',
}

export enum userActionTypes {
  SET_LOGGED = 'set logged',
  SET_LOGIN_REQUEST = 'set login request',
  SET_LOGIN_ERROR = 'set login error',
  SET_CURRENT_PASSWORD_ERROR = 'set current password error',
  SET_PASSWORD_ERROR = 'set password error',
  SET_REGISTRATION_REQUEST = 'set registration request',
  SET_EMAIL_CHECK_ERROR = 'set email check error',
  SET_SHOW_ACTIVATION_BUTTON = 'set show activation button',
  SET_ACTIVATION_CODE_REQUEST = 'set activation code request',
  SET_RESEND_ACTIVATION_CODE_REQUEST = 'set resend activation code request',
  SET_ACTIVATION_CODE_ERROR = 'set activation code error',
  SET_END_REGISTRATION_REQUEST = 'set end registration request',
  SET_END_REGISTRATION_ERROR = 'set end registration error',
  SET_REGISTRATION_DATA = 'set registration data',
  SET_TOKEN = 'set token',
  SET_USER_DATA = 'set user data',
  SET_DATA_REQUEST = 'set data request',
  SET_USER_UID = 'set user uid',
  SET_USER_GAMES_REQUEST = 'set user games request',
  SET_USER_GAMES = 'set user games',
  SET_USER_RATINGS_REQUEST = 'set user ratings request',
  SET_USER_RATINGS = 'set user ratings',
  SET_USER_STATS_REQUEST = 'set user stats request',
  SET_USER_FIDE_STATS = 'set user fide stats',
  SET_USER_WC_STATS = 'set user wc stats',
  SET_USER_OTB_STATS = 'set user otb stats',
  SET_USER_FIDE_CHARTS = 'set user fide charts',
  SET_USER_WC_CHARTS = 'set user wc charts',
  SET_USER_OTB_CHARTS = 'set user otb charts',
  SET_STRIPE_TOKEN = 'set stripe token',
  SET_PAYMENT_ERROR = 'set payment error',
  SET_BUY_TITLE_REQUEST = 'set user rank request',
  SET_USER_RANK = 'set user rank',
  SET_PROMOCODE_REQUEST = 'set promocode request',
  SET_PROMOCODE_ERROR = 'set promocode error',
  SET_PROMOCODE = 'set promocode',
  SET_AVAILABLE_TITLE_REQUEST = 'set avaible title request',
  SET_AVAILABLE_TITLE = 'set avaible title',
  SET_PRODUCT_REQUEST = 'set product request',
  SET_FIDE_REQUEST = 'set fide request',
  SET_FIDE_ERROR = 'set fide error',
  SET_FIND_FIDE_REQUEST = 'set find fide request',
  SET_FIND_FIDE_USERS = 'set find fide users',
  SIGN_OUT = 'sign out',
  SET_ME_TOURNAMENTS = 'set me tournaments',
  SET_PRO_SUBSCRIPTION = 'set pro subscription',
  SET_AUTORENEW_REQUEST = 'set autorenew request',
  SET_POPUP_PROFILE_DATA = 'set popup profile data',
  SET_POPUP_PROFILE_DATA_REQUEST = 'set popup profile data request',
  SET_SHOW_SIGN_IN_INVITE_FORM = 'set show sign in invite form',
  SET_INVITE_FRIEND_NAME = 'set invite friend name',
  SET_CHALLENGES_LIST = 'set challenges list',
  SET_CHALLENGES_REQUEST = 'set challenges request',
  SET_CHALLENGE_OPPONENT_PROFILE_DATA = 'set challenge opponent profile data',
  RESET_CHALLENGE_OPPONENT_PROFILE_DATA = 'reset challenge opponent profile data',
  UPDATE_INCOMING_CHALLENGES = 'update incoming challenges',
  UPDATE_OUTCOMING_CHALLENGES = 'update outcoming challenges',
  SET_UPDATE_FREE_ACCOUNT_REQUEST = 'set update free account request',
}

export interface IStoreAction {
  type: userActionTypes;
  payload?: any; // eslint-disable-line
}

export interface IGameState {
  game_data: ITourGameData | null;
  game_data_request: boolean;

  game_type: GameType;
  game_user_type: GameUserType;

  engine: Chessgun | null;
  liveEngine: Chessgun | null;
  is_review_mode: boolean;
  is_analyze_mode: boolean;

  board_id: string | null;
  game_ended: IBoardEndedData | null;
  game_status: GameStatus;
  board_flipped: boolean;
  board_rotation: number;
  player_turn: chessSide;

  player_warning: TimeLimitWarning | null;
  opponent_warning: TimeLimitWarning | null;

  opponent_disconnected: boolean;

  black_player: IGamePlayer | null;
  white_player: IGamePlayer | null;
  black_captured: figureType[];
  white_captured: figureType[];
  my_color: chessSide;

  moves_history: (IChessgunHistoryItem | null)[];
  selected_move: IChessgunHistoryItem | null;
  selected_move_analysis: IChessgunAnalysisItem | null;

  pgn: string;
  pgn_file_name: string;
  pgn_request: boolean;

  black_ms_left: number;
  white_ms_left: number;
  black_move_end_time: string | null;
  white_move_end_time: string | null;
  black_advantage: number;
  white_advantage: number;
  white_pulse_history: IPulseItem[];
  black_pulse_history: IPulseItem[];

  game_viewers_count: number;
}

/* ========================== tournament ========================== */

export interface ITournamentState {
  tournament_data: ITournamentExpandedData | null;
  tournament_data_request: boolean;

  participation_request: boolean;
  join_limit: TournamentJoinLimit | null;

  tournament_players_data: ITournamentPlayersData | null;
  tournament_players_data_request: boolean;
  tournament_update_players_data_loading: boolean;
  tournament_update_players_data_request: boolean;
  tournament_you: ITournamentPlayer | null;
  tournament_you_request: boolean;
  tournament_you_loading: boolean;
  loaded_players_count: number;

  tournament_rounds: ITournamentRound[];
  tournament_rounds_request: boolean;
  selected_round: ITournamentRound | null;

  tournament_winners: ITournamentPlayer[];
  tournament_winners_request: boolean;

  is_round_boards_ready: boolean;
  round_boards_request: boolean;
  round_boards: ISubscribedRoundBoard[];
  // Subscription management
  round_boards_multiboard_subscribed_games: Set<string>;
  round_boards_game_server_subscriptions: Set<string>;

  round_boards_amount: number;
  active_boards_amount: number;
  boards_type: TournamentBoardType;
  round_boards_status: TournamentBoardStatus | null;
  round_boards_page_number: number;
  round_boards_page_limit: number;

  tournament_certificate_pdf: string;
  tournament_certificate_pdf_request: boolean;
  tournament_certificate_image: string;
  tournament_certificate_image_request: boolean;

  tournament_sponsors: ITournamentSponsor[];
  tournament_sponsors_request: boolean;

  tournament_streams: ITournamentStream[];
  tournament_streams_request: boolean;

  tournament_pgn: string | null;
  tournament_pgn_request: boolean;

  ready_for_arena: boolean;
  ready_for_arena_request: boolean;

  stages_tabs: ITabElement[] | null;
  current_stage: string | null;
  current_stage_players: ITournamentPlayersData | null;
  current_stage_players_request: boolean;

  team_play_data_request: boolean;
  // team_play_players: ITournamentTeamsPlayPlayer[];
  // team_play_teams: ITournamentTeamsPlayTeam[];
  team_play_teams: ITournamentTeamPlayTeamsData | null;
  team_play_players: ITournamentTeamPlayPlayersData | null;
  team_play_update_request: boolean;

  team_play_current_group: eTeamsWithPlayersGroups;

  tagged_news: INewsCommonData | null;
  tagged_news_request: boolean;

  standing_page_number: number;
  standing_page_limit: number;

  standing_selected_game: ISubscribedRoundBoard | null;
}

export interface MainState {
  game: IGameState;
}

export type IAppAction = Omit<IStoreAction, 'type'> & {
  type: gameActionTypes;
};

export interface SharedState {
  user: IUserState;
}

export type SharedThunkResult<T, S extends object> = ThunkAction<
  T,
  SharedState & S,
  object,
  IStoreAction | AnyAction
>;

export type ThunkResult<T> = SharedThunkResult<T, MainState>;

export interface IBoardSettingsState {
  boardTheme: eBoardThemes;
  lastMoveTheme: eLastMoveTheme;
  legalMovesTheme: ePossibleMovesTheme;
  soundsTheme: eSoundsTheme;
  premovesTheme: ePremovesTheme;
  autoPromotion: eAutoPromotionTheme;
}

export interface IUserState {
  logged: boolean;
  login_request: boolean;
  login_error: string | null;
  current_password_error: string | null;
  password_error: string | null;
  registration_request: boolean;
  email_check_error: checkEmailErrorCodes | null;
  show_activation_button: boolean;
  end_registration_request: boolean;
  end_registration_error: boolean;
  activation_code_request: boolean;
  activation_code_error: string | IActivationError | null;
  resend_activation_code_request: boolean;
  registration_data: IRegistrationData | null;
  token: string | null;
  data_request: boolean;
  data: IUserData | null;
  games_request: boolean;
  games: IRequestPagination<IGameData> | null;
  ratings_request: boolean;
  ratings: IRatingsData | null;
  stats_request: boolean;
  wc_stats: IStatisticData | null;
  fide_stats: IStatisticData | null;
  otb_stats: IStatisticData | null;
  fide_charts: IRatingsChartsData | null;
  otb_charts: IRatingsChartsData | null;
  wc_charts: IRatingsChartsData | null;
  uid: string | null;
  available_title_request: boolean;
  available_title: IAvailableTitleData | null;
  product_request: boolean;
  fide_request: boolean;
  fide_error: IUpdateFideError;
  find_fide_request: boolean;
  find_fide_users: IFideUsersResponse | null;
  tournaments: IPlayerTournaments | null;
  pro_subscription: IProSubscription | null;
  autorenew_request: boolean;
  show_sign_in_invite_form: boolean;
  invite_friend_name: string | null;
  challenges?: IChallengesList | null;
  challenge_opponent_data?: IProfileData | null;
  challenges_request?: boolean;
  free_account_request: boolean;

  // TODO: вынести из этого стора
  buy_title_request: boolean;
  stripe_token: string | null;
  payment_error: IStripeError | null;
  promocode_request: boolean;
  promocode_error: string | null;
  promocode: ICouponData | null;
  popup_profile: IProfileData | null;
  popup_profile_request: boolean;
}
