import { Chessgun, chessSide } from 'chessgun/core';
import Router, { NextRouter } from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ePopupPaths, httpStatuses, routesConstants } from '@constants';
import { appService } from '@services/_app.service';
import { gameService } from '@services/_game.service';
import { broadcastBoardActions } from '@store/actions/_broadcast_board.actions';
import { gameActions } from '@store/actions/game';
import { mainStore, soundsActions, tournamentActions } from '@store/storeshed';
import {
  CreateInviteParams,
  GameStatus,
  GameType,
  IAcceptShortChallengeRequestParams,
  IChallengeItem,
  IInviteChallengeRequestParams,
  IInviteData,
  IInviteErrorData,
  IInviteGameData,
  ILobbyTagItem,
  InviteErrors,
  IRequestError,
  IShortChallengeRequestParams,
  ISystemPopups,
  ITourGameData,
  LobbyServerActions,
  LobbyServerTags,
  oppMode,
  SocketStatus,
  SoundType,
} from '@types';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';
import { getBroadcastGameStatus } from '@utils/_ng_helpers';
import { openPopup } from '@utils/_router';
import { getIsMissingLastMove } from '@utils/_getIsMissingLastMove';

import { challengesService } from '@services/_challenges.service';
import { executeWhenOnline } from '@utils/_executeWhenOnline';

import { eDoneReason, eDoneResult, ePhase } from '../_common.types';
import {
  eBroadcastCause,
  eCause,
  IBroadcastBoardStatePayload,
  IGameState,
  ITurn,
} from '../_types';
import {
  eLobbyContextActionType,
  IGameInQueueData,
  ILobbyContextAction,
  ILobbyContextDispatch,
  ILobbyContextState,
  TCreateGSSocket,
  TSubscribeOnTournamentGame,
} from './_lobby_context.types';
import { filterMyself } from './helpers/_filterMyself';
import { adaptChallengesQueue } from './helpers/adapters/adaptChallengesQueue';
import { GameWSController } from './ws';
import {
  eGameServerPayloadType,
  IGameRequestState,
  MessageType,
} from './ws/_types';
import { handleChallengeErrors } from './helpers/_handleAcceptShortChallengeErrors';
import { getRegion } from './helpers/_getRegion';
import { syncedDate } from '@utils/_helpers/_common';
import { myColorIsWhite } from '@utils/_helpers/_game';
import { devConsole } from '@utils/_helpers/_dev_console';

const DEFAULT_POPUPS = { confirm: () => {}, alert: () => {} };

export const useLobbyContextActions = (
  state: ILobbyContextState,
  dispatch: ILobbyContextDispatch
) => {
  const reduxDispatch = useDispatch();

  const updateGameRequestState = useCallback(
    (gameRequestState: IGameRequestState | null) => {
      dispatch({
        type: eLobbyContextActionType.SET_GAME_REQUEST_STATE,
        payload: gameRequestState,
      });
    },
    []
  );

  const resetGames = useCallback(() => {
    devConsole.log('LobbyContext games reset');
    dispatch({
      type: eLobbyContextActionType.RESET_GAMES,
    });
  }, [dispatch]);

  const resetGame = useCallback(
    (boardId: string) => {
      devConsole.log(`LobbyContext game ${boardId} reset`);
      dispatch({
        type: eLobbyContextActionType.RESET_GAME,
        payload: boardId,
      });
    },
    [dispatch]
  );

  const updateGame = useCallback(
    (gId: string, gameState: IGameState) => {
      dispatch({
        type: eLobbyContextActionType.UPDATE_GAME,
        payload: {
          gId,
          gameState,
        },
      });
    },
    [dispatch]
  );

  const updateGameDataById = useCallback(
    ({
      gId,
      gameState,
      ws,
      chessgun,
    }: {
      gId: string;
      gameState?: IGameState;
      ws: GameWSController;
      chessgun: Chessgun;
    }) => {
      const newGame = {
        ws,
        chessgun,
        meta: null,
        state: gameState,
      };

      dispatch({
        type: eLobbyContextActionType.UPDATE_GAME_DATA_BY_ID,
        payload: {
          gameId: gId,
          lobbyGame: newGame,
        },
      });
    },
    [dispatch]
  );

  const setAnalysis = useCallback(
    (analysis: boolean | null, boardId: string) => {
      dispatch({
        type: eLobbyContextActionType.SET_ANALYSIS,
        payload: { gId: boardId, analysis },
      });
    },
    [dispatch]
  );

  const gameSoundsHandler = useCallback(
    (gameState: IGameState, playerSide: chessSide | null) => {
      switch (gameState.cause) {
        // case eCause.FIRST_MOVE_WARN:
        //   if (typeof gameState.playingPh?.currTurn?.clr === 'boolean') {
        //     const currMoveIsWhite = gameState.playingPh.currTurn.clr;
        //     if (
        //       (currMoveIsWhite && playerSide === chessSide.WHITE) ||
        //       (!currMoveIsWhite && playerSide === chessSide.BLACK)
        //     ) {
        //       playSound(SoundType.zeitnot);
        //     }
        //   }
        //   break;

        case eCause.PHASE_CHANGED:
          if (gameState.currPh === ePhase.PLAYING) {
            soundsActions.playSound(SoundType.start);
          }

          if (
            gameState.currPh === ePhase.DONE &&
            gameState.donePh &&
            playerSide
          ) {
            const playerWin = myColorIsWhite(playerSide)
              ? gameState.donePh.result === eDoneResult.WHITE_WIN
              : gameState.donePh.result === eDoneResult.BLACK_WIN;
            const opponentWin = myColorIsWhite(playerSide)
              ? gameState.donePh.result === eDoneResult.BLACK_WIN
              : gameState.donePh.result === eDoneResult.WHITE_WIN;

            if (playerWin) {
              soundsActions.playSound(SoundType.win);
            }

            if (opponentWin) {
              soundsActions.playSound(SoundType.def);
            }

            if (gameState.donePh.reason === eDoneReason.DRAW_OFFER) {
              soundsActions.playSound(SoundType.draw);
            }
          }
          break;

        default:
          break;
      }
    },
    []
  );

  const refreshGame = useCallback(
    async ({
      gameId,
      region,
      chessgun,
    }: {
      gameId: string;
      region: string | null;
      chessgun: Chessgun;
    }) => {
      const { data } = region
        ? await appService.getNGPlayerRegionGames({
            gameIds: [gameId],
            region,
          })
        : await appService.getNGPlayerGames({ gameIds: [gameId] });

      const prevMoves = !!data?.length ? data[0].playingPh?.turns : null;
      if (prevMoves) {
        const lastMove = prevMoves[prevMoves.length - 1];

        chessgun.restart();
        setTimeout(() => {
          chessgun.loadFen(lastMove.end.fen);

          chessgun.uploadHistory({
            notations: prevMoves.map((move) => move.end.uci),
          });
        }, 50);
      }
    },
    []
  );

  const createChessgunInstance = useCallback(
    ({
      boardId,
      lastMoveFen,
      uploadHistoryNotations,
    }: {
      boardId: string;
      lastMoveFen?: string;
      uploadHistoryNotations?: string[];
    }) => {
      const chessgun = new Chessgun({
        name: boardId,
      });
      window[`chessEngine: ${boardId}`] = chessgun;
      const engineHistory = chessgun.get('history');

      if (lastMoveFen && uploadHistoryNotations && engineHistory.length === 0) {
        chessgun.loadFen(lastMoveFen);

        chessgun.uploadHistory({
          notations: uploadHistoryNotations,
        });
      }

      return chessgun;
    },
    []
  );

  const createGSSocket = useCallback(
    ({
      settings: { boardId, playerId, region, userType },
    }: TCreateGSSocket) => {
      const ws = new GameWSController();
      ws.boardId = boardId;
      ws.playerId = playerId;
      ws.userType = userType;
      ws.region = region;

      console.log('created game server socket instance', ws);
      return ws;
    },
    []
  );

  const getBoardData = useCallback(
    async ({
      boardId,
      playerUid,
      endedUpdate,
      endedCallback,
    }: {
      boardId: string;
      playerUid: string;
      endedUpdate?: boolean;
      endedCallback?: (data: ITourGameData, isMyBoard: boolean) => void;
    }) => {
      dispatch({
        type: eLobbyContextActionType.SET_BOARD_DATA_REQUEST,
        payload: { gId: boardId, inRequest: true },
      });

      try {
        const { data } = await gameService.getBoardData(boardId);
        let myColor: chessSide | null = null;

        if (data.black_player.uid === playerUid) {
          myColor = chessSide.BLACK;
        }

        if (data.white_player.uid === playerUid) {
          myColor = chessSide.WHITE;
        }

        if (!myColor && !data.result) {
          getNGGameData(data.board_id, playerUid);
        }

        if (data.result && endedCallback) {
          endedCallback(data, myColor !== null);
        }

        /*
        if (myColor) {
          const inviteData: IInviteData = {
            uid: '',
            created: data.created,
            player_rating: 1200,
            invite_code: '',
            player_uid: playerUid,
            rating: [data.rating],
            opp_mode: data.rematch_opp_mode,
            time_control: data.time_control,
            rating_limits: {
              lower: myColorIsWhite(myColor)
                ? `${data.black_player.rating - 100}`
                : `${data.white_player.rating - 100}`,
              upper: myColorIsWhite(myColor)
                ? `${data.black_player.rating + 100}`
                : `${data.white_player.rating + 100}`,
              bounds: '[)',
            },
            desired_color: null,
            is_new_gaming: true,
          };

          setInviteData(inviteData);
        }
       */

        setBoarData(boardId, data);

        if (!endedUpdate) {
          setAnalysis(data.analysis, boardId);
        }
      } catch (err) {
        const error = err as IRequestError;
        console.log(err);

        const gameWs = state.games[boardId]?.ws;
        const gameState = state.games[boardId]?.state;
        if (gameState) {
          gameWs.shouldReconnect = false;
          const newState: IGameState = { ...gameState, currPh: ePhase.DONE };
          updateGame(boardId, newState);
        }

        if (error?.status) {
          Router.push(routesConstants.HOME);
        }
      }

      dispatch({
        type: eLobbyContextActionType.SET_BOARD_DATA_REQUEST,
        payload: { gId: boardId, inRequest: false },
      });
    },
    [setAnalysis, dispatch, state.inviteData, state.games]
  );

  const handleRedirects = useCallback(
    ({
      gId,
      gameState,
      ws,
      playerSide,
    }: {
      gId: string;
      gameState: IGameState;
      ws: GameWSController;
      playerSide: chessSide | null;
    }) => {
      const gamePath = `${routesConstants.GAME}/${gId}`;
      if (gameState.currPh !== ePhase.DONE) {
        if (
          Router.asPath.includes(gamePath) ||
          gameState.currPh === ePhase.PLAYING
        ) {
          ws.connect();
        } else {
          const pathname = Router.pathname;
          const handleRouteChange = (url: string) => {
            if (url.includes(gamePath)) {
              Router.events.off('routeChangeComplete', handleRouteChange);
              ws.connect();
              if (
                ['/', '/home'].includes(pathname) &&
                state.shortChallenges.length
              ) {
                clearShortChallenges();
              }
            }
          };
          Router.events.on('routeChangeComplete', handleRouteChange);
        }
      }

      const playingPhases = [ePhase.PLAYING, ePhase.DONE];
      if (!playingPhases.includes(gameState.currPh) && playerSide) {
        const dateTimeOfRound = gameState?.cfg?.scheduledPhCfg?.start || null;
        const currentTime = syncedDate().getTime();
        const msToRedirect = dateTimeOfRound
          ? dateTimeOfRound - currentTime - 10000
          : 0;

        setTimeout(() => {
          if (!Router.asPath.includes(gamePath)) {
            executeWhenOnline(() => {
              Router.push(gamePath);
            });
          }
        }, msToRedirect);
      }
    },
    [state.shortChallenges]
  );

  const uploadTurnsToChessgun = useCallback(
    (chessgun: Chessgun, turns: ITurn[], lastMove?: ITurn) => {
      if (turns) {
        chessgun.restart();

        setTimeout(() => {
          if (lastMove) {
            chessgun.loadFen(lastMove.end.fen);
          }

          chessgun.uploadHistory({
            notations: turns.map((move) => move.end.uci),
          });
        }, 50);
      }
    },
    []
  );

  const createGame = useCallback(
    (gameState: IGameState, playerUid?: string) => {
      const pUid = playerUid ? playerUid : state.playerUid;
      if (!pUid) return;

      const boardId = gameState?.cfg?.gId;

      let playerSide: chessSide | null = null;
      if (pUid === gameState?.cfg?.bPId) {
        playerSide = chessSide.BLACK;
      }
      if (pUid === gameState?.cfg?.wPId) {
        playerSide = chessSide.WHITE;
      }

      if (playerSide) {
        getBoardData({ boardId, playerUid: pUid });
      }

      // chessgun instance creation
      const prevMoves = gameState.playingPh?.turns;
      const lastMove = prevMoves?.[prevMoves.length - 1];
      const lastMoveFen = lastMove?.end.fen;
      const uploadHistoryNotations = prevMoves?.map((move) => move.end.uci);

      const chessgun = createChessgunInstance({
        boardId,
        lastMoveFen,
        uploadHistoryNotations,
      });

      // region logic
      const region = getRegion(gameState?.cfg?.publicExtra?.rId);

      const userType = !playerSide ? 'viewer' : 'player';
      // ws instance creation
      const ws = createGSSocket({
        settings: {
          boardId,
          playerId: pUid,
          userType,
          region,
        },
      });

      ws.onMessage = ({ payload, payloadType }: MessageType) => {
        // обработка приходящего стейта
        if (payloadType === eGameServerPayloadType.STATE) {
          updateGame(boardId, payload);
          const playingPh = payload?.playingPh;
          const lastMove = playingPh?.lastTurn;
          const isFirstMove = playingPh?.currTurn?.absNum === 1;

          // Когда возобновляем соединение, когда первый ход не был сделан
          // то подгружаем актуальные ходы
          if (!lastMove && isFirstMove) {
            const turns = playingPh.turns;

            if (turns) {
              uploadTurnsToChessgun(chessgun, turns);
            }
          }

          if (lastMove) {
            const history = chessgun.get('history');
            const movesLength = history.length;
            const abs = lastMove.start.absNum;
            const lan = lastMove.end?.uci;
            const turns = playingPh.turns;

            const needsUpdateMoves = abs < movesLength || abs - 1 > movesLength;
            // если последний ход в движке отсутствует в стейте игры - отменяем последний ход
            if (getIsMissingLastMove(history, playingPh.turns)) {
              chessgun.undo();
            } else if ((!movesLength || needsUpdateMoves) && turns) {
              // (если в движке ещё нет ходов или нарушилась последовательность) и ходы уже есть в стейте игры, то пушим их все в движок
              uploadTurnsToChessgun(chessgun, turns, lastMove);
            } else {
              // если в сообщении не прилетел список ходов, но последовательность
              // была нарушена, то перезапрашиваем стейт
              if (needsUpdateMoves) {
                refreshGame({ gameId: boardId, region, chessgun });

                return;
              }

              // пушим новый ход в движок
              if (movesLength < abs && lan) {
                chessgun.move(lan);
                playerSide && soundsActions.playSound(SoundType.knock);
              }
            }
          }

          gameSoundsHandler(payload, playerSide);
        }
      };

      ws.onClose = async () => {
        try {
          const result = await appService.getNGGame(boardId, region);

          if (!result.ok) {
            ws.shouldReconnect = false;
          }
        } catch (err) {
          console.log(err);
        }
      };

      // redirects logic for game page
      handleRedirects({ gId: boardId, gameState, ws, playerSide });

      // Game sounds + chessgun history updates
      if (playerSide) {
        chessgun.on('history', (h, ph) => {
          if (h.length === 0 || h.length === ph.length) return;

          const lastMove = h[h.length - 1];

          if (!lastMove || lastMove.turn !== playerSide) return;

          soundsActions.playSound(SoundType.knock);
          ws.move({
            absNum: h.length,
            uci: lastMove.lan,
          });
        });
      }

      // updates game info in store
      updateGameDataById({ gId: boardId, chessgun, gameState, ws });
    },
    [
      createChessgunInstance,
      createGSSocket,
      gameSoundsHandler,
      getBoardData,
      handleRedirects,
      refreshGame,
      state.playerUid,
      updateGame,
      updateGameDataById,
      uploadTurnsToChessgun,
    ]
  );

  const subscribeOnTournamentGame = useCallback(
    ({
      settings: { boardId, playerId, userType, region },
      moves,
    }: TSubscribeOnTournamentGame) => {
      const lastMove = moves?.[moves.length - 1];
      const lastMoveFen = lastMove?.fen;
      const uploadHistoryNotations = moves?.map((move) => move.long_san);

      const chessgun = createChessgunInstance({
        boardId,
        lastMoveFen,
        uploadHistoryNotations,
      });

      const ws = createGSSocket({
        settings: {
          boardId,
          playerId,
          userType,
          region,
        },
      });

      ws.onMessage = ({ payload, payloadType }: MessageType) => {
        // обработка приходящего стейта
        if (payloadType === eGameServerPayloadType.STATE) {
          tournamentActions.updateNGTourBoardData({
            ...payload,
            cfg: {
              ...payload?.cfg,
              gId: boardId,
            },
          });
        }
      };

      ws.onClose = async () => {
        // ws.shouldReconnect = false;
      };

      console.log('subscribeOnTournamentGame', boardId);
      ws.connect();

      updateGameDataById({ gId: boardId, chessgun, ws, gameState: undefined });
    },
    [createChessgunInstance, createGSSocket, updateGameDataById]
  );

  const unsubscribeFromTournamentGame = useCallback(
    (boardId: string) => {
      console.log('unsubscribeFromTournamentGame', boardId);
      const gameWs = state.games?.[boardId]?.ws;

      if (!gameWs) {
        console.log('cant unsubscribe from ', boardId, state.games);
        return;
      }

      console.log(
        'unsubscribe as',
        gameWs.userType,
        'from',
        gameWs.boardId,
        ':',
        gameWs.userType === 'viewer' ? 'will disconnect' : 'connection saved'
      );
      if (gameWs.userType === 'viewer') {
        gameWs.shouldReconnect = false;
        gameWs.disconnect();
      }
    },
    [state.games]
  );

  const updateGameState = useCallback(
    ({ gId, gameState }: { gId?: string; gameState: IGameState }) => {
      const gameId = gId || gameState?.cfg?.gId;

      // TODO: сделать это как-то понятнее
      // т.к. state.games не успевает обновиться, проверяем наличие движка в window
      const isGameExist =
        gameId in state.games || window[`chessEngine: ${gameId}`];
      // const isGameScheduled =
      //   (gameState.cause === eCause.PHASE_CHANGED ||
      //     gameState.cause === eCause.SCHEDULED_WARN) &&
      //   gameState.currPh === ePhase.SCHEDULED;
      const isGameScheduled = !gameState.playingPh;

      if (!isGameScheduled || isGameExist) {
        updateGame(gameId, gameState);
      } else {
        createGame(gameState);
      }
    },
    [state.games, createGame, updateGame]
  );

  const getPlayerGames = useCallback(
    async (uid: string, region?: string): Promise<void> => {
      const { data = [] } = region
        ? await appService.getNGPlayerRegionGames({ userUids: [uid], region })
        : await appService.getNGPlayerGames({ userUids: [uid] });

      const activeGames = data.filter(
        (game) =>
          game.currPh !== ePhase.DONE && game.currPh !== ePhase.UNEXPECTED
      );

      activeGames.forEach((game) => {
        const gameId = game.cfg?.gId;
        const currGame = state.games[gameId];
        if (currGame) {
          const ws = currGame.ws;
          const chessgun = currGame.chessgun;
          if (ws?.userType === 'viewer') {
            ws.userType = 'player';
            ws.playerId = uid;
            ws.connect();

            chessgun.on('history', (h, ph) => {
              if (h.length === 0 || h.length === ph.length) return;

              const playerSide =
                state.playerUid === game.cfg?.bPId
                  ? chessSide.BLACK
                  : chessSide.WHITE;
              const lastMove = h[h.length - 1];

              if (!lastMove || lastMove.turn !== playerSide) return;

              ws.send(eGameServerPayloadType.MOVE, {
                absNum: h.length,
                uci: lastMove.lan,
              });
            });
          }

          updateGame(gameId, game);
        } else {
          createGame(game);
        }
      });
    },
    [createGame, updateGame, state.games]
  );

  const setUID = useCallback((uid: string | null) => {
    dispatch({
      type: eLobbyContextActionType.SET_UID,
      payload: uid,
    });
  }, []);

  const setPlayerUID = useCallback((uid: string | null) => {
    dispatch({
      type: eLobbyContextActionType.SET_PLAYER_UID,
      payload: uid,
    });
  }, []);

  const setWsStatus = useCallback((status: SocketStatus) => {
    dispatch({
      type: eLobbyContextActionType.SET_WS_STATUS,
      payload: status,
    });
  }, []);

  const addGameWS = useCallback((ws: GameWSController, gameId: string) => {
    dispatch({
      type: eLobbyContextActionType.ADD_GAME_WS,
      payload: {
        ws,
        gameId,
      },
    });
  }, []);

  const wsLobbyServerSubscribeTag = ({ name, id, pages }: ILobbyTagItem) => {
    const subscribedTags = state.subscribedTags;
    const ws = state.ws;

    if (!ws) return;
    if (
      subscribedTags.find(
        (tag) => tag.name === name && tag.id?.toString() === id?.toString()
      )
    ) {
      return;
    }

    const tagValue = id
      ? { [name]: Array.isArray(id) ? id : [id] }
      : { tag: [name] };

    ws.send(LobbyServerActions.RSVD_REQ_TAG_EDIT, {
      action: 'ADD',
      tag_value: tagValue,
    });

    dispatch({
      type: eLobbyContextActionType.WS_LOBBY_SERVER_SUBSCRIBE_TAG,
      payload: { name, id, pages },
    });
  };

  const wsLobbyServerUnsubscribeTag = (
    name: LobbyServerTags,
    id?: string | string[] | number | number[]
  ) => {
    const ws = state.ws;
    if (!ws) return;

    const tagValue = id
      ? { [name]: Array.isArray(id) ? id : [id] }
      : { tag: [name] };
    ws.send(LobbyServerActions.RSVD_REQ_TAG_EDIT, {
      action: 'DELETE',
      tag_value: tagValue,
    });

    dispatch({
      type: eLobbyContextActionType.WS_LOBBY_SERVER_UNSUBSCRIBE_TAG,
      payload: { name, id },
    });
  };

  const wsLobbyServerResetTags = () => {
    dispatch({
      type: eLobbyContextActionType.WS_LOBBY_SERVER_RESET_TAGS,
    });
  };

  const setGamesInQueueData = useCallback(
    (data: IGameInQueueData[]) => {
      dispatch({
        type: eLobbyContextActionType.SET_GAMES_IN_QUEUE_DATA,
        payload: data,
      });
    },
    [dispatch]
  );

  /**
   * Получаем игры из сокетов — CHALLENGE_SHORT_UPDATED
   */
  const requestGamesInQueueData = useCallback(async () => {
    // dispatch({ type: gamesInQueueActionType.SET_IS_PENDING, payload: true });

    if (!state.uid) return;

    try {
      const { ok, data } = await challengesService.getChallenges();
      // let hasOurRequest = false;
      if (ok) {
        const gamesInQueueData = adaptChallengesQueue(data.results);

        const gamesWithoutMe = filterMyself(gamesInQueueData, state.uid);

        setGamesInQueueData(gamesWithoutMe);
      }
    } catch (err) {
      // dispatch({ type: gamesInQueueActionType.SET_IS_PENDING, payload: false });
    }
    // dispatch({ type: gamesInQueueActionType.SET_IS_PENDING, payload: false });
  }, [setGamesInQueueData, state.uid]);

  const setChallenges = useCallback(
    (challenges: IChallengeItem[]) => {
      dispatch({
        type: eLobbyContextActionType.SET_SHORT_CHALLENGES,
        payload: challenges,
      });
    },
    [dispatch]
  );

  const addShortChallengeData = useCallback(
    (challengeData: IChallengeItem) => {
      dispatch({
        type: eLobbyContextActionType.ADD_SHORT_CHALLENGE_DATA,
        payload: challengeData,
      });
    },
    [dispatch]
  );

  const removeShortChallengeData = useCallback(
    (id: number) => {
      dispatch({
        type: eLobbyContextActionType.REMOVE_SHORT_CHALLENGE_DATA,
        payload: id,
      });
    },
    [dispatch]
  );

  const addShortChallengeDataRequest = useCallback(
    (data: IShortChallengeRequestParams) => {
      dispatch({
        type: eLobbyContextActionType.ADD_SHORT_CHALLENGE_DATA_REQUEST,
        payload: data,
      });
    },
    [dispatch]
  );

  const removeShortChallengeDataRequest = useCallback(
    (data: IShortChallengeRequestParams) => {
      dispatch({
        type: eLobbyContextActionType.REMOVE_SHORT_CHALLENGE_DATA_REQUEST,
        payload: data,
      });
    },
    [dispatch]
  );

  const addCancelShortChallengeRequest = useCallback(
    (id: number) => {
      dispatch({
        type: eLobbyContextActionType.ADD_CANCEL_SHORT_CHALLENGE_REQUEST,
        payload: id,
      });
    },
    [dispatch]
  );

  const removeCancelShortChallengeRequest = useCallback(
    (id: number) => {
      dispatch({
        type: eLobbyContextActionType.REMOVE_CANCEL_SHORT_CHALLENGE_REQUEST,
        payload: id,
      });
    },
    [dispatch]
  );

  const setInviteChallengeData = useCallback(
    (challengeData: IChallengeItem | null) => {
      dispatch({
        type: eLobbyContextActionType.SET_INVITE_CHALLENGE_DATA,
        payload: challengeData,
      });
    },
    [dispatch]
  );

  const setInviteChallengeDataRequest = useCallback(
    (data: boolean) => {
      dispatch({
        type: eLobbyContextActionType.SET_INVITE_CHALLENGE_DATA_REQUEST,
        payload: data,
      });
    },
    [dispatch]
  );

  const setCancelInviteChallengeDataRequest = useCallback(
    (data: boolean) => {
      dispatch({
        type: eLobbyContextActionType.SET_CANCEL_INVITE_CHALLENGE_DATA_REQUEST,
        payload: data,
      });
    },
    [dispatch]
  );

  const setClearShortChallengesRequest = useCallback(
    (data: boolean) => {
      dispatch({
        type: eLobbyContextActionType.SET_CLEAR_SHORT_CHALLENGES_REQUEST,
        payload: data,
      });
    },
    [dispatch]
  );

  const setShortChallengesRequest = useCallback(
    (data: boolean) => {
      dispatch({
        type: eLobbyContextActionType.SET_SHORT_CHALLENGES_REQUEST,
        payload: data,
      });
    },
    [dispatch]
  );

  const setInviteData = useCallback(
    (inviteData: IInviteData | null) => {
      dispatch({
        type: eLobbyContextActionType.SET_INVITE_DATA,
        payload: inviteData,
      });
    },
    [dispatch]
  );

  const setInviteDataRequest = useCallback(
    (inRequest: boolean) => {
      dispatch({
        type: eLobbyContextActionType.SET_INVITE_DATA_REQUEST,
        payload: inRequest,
      });
    },
    [dispatch]
  );

  const setRequestExpired = useCallback(
    (requestExpired: boolean) => {
      dispatch({
        type: eLobbyContextActionType.SET_REQUEST_EXPIRED,
        payload: requestExpired,
      });
    },
    [dispatch]
  );

  const setCancelInviteRequest = useCallback(
    (inRequest: boolean) => {
      dispatch({
        type: eLobbyContextActionType.CANCEL_INVITE_REQUEST,
        payload: inRequest,
      });
    },
    [dispatch]
  );

  const setSelectedInvite = useCallback(
    (invite: string | null) => {
      dispatch({
        type: eLobbyContextActionType.SET_SELECTED_INVITE,
        payload: invite,
      });
    },
    [dispatch]
  );

  const setInviteFriendName = useCallback(
    (inviteFriendName: string | null) => {
      dispatch({
        type: eLobbyContextActionType.SET_INVITE_FRIEND_NAME,
        payload: inviteFriendName,
      });
    },
    [dispatch]
  );

  const setBoarData = useCallback(
    (boardId: string, data: ITourGameData) => {
      dispatch({
        type: eLobbyContextActionType.SET_BOARD_DATA,
        payload: { gId: boardId, data },
      });
    },
    [dispatch]
  );

  const getNGGameData = useCallback(
    async (gameId: string, playerUid: string) => {
      try {
        const { data: regions } = await appService.getRegionsByGIds([gameId]);
        const region = regions[gameId];

        const { data } =
          region === 'basic'
            ? await appService.getNGPlayerGames({ gameIds: [gameId] })
            : await appService.getNGPlayerRegionGames({
                gameIds: [gameId],
                region,
              });
        if (!data.length) return;

        if (
          data[0] &&
          data[0].currPh !== ePhase.DONE &&
          data[0].currPh !== ePhase.UNEXPECTED
        ) {
          createGame(data[0], playerUid);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [state.games, createGame]
  );

  const updateBroadcastBoard = useCallback(
    (payload: IBroadcastBoardStatePayload, boardId: string) => {
      const isLobbyBoardEvent = Router.asPath.includes(
        routesConstants.BROADCAST_LOBBY
      );

      switch (payload.cause) {
        case eBroadcastCause.STATUS_CHANGED:
          if (isLobbyBoardEvent) {
            tournamentActions.setBoardStatus(payload.status, boardId);
            tournamentActions.setBoardResult(payload.result, boardId);
          } else {
            const status = getBroadcastGameStatus(
              payload.status,
              payload.result
            );
            reduxDispatch(gameActions.setGameStatus(status));

            if (payload.result !== null) {
              reduxDispatch(
                broadcastBoardActions.broadcastBoardEndedUpdate({
                  boardId,
                })
              );
            }
          }
          break;

        case eBroadcastCause.MOVED:
          if (isLobbyBoardEvent) {
            tournamentActions.setBroadcastBoardMove(payload, boardId);
          } else {
            reduxDispatch(broadcastBoardActions.addBroadcastBoardMove(payload));
          }
          break;

        case eBroadcastCause.MOVE_ANALYZED:
          if (isLobbyBoardEvent) {
            tournamentActions.setBroadcastBoardAnalysis(payload, boardId);
          } else {
            reduxDispatch(
              broadcastBoardActions.addBroadcastMoveAnalysis(payload)
            );
          }
          break;

        case eBroadcastCause.MOVES_CHANGED:
          if (isLobbyBoardEvent) {
            tournamentActions.setBroadcastBoardMovesChanged(
              payload.moves,
              boardId
            );
          } else {
            reduxDispatch(
              broadcastBoardActions.setBroadcastMovesChanged(payload.moves)
            );
          }
          break;

        case eBroadcastCause.TIME_CHANGED:
          if (isLobbyBoardEvent) {
            tournamentActions.setBroadcastBoardPlayersMsLeft(payload, boardId);
          } else {
            reduxDispatch(
              broadcastBoardActions.setBroadcastTimeChanged(payload)
            );
          }
          break;

        default:
          break;
      }
    },
    []
  );

  /**
   * Выполняет запрос на получение данных о  создаваемой партии
   */
  const createGameInvite = async ({
    gameData,
    router,
    popups = DEFAULT_POPUPS,
  }: {
    gameData: IInviteGameData;
    router?: NextRouter;
    popups: Pick<ISystemPopups, 'alert'>;
  }) => {
    setInviteDataRequest(true);

    try {
      const { status, data } = await gameService.createGameInvite(gameData);

      if (status === httpStatuses.CREATED) {
        gtmPush({
          event: GTM_EVENTS.START_GAME,
        });
        setInviteData(data);

        setRequestExpired(false);

        console.log('Starting game');

        reduxDispatch(gameActions.setGameStatus(GameStatus.INITIALIZING));
        reduxDispatch(gameActions.setGameType(GameType.SINGLE_GAME));

        if (router && data.opp_mode === oppMode.FRIEND) {
          console.log('Friend game');

          router.push(routesConstants.FRIEND_WAITING);
        }
      }
    } catch (err) {
      console.log(err);
      reduxDispatch(gameActions.resetGame());
      popups.alert(
        'You have reached the maximum number of active games. Please complete one of your ongoing games.'
      );
    }

    setInviteDataRequest(false);
  };

  /**
   * Создает инвайт
   * TODO: разные состояния и параметры если нужно
   */
  const createInvite = async ({
    inviteParams,
    // router,
    popups = DEFAULT_POPUPS,
  }: {
    inviteParams: CreateInviteParams;
    router?: NextRouter;
    popups?: Pick<ISystemPopups, 'alert'>;
  }) => {
    setInviteDataRequest(true);

    try {
      const { ok } = await gameService.createInvite(inviteParams);

      if (ok) {
        gtmPush({
          event: GTM_EVENTS.START_GAME,
        });
      }
    } catch (err) {
      console.log(err);
      reduxDispatch(gameActions.resetGame());
      popups.alert(
        'You have reached the maximum number of active games. Please complete one of your ongoing games.'
      );
    }

    setInviteDataRequest(false);
  };

  /**
   * Выполняет запрос на удаление создаваемой партии
   */
  const cancelGameInvite = async ({
    gameUid,
    router,
    oppUid,
  }: {
    gameUid: string;
    router?: NextRouter;
    oppUid?: string;
  }) => {
    setCancelInviteRequest(true);

    try {
      const token = mainStore.authStore.get('token');

      const { status } = oppUid
        ? await gameService.cancelRematchData(gameUid, token, oppUid)
        : await gameService.cancelInviteData(gameUid, token);

      if (status === httpStatuses.DELETED) {
        console.log('cancelGameInvite DELETED');
      }
    } catch (err) {
      const error = err as IRequestError;
      console.log(err);

      if (error.status === httpStatuses.NOT_FOUND) {
        console.log('cancelGameInvite NOT FOUND');
      }
    }

    reduxDispatch(gameActions.resetGame());

    if (router) {
      router.push(routesConstants.HOME);
    }

    setCancelInviteRequest(false);
  };

  /**
   * Выполняет запрос на создание challenge на игру в общей очереди (short)
   */
  const createShortChallenge = async ({
    params,
    throwError,
    popups = DEFAULT_POPUPS,
  }: {
    params: IShortChallengeRequestParams;
    throwError?: boolean;
    popups?: Pick<ISystemPopups, 'alert'>;
  }) => {
    try {
      addShortChallengeDataRequest(params);
      setRequestExpired(false);
      const uid = mainStore.authStore.get('uid');
      const token = mainStore.authStore.get('token');
      const { status, data } = await challengesService.createShortChallenge(
        params,
        uid,
        token
      );

      if (status === httpStatuses.OK) {
        gtmPush({
          event: GTM_EVENTS.START_GAME,
        });
        addShortChallengeData(data);

        reduxDispatch(gameActions.setGameStatus(GameStatus.INITIALIZING));
        reduxDispatch(gameActions.setGameType(GameType.SINGLE_GAME));
      }
    } catch (err) {
      const error = err as IRequestError<{ code: string; detail: string }>;
      console.log(err);
      reduxDispatch(gameActions.resetGame());
      handleChallengeErrors(error, popups.alert);
      if (throwError) throw err;
    } finally {
      removeShortChallengeDataRequest(params);
    }
  };

  /**
   * Выполняет запрос на удаление short challenge
   */
  const cancelShortChallenge = async ({
    challengeId,
    router,
    throwError,
  }: {
    challengeId: number;
    router?: NextRouter;
    throwError?: boolean;
  }) => {
    try {
      addCancelShortChallengeRequest(challengeId);
      const uid = mainStore.authStore.get('uid');
      const token = mainStore.authStore.get('token');

      await challengesService.cancelShortChallenge(challengeId, uid, token);

      removeShortChallengeData(challengeId);
    } catch (err) {
      const error = err as IRequestError;
      console.log(err);
      if (error.status === httpStatuses.NOT_FOUND) {
        console.log('cancelGameInvite NOT FOUND');
        removeShortChallengeData(challengeId);
      }
      if (throwError) throw err;
    } finally {
      removeCancelShortChallengeRequest(challengeId);
      setRequestExpired(false);
    }

    reduxDispatch(gameActions.resetGame());

    if (router) {
      router.push(routesConstants.HOME);
    }
  };

  /**
   * Выполняет запрос на удаление всех собственных short challenges
   */
  const clearShortChallenges = async () => {
    try {
      setClearShortChallengesRequest(true);
      const uid = mainStore.authStore.get('uid');
      const token = mainStore.authStore.get('token');

      await challengesService.clearShortChallenges(uid, token);
    } catch (err) {
      console.log(err);
    } finally {
      setClearShortChallengesRequest(false);
    }
  };

  /**
   * Выполняет запрос на создание invite challenge
   */
  const createInviteChallenge = async ({
    params,
    router,
    isFriendGame,
    popups = DEFAULT_POPUPS,
  }: {
    params: IInviteChallengeRequestParams;
    router?: NextRouter;
    isFriendGame?: boolean;
    popups?: Pick<ISystemPopups, 'alert'>;
  }) => {
    try {
      setInviteChallengeDataRequest(true);
      const uid = mainStore.authStore.get('uid');
      const token = mainStore.authStore.get('token');
      const { status, data } = await challengesService.createInviteChallenge(
        params,
        uid,
        token
      );

      if (status === httpStatuses.OK) {
        setInviteChallengeData(data);

        reduxDispatch(gameActions.setGameStatus(GameStatus.INITIALIZING));
        reduxDispatch(gameActions.setGameType(GameType.SINGLE_GAME));
      }
      if (router && isFriendGame) {
        console.log('Friend game');
        router.push(routesConstants.FRIEND_WAITING);
      }
    } catch (err) {
      console.log(err);
      const error = err as IRequestError<{ code: string; detail: string }>;

      reduxDispatch(gameActions.resetGame());
      handleChallengeErrors(error, popups.alert);
    } finally {
      setInviteChallengeDataRequest(false);
    }
  };

  /**
   * Выполняет запрос на удаление invite challenge
   */
  const cancelInviteChallenge = async ({
    inviteId,
    router,
  }: {
    inviteId: number;
    router?: NextRouter;
  }) => {
    try {
      setCancelInviteChallengeDataRequest(true);
      const uid = mainStore.authStore.get('uid');
      const token = mainStore.authStore.get('token');

      await challengesService.cancelInviteChallenge(inviteId, uid, token);

      setInviteChallengeData(null);
    } catch (err) {
      console.log(err);
    } finally {
      setCancelInviteChallengeDataRequest(false);
    }

    if (router) {
      router.push(routesConstants.HOME);
    }
  };

  /**
   * Выполняет запрос на получение моих short challenges
   */
  const getShortChallenges = async (uid: string, token?: string) => {
    try {
      setShortChallengesRequest(true);
      const params = { limit: 30, offset: 0 };
      const { data } = await challengesService.getMyShortChallenges(
        params,
        uid,
        token
      );
      if (data?.results) setChallenges(data.results);
    } catch (err) {
      console.log(err);
    } finally {
      setShortChallengesRequest(false);
    }
  };

  /**
   * Выполняет запрос на создание challenge на игру в общей очереди (short)
   */
  const acceptShortChallenge = async ({
    params,
    throwError,
    popups = DEFAULT_POPUPS,
  }: {
    params: IAcceptShortChallengeRequestParams;
    throwError?: boolean;
    popups?: Pick<ISystemPopups, 'alert'>;
  }) => {
    try {
      const uid = mainStore.authStore.get('uid');
      const token = mainStore.authStore.get('token');
      const { status } = await challengesService.acceptShortChallenge(
        params,
        uid,
        token
      );

      if (status === httpStatuses.OK) {
        gtmPush({
          event: GTM_EVENTS.START_GAME,
        });

        reduxDispatch(gameActions.setGameStatus(GameStatus.INITIALIZING));
        reduxDispatch(gameActions.setGameType(GameType.SINGLE_GAME));
      }
    } catch (err) {
      const error = err as IRequestError<{ code: string; detail: string }>;
      console.log(err);
      reduxDispatch(gameActions.resetGame());

      handleChallengeErrors(error, popups.alert);

      if (throwError) throw err;
    }
    // finally {}
  };

  /**
   * Выполняет запрос на создание игры через активацию инвайта или при клике на пользователя в лобби
   */
  const getGameFromInvite = async ({
    inviteCode,
    oppMode,
    router,
    popups = { confirm: () => {}, alert: () => {} },
  }: {
    inviteCode: string;
    oppMode: string;
    router?: NextRouter;
    popups?: ISystemPopups;
  }) => {
    setSelectedInvite(inviteCode);
    reduxDispatch(gameActions.setGameStatus(GameStatus.CREATING));

    try {
      const uid = mainStore.authStore.get('uid');
      const token = mainStore.authStore.get('token');
      const { status } = await challengesService.acceptInviteChallenge(
        inviteCode,
        uid,
        token
      );

      if (status === httpStatuses.CREATED) {
        setSelectedInvite(null);
        reduxDispatch(gameActions.setGameStatus(GameStatus.NONE));
      }
    } catch (err) {
      const error = err as IRequestError<IInviteErrorData>;
      const gameData = {
        oppMode,
      };

      handleInviteErrors(error.data, popups, router, gameData);
    }

    setSelectedInvite(null);
  };

  /**
   * Обработка ошибок ответа на приглашение играть
   */
  const handleInviteErrors = (
    errorData: IInviteErrorData,
    { confirm, alert }: ISystemPopups,
    router?: NextRouter,
    additionalInfo?: { oppMode: string }
  ) => {
    if (!errorData) return;

    let inviteFriendName = null;

    const proSubscription =
      mainStore.fideSubscriptionStore.get('pro_subscription');
    const fideId = mainStore.userDataStore.get('data')?.fide_id;

    const redirectHomeCallback = () => {
      router && router.push(routesConstants.HOME);
    };

    if (errorData?.code === InviteErrors.REQUEST_NOT_VERIFIED) {
      if (proSubscription?.is_active && !fideId) {
        confirm(
          'Oops. You missed some fields! Please go back and complete your registration',
          () => Router.push('/membership'),
          redirectHomeCallback
        );
      } else {
        alert(
          'Oops. Please wait for the approval. It usually takes 24 hours',
          redirectHomeCallback
        );
      }
    }
    if (errorData.code === InviteErrors.FIDE_RATED_GAME_UNAVAILABLE_FOR_USER) {
      confirm(
        'Switch to PRO membership to play FOA-rated games. Redirect to membership page for upgrade?',
        () => Router.push('/membership?pro=true'),
        redirectHomeCallback
      );
    }

    if (errorData.code === InviteErrors.ANON_AVAILABLE_RATING) {
      if (additionalInfo?.oppMode === 'friend') {
        inviteFriendName = errorData?.friend_name ?? null;
        openPopup(`?popup=${ePopupPaths.SIGN_IN_INVITE}`);
      } else {
        confirm(
          'Rated games are available for registered users only. Would you like to register to play for a World Chess or FOA rating?',
          () => openPopup(`?popup=${ePopupPaths.SIGN_IN}`)
        );
      }
    }

    if (
      errorData.code === InviteErrors.INVALID_CODE ||
      errorData.code === InviteErrors.OWNER_DISCONNECTED ||
      errorData.code === InviteErrors.CHALLENGE_NOT_FOUND
    ) {
      alert(
        'Oops. Your friend has left. The good news is, you still can find an opponent to play',
        redirectHomeCallback
      );
    }

    if (errorData.code === InviteErrors.TOO_MANY_ACTIVE_GAMES) {
      alert(
        'You have reached the maximum number of active games. Please complete one of your ongoing games.'
      );
    }

    // if (errorData.code === InviteErrors.FIDE_RATED_GAME_UNAVAILABLE_FOR_USER) {
    //   // const rate = gameRatingSelector.find(
    //   //   (rating) => rating.id === GameRatingMode.FIDERATED
    //   // );
    //   // if (rate) {
    //   //   dispatch(gameSettingsActions.setRate(rate));
    //   // }
    // }

    reduxDispatch(gameActions.resetGame());

    if (inviteFriendName) {
      setInviteFriendName(inviteFriendName);
    }

    reduxDispatch(gameActions.setGameStatus(GameStatus.NONE));
  };

  /**
   * Выполняет запрос на получение статуса заявки
   * @param {string} inviteUid  - id доски
   * @param {NextRouter} router  - история
   */
  const getInviteStatus = async (
    inviteUid: string,
    { alert }: Pick<ISystemPopups, 'alert'>
  ) => {
    try {
      await gameService.getInviteStatus(inviteUid);
    } catch (err) {
      console.log(err);

      reduxDispatch(gameActions.resetGame());

      alert('Oops. The invite was canceled', () =>
        Router.push(routesConstants.HOME)
      );
    }
  };

  return {
    updateGameRequestState,
    updateGameState,
    setUID,
    setPlayerUID,
    resetGames,
    resetGame,
    getPlayerGames,
    setWsStatus,
    addGameWS,
    wsLobbyServerSubscribeTag,
    wsLobbyServerUnsubscribeTag,
    wsLobbyServerResetTags,
    requestGamesInQueueData,
    getBoardData,
    setAnalysis,
    setChallenges,
    addShortChallengeData,
    removeShortChallengeData,
    addShortChallengeDataRequest,
    removeShortChallengeDataRequest,
    addCancelShortChallengeRequest,
    removeCancelShortChallengeRequest,
    setInviteChallengeData,
    setInviteChallengeDataRequest,
    setCancelInviteChallengeDataRequest,
    setClearShortChallengesRequest,
    setShortChallengesRequest,
    setInviteData,
    setBoarData,
    updateBroadcastBoard,
    setRequestExpired,

    createInvite,
    setGamesInQueueData,

    createGameInvite,
    cancelGameInvite,
    createShortChallenge,
    cancelShortChallenge,
    clearShortChallenges,
    createInviteChallenge,
    cancelInviteChallenge,
    acceptShortChallenge,
    getShortChallenges,
    getGameFromInvite,
    getInviteStatus,
    subscribeOnTournamentGame,
    unsubscribeFromTournamentGame,
  };
};

export const setPingsWithoutAnswer = (amount: number): ILobbyContextAction => ({
  type: eLobbyContextActionType.SET_PINGS_WITHOUT_ANSWER,
  payload: amount,
});
