import { httpStatuses } from '@constants';

import { LobbyServerTags } from './websocket';

export enum SoundType {
  knock = 'knock', // ход
  start = 'start', // старт игры
  zeitnot = 'zeitnot', // окончание времени игры
  win = 'win', // победа
  def = 'def', // поражение
  draw = 'draw', // звук ничьи (ex: предложение ничьи оппонентом и принятие ничьи оппонентом)
  drawdecl = 'drawdecl', // отклонение ничьей оппонентом
  call = 'call', // предложение игры (рематч)
  tourend = 'tourend', // окончание турнира
  popup = 'popup', // уведомления (потеря соединения и т.п.)
}

export type ISounds = {
  [key in SoundType]: string;
};

export interface IGameAnalyze {
  email: string;
  title: string;
  user_id: number;
  board_id: number;
  board_uid: string;
  tournament_id: number;
  time_control: string;
  start_elo: number;
  rating_delta: number;
  country: string;
  num_moves: number;
  analyse_end: string;
  single_report: string;
  board_pgn: string;
  board_result: string;
  total_cheat: number;
  description: string;
  ending_cheat: number;
  banned: boolean;
  suspected: boolean;
  board_cheated: boolean;
  rating: string;
  history_index: number;
  is_broadcast: boolean;
  full_name: string;
  white_estimated_elo: number | null;
  black_estimated_elo: number | null;
  // Tournament data
  tournament_has_banned: boolean;
  tournament_has_removed: boolean;
  tournament_has_cheated: boolean;
  tournament_has_left: boolean;
  tournament_points: number;
  tournament_report: string;
  is_cheat_by_one_shot_main: boolean;
  is_cheat_by_one_shot_positional: boolean;
  is_cheat_by_one_shot_tactic: boolean;
  main_history_score: number;
  tactic_history_score: number;
  positional_history_score: number;
  is_cheat_by_history: boolean;
  is_supposed_to_be_banned: boolean;
  is_cheat_by_one_shot: boolean;

  cheat_one_shot: string | null;
  supposed_banned: string | null;
}

export interface ILobbyTagItem {
  name: LobbyServerTags;
  id?: string | string[] | number | number[];
  pages: string[];
}

export interface IAnalyzer {
  fide_id: number;
  full_name: string;
  photo: string;
}

export interface IRequestData<T, Error = string | object | Array<string>> {
  status: 'done' | 'request' | 'error' | 'not_requested';
  data: T;
  error: Error | null;
}

export interface IRequestError<T = undefined> {
  status: (typeof httpStatuses)[keyof typeof httpStatuses];
  data: T;
  error: T;
  message: T;
  name: string;
}

export interface ISystemPopups {
  confirm: (text: string, confirm: () => void, decline?: () => void) => void;
  alert: (text: string, close?: () => void) => void;
}
