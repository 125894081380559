import {
  AccountVerification,
  eAccessLevel,
  IImageThumbnails,
} from './_common.types';
import { IClubData } from './_community.types';
import { IChallengesNumber } from './_challenges.types';
import {
  IOrderData,
  IRecurringPurchase,
  ISubscriptionData,
} from './_payment.types';

export enum fideAccountSelectErrorCodes {
  ALREADY_ASSIGNED = 'already_assigned',
  PERMISSION_DENIED = 'permission_denied',
  NOT_AUTHENTICATED = 'not_authenticated',
}

export type TUpdateMeKeys =
  | 'full_name'
  | 'first_name'
  | 'last_name'
  | 'country'
  | 'avatar'
  | 'birth_date'
  | 'twitter'
  | 'facebook'
  | 'instagram'
  | 'federation'
  | 'gender'
  | 'weight'
  | 'height'
  | 'receive_newsletters'
  | 'place_of_birth'
  | 'challenges_allowed';

export enum eActivationErrorCode {
  RESEND_THROTTLED = 'resend_throttled',
  THROTTLED = 'throttled',
  UNKNOWN = 'unknown',
  INVALID_CODE = 'invalid_code',
  CODE_IS_RESET = 'code_is_reset',
}

export enum eActivationErrorTextCode {
  CODE_IS_RESET = 'Code is reset, too many tries. Please, resend the verification code.',
  INVALID = 'Code is invalid or expired',
}

export interface IActivationError {
  code: eActivationErrorCode;
  detail: string;
  time?: number | null;
}

export type IUpdateMe = {
  [full_name in TUpdateMeKeys]?: string;
};

export interface IIsOnline {
  is_online: boolean;
}

export interface IUpdateMeError {
  [key: string]: string;
}

export type TUserGameRating = number | 'New';

export interface INextTitleByTypeProgress {
  title: 'ACM' | 'AFM' | 'AIM' | 'AGM';
  category: 'rapid' | 'blitz' | 'bullet';
  games_total: number;
  games_done: number;
  rating: number;
}

export interface INextTitleProgress {
  rapid: INextTitleByTypeProgress;
  blitz: INextTitleByTypeProgress;
  bullet: INextTitleByTypeProgress;
}

export interface ITitleProgress {
  current_title: string | null;
  next_title_progress: INextTitleProgress | null;
}

export enum eFideIdStatus {
  CONNECTED = 'CONNECTED',
  ON_CHECK = 'ON_CHECK',
  REJECTED = 'REJECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

export type AccountType = 'free' | 'anonymous' | 'subscribed';

export type AccountTypeInfo = [AccountType, eFideIdStatus | null];

export interface IUserUidData {
  uid: string;
}

export interface IProfileData {
  avatar: IImageThumbnails | null;
  best_place: number | null;
  birth_date: string | null;
  place_of_birth: string | null;
  can_purchase_subscription: boolean;
  clubs: IClubData[];
  country: number | null;
  email: string;
  federation: number | null;
  weight: number | null;
  height: number | null;
  fide_id: number | null;
  fide_verified_status: AccountVerification | null;
  fidelity_points: number;
  founder_approve_status: number;
  full_camera_access: boolean;
  full_name: string | null;
  first_name: string | null;
  last_name: string | null;
  gender: 'male' | 'female' | null;
  id: number;
  is_active: boolean;
  is_registered: boolean;
  nickname: string;
  player: {
    player_id: number;
    gender: string;
    federation?: number;
    foa_title: 'ACM' | 'AFM' | 'AIM' | 'AGM' | null;
    fide_title:
      | 'CM'
      | 'FM'
      | 'IM'
      | 'GM'
      | 'WCM'
      | 'WFM'
      | 'WIM'
      | 'WGM'
      | null;
  };
  since: string;
  social_accounts: {
    facebook: string;
    instagram: string;
    mates: string;
    twitter: string;
  };
  subscriptions: ISubscriptionData[];
  uid: string;
  games_count: number | null;
  win_count: number | null;
  draw_count: number | null;
  loss_count: number | null;
  tournaments_count: number | null;
  is_online?: boolean;
  player_id?: number;
  payments_method?: string;
  card_brand?: string;
  card_last_4?: string;
  card_expiring_this_month: boolean;
  paypal_email?: string;
  challenges_allowed: boolean;
  access_level?: eAccessLevel;
}

export interface IUserData extends IProfileData {
  access_level: eAccessLevel;
  board_last_move_style: string;
  board_legal_move_style: string;
  board_style: string;
  challenges_allowed: boolean;
  challenges: IChallengesNumber;
  challenges_request: boolean;
  challenge_opponent_data: IProfileData | null;
  free_account_requested: boolean;
  is_finalized: boolean;
  is_public: boolean;
  is_sound_enabled: boolean;
  language?: string;
  orders?: IOrderData[];
  receive_newsletters?: boolean;
  recurring_purchase: IRecurringPurchase;
}

export interface IRegistrationData {
  uid: string;
  token: string;
  access_token?: string;
  email?: string | null;
}

export interface IStatisticData {
  total: IStatisticDataRowObject;
  win: IStatisticDataRowObject;
  draw: IStatisticDataRowObject;
  loss: IStatisticDataRowObject;
}

export interface IStatisticDataRowObject {
  all: IStatisticDataCellObject;
  white: IStatisticDataCellObject;
  black: IStatisticDataCellObject;
}

export interface IStatisticDataCellObject {
  all: number;
  blitz: number;
  bullet: number;
  rapid: number;
}

export interface IStatisticRequest {
  game_stats: IStatisticData;
  ratings: IRatingsChartsData;
}

export interface IRatingsChartsData {
  blitz: Array<IRatingsChartValue> | null;
  bullet: Array<IRatingsChartValue> | null;
  rapid: Array<IRatingsChartValue> | null;
  classic: Array<IRatingsChartValue> | null;
}

export interface IRatingsChartValue {
  date: string;
  rating: number;
}

export interface INewAvailTitle {
  title: 'ACM' | 'AFM' | 'AIM' | 'AGM';
  category: 'rapid' | 'blitz' | 'bullet';
  games_total: number;
  games_done: number;
  rating: number;
}

export interface INewUnavailTitle {
  rapid: INewAvailTitle;
  blitz: INewAvailTitle;
  bullet: INewAvailTitle;
}

export interface IAvailableTitleData {
  current_title: string | null;
  new_avail_title: INewAvailTitle | null;
  new_unavail_title: INewUnavailTitle | null;
  price: number;
  product_sku: string | null;
  reason: string;
}

export interface IUpdateFide {
  date_of_birth?: string;
  email?: string;
  federation?: string;
  full_name?: string;
  first_name?: string;
  last_name?: string;
  is_male?: boolean;
  national_id_selfie: string;
  nationality?: string;
  fide_id?: number;
  photo?: string;
  place_of_birth?: string;
}

export interface IFindFidePlayers {
  full_name?: string;
  birth_year?: string;
  fide_id?: number;
}

export interface IFideUsers {
  avatar: null | string;
  birth_year: number;
  blitz_rating: number | null;
  federation: number;
  fide_id: number;
  full_name: string;
  id: number;
  is_active: boolean;
  is_male: boolean;
  labels: Array<any>; // eslint-disable-line
  nationality: number | null;
  portrait: null;
  portrait_fullface: null;
  rank: null;
  rapid_rating: number | null;
  rating: number | null;
}

export interface IFideUsersResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<IFideUsers>;
}

export interface IUpdateFideFieldError {
  [key: string]: string;
}

export interface IUpdateFideaccountError {
  fide_id: {
    code: fideAccountSelectErrorCodes;
    detail: string;
    masked_email?: string;
  };
}

export type IUpdateFideError =
  | IUpdateFideFieldError
  | IUpdateFideaccountError
  | null;
