import { initialTimeControl } from '@constants';
import {
  getUserGameRating,
  calcRatingLimitsByGameModeAndTimeControl,
} from './helpers/_calc_rating_limits_by_game_mode_and_time_control';

import { DEFAULT_RATING_LIMINTS, STORAGE_KEYS } from './_constants';
import {
  eGameLobbyReducerActionType,
  IGameLobbyContextState,
  TGameLobbyRatingModes,
  TGameLobbyReducerActionKeyPayload,
  TGameLobbyReducerActionKeysPayload,
  TGameLobbyReducerActionPayload,
  TGameLobbyTimeControls,
} from './_types';
import { getLSItem, setLSItem } from '@utils/_local_storage';
import { helpersService } from '@services/_helpers.service';
import {
  GameRatingMode,
  IAdvancedTimeControl,
  IRatingsData,
  TUserGameRating,
} from '@types';

export const gameLobbyContextReducer = (
  prevState: IGameLobbyContextState,
  {
    type,
    payload,
  }: {
    type: eGameLobbyReducerActionType;
    payload: TGameLobbyReducerActionPayload;
  }
): IGameLobbyContextState => {
  switch (type) {
    case eGameLobbyReducerActionType.KeyOf:
      const { key, value } = payload as TGameLobbyReducerActionKeyPayload;
      return {
        ...prevState,
        [key]: value,
      };
    case eGameLobbyReducerActionType.KeysOf:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newState: any = {
        ...prevState,
      };
      (payload as TGameLobbyReducerActionKeysPayload).forEach(
        ({ key, value }) => {
          newState[key] = value;
        }
      );
      return newState;
    default:
      return prevState;
  }
};

export function createReducerInitialState({
  ratings,
}: {
  ratings: IRatingsData | null;
}): IGameLobbyContextState {
  // TODO: refactor, do not store constants in state
  const timeControls = helpersService.getGroupedTimeControls(
    process.env.NEXT_PUBLIC_ENV
  );

  const gameRatingLimits =
    getLSItem(STORAGE_KEYS.RATING_LIMITS) || DEFAULT_RATING_LIMINTS;

  const gameRatingMode =
    (getLSItem(STORAGE_KEYS.RATING_MODE) as TGameLobbyRatingModes) ||
    GameRatingMode.UNRATED;

  const currentTimeControl =
    (getLSItem(STORAGE_KEYS.TIME_CONTROL) as IAdvancedTimeControl) ||
    initialTimeControl;

  const currentUserRating: TUserGameRating = getUserGameRating(
    ratings,
    gameRatingMode,
    currentTimeControl.board_type
  );

  fillRatingLimitsIfNotSet(
    gameRatingLimits,
    gameRatingMode,
    currentTimeControl.board_type as TGameLobbyTimeControls,
    currentUserRating
  );

  return {
    timeControls,
    gameRatingMode,
    gameRatingLimits,
    currentTimeControl,
    gameIsStarting: false,
    currentUserRating,
  };
}

function fillRatingLimitsIfNotSet(
  gameRatingLimits: IGameLobbyContextState['gameRatingLimits'],
  gameRatingMode: TGameLobbyRatingModes,
  boardType: TGameLobbyTimeControls,
  currentUserRating: TUserGameRating
): void {
  const { lower, upper } = gameRatingLimits[gameRatingMode][boardType];

  if (lower === 0 && upper === 0) {
    const newRatingLimits = calcRatingLimitsByGameModeAndTimeControl(
      currentUserRating,
      gameRatingMode
    );
    gameRatingLimits[gameRatingMode][boardType] = newRatingLimits;
    setLSItem(STORAGE_KEYS.RATING_LIMITS, gameRatingLimits);
  }
}
